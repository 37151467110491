import * as React from 'react';
import {
  Field,
  FieldProps,
  Form,
  Formik,
  FormikProps,
  FormikValues,
} from 'formik';
import * as Yup from 'yup';
import { IComboBoxOption } from 'office-ui-fabric-react';
import { TextField } from '../../../Common/Form';
import { Button, Modal } from '../../../Common';
import { useStates } from '../../../../queries/useStates';
import {
  BPOLoanAddress,
  BPOLoanData,
} from '../../../../context/order/bpo/types';
import { Column, Grid, ModalTitle, Row } from './styles';
import { extractAddresses } from '../../../../context/order/bpo/util';
import FormikComboBox from '../../BPOLoanDetails/ManualBPOLoans/FormikComboBox';
import { useProducts } from '../../../../queries/useProducts';
import { ISubProduct } from '../../../../context/order/types';
import { useOrder } from '../../../../context/order';

export interface UpdateLoanModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  isLoading: boolean;
  rowData: BPOLoanData;
  onSubmit: (loanData: BPOLoanData) => void;
  addressOnly?: boolean;
}

function UpdateLoanModal({
  isOpen,
  onDismiss,
  isLoading,
  rowData,
  onSubmit,
  addressOnly,
}: UpdateLoanModalProps) {
  const { order } = useOrder();

  const productsQuery = useProducts();
  const [subProductLookup, subProductList] = React.useMemo(() => {
    const lookup: { [key: string]: ISubProduct } = {};
    const list: ISubProduct[] = [];
    if (productsQuery.data) {
      productsQuery.data.some(product => {
        if (product.productId === order.product.productId) {
          product.subProducts.forEach(subProduct => {
            lookup[subProduct.productId] = subProduct;
            list.push(subProduct);
          });
          return true;
        }
        return false;
      });
    }
    return [lookup, list];
  }, [order.product.productId, productsQuery.data]);

  const validationSchema = Yup.object().shape({
    clientLoanId: Yup.string().required('Required'),
    street: Yup.string().required('Required'),
    unitNumber: Yup.string(),
    city: Yup.string().required('Required'),
    state: Yup.string().min(2, 'Too Short!').required('Required'),
    zip: Yup.string().min(5, 'Too Short!').required('Required'),
    bpoProduct: Yup.object()
      .shape({
        id: Yup.string(),
        name: Yup.string(),
      })
      .required('Required'),
    accessDetails: Yup.string().when('bpoProduct', {
      is: (bpoProduct: string) => {
        return (
          subProductLookup[bpoProduct] &&
          subProductLookup[bpoProduct].subCategory === 3
        );
      },
      then: Yup.string().required('Field is required'),
      // otherwise: Yup.string().max(0, 'Not Allowed for Exterior BPO Type'),
    }),
    customerReferenceId1: Yup.string(),
    customerReferenceId2: Yup.string(),
    customerReferenceId3: Yup.string(),
  });

  const states = useStates();

  const statesComboBoxData: IComboBoxOption[] = React.useMemo(() => {
    return states.data
      ? states.data.map(({ code, name }) => ({
          key: code,
          text: `${code} - ${name}`,
        }))
      : [];
  }, [states.data]);

  const address: BPOLoanAddress | undefined = React.useMemo(() => {
    const addresses = extractAddresses(rowData);
    return addresses.selected || addresses.edited || addresses.original;
  }, [rowData]);

  const formRef = React.useRef<FormikProps<FormikValues> | null>(null);

  React.useEffect(() => {
    if (isOpen) {
      let count = 0;
      const interval = setInterval(() => {
        count++;
        if (count > 20) {
          clearInterval(interval);
          return;
        }

        if (formRef.current) {
          formRef.current.setFieldTouched('clientLoanId');
          formRef.current.setFieldTouched('street');
          formRef.current.setFieldTouched('unitNumber');
          formRef.current.setFieldTouched('city');
          formRef.current.setFieldTouched('state');
          formRef.current.setFieldTouched('zip');
          formRef.current.setFieldTouched('bpoProduct');
          formRef.current.setFieldTouched('accessDetails');
          formRef.current.setFieldTouched('customerReferenceId1');
          formRef.current.setFieldTouched('customerReferenceId2');
          formRef.current.setFieldTouched('customerReferenceId3');
          clearInterval(interval);
        }
      }, 100);
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onDismiss}
      width={addressOnly ? 400 : 800}
      height="fit-content"
    >
      <Formik
        validationSchema={validationSchema}
        innerRef={formRef}
        initialValues={{
          row: rowData.row,
          clientLoanId: rowData.clientLoanId,
          street: address?.street,
          unitNumber: address?.unitNumber,
          city: address?.city,
          state: address?.state,
          zip: address?.zip,
          bpoProduct: rowData.bpoProduct,
          accessDetails: rowData.accessDetails,
          customerReferenceId1: rowData.customerReferenceId1,
          customerReferenceId2: rowData.customerReferenceId2,
          customerReferenceId3: rowData.customerReferenceId3,
        }}
        onSubmit={async ({
          clientLoanId,
          street,
          unitNumber,
          city,
          state,
          zip,
          bpoProduct,
          accessDetails,
          customerReferenceId1,
          customerReferenceId2,
          customerReferenceId3,
        }) => {
          const newRowData = {
            ...rowData,
            clientLoanId,
            bpoProduct,
            accessDetails,
            customerReferenceId1,
            customerReferenceId2,
            customerReferenceId3,
          };

          const addressLookup = extractAddresses(newRowData);

          if (
            addressLookup.original &&
            street === addressLookup.original.street &&
            unitNumber === addressLookup.original.unitNumber &&
            city === addressLookup.original.city &&
            state === addressLookup.original.state &&
            zip === addressLookup.original.zip
          ) {
            if (addressLookup.edited) {
              addressLookup.edited.isDeleted = true;
            }
          } else if (
            street !== address?.street ||
            unitNumber !== address?.unitNumber ||
            city !== address?.city ||
            state !== address?.state ||
            zip !== address?.zip
          ) {
            if (addressLookup.edited) {
              let isValidated = addressLookup.edited.isValidated;
              if (
                street !== addressLookup.edited.street ||
                unitNumber !== addressLookup.edited.unitNumber ||
                city !== addressLookup.edited.city ||
                state !== addressLookup.edited.state ||
                zip !== addressLookup.edited.zip
              ) {
                isValidated = false;
              }

              Object.assign(addressLookup.edited, {
                street,
                unitNumber,
                city,
                state,
                zip,
                isValidated,
                isSelected: true,
              });
            } else {
              newRowData.addresses.push({
                type: 'Edited',
                bpoOrderStagingAddressId: undefined,
                bpoOrderStagingId: rowData.bpoOrderStagingId,
                street: street || '',
                unitNumber: unitNumber || '',
                city: city || '',
                state: state || '',
                zip: zip || '',
                isValidated: false,
                isSelected: true,
              });
            }

            if (
              addressLookup.selected &&
              addressLookup.selected !== addressLookup.edited
            ) {
              addressLookup.selected.isSelected = false;
            }
          }

          onSubmit(newRowData);

          onDismiss();
        }}
      >
        {({ isValid, handleReset, dirty, values }) => {
          return (
            <Form
              translate="no"
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                width: '100%',
              }}
            >
              <ModalTitle>Edit Loan</ModalTitle>
              <Grid>
                <Column>
                  {!addressOnly && (
                    <Field name="clientLoanId">
                      {(fieldProps: FieldProps) => (
                        <TextField
                          styles={{ root: { height: 86, width: '100%' } }}
                          label="Loan ID"
                          required
                          {...fieldProps}
                        />
                      )}
                    </Field>
                  )}
                  <Field name="street">
                    {(fieldProps: FieldProps) => (
                      <TextField
                        styles={{ root: { height: 86, width: '100%' } }}
                        label="Street"
                        required
                        {...fieldProps}
                      />
                    )}
                  </Field>
                  <Field name="unitNumber">
                    {(fieldProps: FieldProps) => (
                      <TextField
                        styles={{ root: { height: 86, width: '100%' } }}
                        label="Suite/Unit (optional)"
                        {...fieldProps}
                      />
                    )}
                  </Field>
                  <Field name="city">
                    {(fieldProps: FieldProps) => (
                      <TextField
                        styles={{ root: { height: 86, width: '100%' } }}
                        label="City"
                        required
                        {...fieldProps}
                      />
                    )}
                  </Field>
                  <Row>
                    <Field name="state">
                      {(fieldProps: FieldProps) => {
                        return (
                          <FormikComboBox
                            id="state"
                            label="State"
                            styles={{
                              container: {
                                height: 86,
                                width: '100%',
                              },
                            }}
                            {...fieldProps}
                            options={statesComboBoxData}
                            onSetValue={(helpers, option?) => {
                              if (option && option.key) {
                                helpers.setValue(option.key);
                              }
                            }}
                            index={0}
                            // error={!address?.state}
                            selectedKey={values.state}
                            calloutProps={{
                              calloutMinWidth: 300,
                            }}
                            required
                          />
                        );
                      }}
                    </Field>
                    <Field name="zip">
                      {(fieldProps: FieldProps) => (
                        <TextField
                          styles={{ root: { height: 86, width: '100%' } }}
                          label="Zip"
                          required
                          {...fieldProps}
                        />
                      )}
                    </Field>
                  </Row>
                </Column>
                {!addressOnly && (
                  <Column>
                    <Field name="bpoProduct">
                      {(fieldProps: FieldProps) => (
                        <FormikComboBox
                          styles={{ container: { height: 86, width: '100%' } }}
                          required
                          label="BPO Type"
                          index={0}
                          // style={{ marginBottom: 40 }}
                          {...fieldProps}
                          // error={fieldProps.meta.error}
                          selectedKey={values.bpoProduct.id}
                          options={subProductList.map(subProduct => {
                            return {
                              key: subProduct.productId,
                              text: subProduct.productName,
                            };
                          })}
                          onSetValue={(helpers, option?) => {
                            if (option && option.key) {
                              helpers.setValue({
                                id: option.key,
                                name: option.text,
                              });
                            }
                          }}
                        />
                      )}
                    </Field>
                    <Field name="accessDetails">
                      {(fieldProps: FieldProps) => (
                        <TextField
                          styles={{ root: { height: 86, width: '100%' } }}
                          label="Interior Access Details"
                          required={
                            subProductLookup[values.bpoProduct.id] &&
                            subProductLookup[values.bpoProduct.id]
                              .subCategory === 3
                          }
                          // disabled={values.bpoType !== 'Interior'}
                          {...fieldProps}
                        />
                      )}
                    </Field>
                    <Field name="customerReferenceId1">
                      {(fieldProps: FieldProps) => (
                        <TextField
                          styles={{ root: { height: 86, width: '100%' } }}
                          label="Customer Reference ID1"
                          {...fieldProps}
                        />
                      )}
                    </Field>
                    <Field name="customerReferenceId2">
                      {(fieldProps: FieldProps) => (
                        <TextField
                          styles={{ root: { height: 86, width: '100%' } }}
                          label="Customer Reference ID2"
                          {...fieldProps}
                        />
                      )}
                    </Field>
                    <Field name="customerReferenceId3">
                      {(fieldProps: FieldProps) => (
                        <TextField
                          styles={{ root: { height: 86, width: '100%' } }}
                          label="Customer Reference ID3"
                          {...fieldProps}
                        />
                      )}
                    </Field>
                  </Column>
                )}
              </Grid>
              <Row>
                <Button
                  type="reset"
                  width="48.5%"
                  height="30px"
                  variant="secondary"
                  alternate
                  onClick={() => {
                    onDismiss();
                    handleReset();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  width="48.5%"
                  height="30px"
                  isLoading={isLoading}
                  disabled={!(isValid && dirty)}
                >
                  Update Loan
                </Button>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default UpdateLoanModal;
