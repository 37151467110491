import { ITooltipParams } from 'ag-grid-community';
import { ConfirmationDialogOptions, confirm } from '@samc/react-ui-core';
import {
  BaseGridRef,
  GridFieldConfiguration,
  useAgGridApi,
} from '@samc/react-ui-grid';
import * as React from 'react';
import { FileRejection } from 'react-dropzone';
import { useOrder } from '../../../context/order';
import { useRemoveSecurentUpload } from '../../../mutations/useRemoveSecurentUpload';
import { useSetSecurentUpload } from '../../../mutations/useSetSecurentUpload';
import {
  Dropzone,
  Grid,
  Instructions,
  Loading,
  StepContainer,
} from '../../Common';
import { DeleteBtCellRenderer } from './config';
import { GridWrapper } from './styles';

export type FileGridData = {
  fileStagingId: string;
  documentName: string;
  borrowerName: string;
  address: string;
  loanAmount: number;
  loanAmountFormatted: string;
};

function SecurentUploadLoan() {
  const gridRef = React.useRef<BaseGridRef>(null);
  const { order, securentActions } = useOrder();
  const setSecurentUploadMutation = useSetSecurentUpload();
  const removeSecurentUploadMutation = useRemoveSecurentUpload();
  const [, onGridReady] = useAgGridApi();

  function onDrop(acceptedFiles: File[], fileRejections: FileRejection[]) {
    if (fileRejections.length === 0) {
      setSecurentUploadMutation.mutate(
        { stagingId: order.stagingId, uploadedFiles: acceptedFiles },
        {
          onSuccess: data => {
            const { stagingId, files } = data;
            securentActions.onChangeFiles(files, stagingId);

            gridRef.current?.reset();
          },
        },
      );
    }
  }

  const filesGridData: FileGridData[] = React.useMemo(
    () =>
      order.securentFiles.map(file => {
        const address = `${
          file.borrowers[0].street && `${file.borrowers[0].street}, `
        } ${file.borrowers[0].unitNumber && `${file.borrowers[0].unitNumber}, `}
        ${file.borrowers[0].city && `${file.borrowers[0].city}, `} ${
          file.borrowers[0].state && `${file.borrowers[0].state}, `
        } ${file.borrowers[0].zip && `${file.borrowers[0].zip}, `}`;

        return {
          fileStagingId: file.fileStagingId,
          documentName: file.documentName,
          borrowerName: file.borrowers[0].borrowerName,
          address,
          loanAmount: file.loanAmount,
          loanAmountFormatted: file.loanAmountFormatted,
        };
      }),
    [order.securentFiles],
  );

  const onRemoveSecurentUpload = React.useCallback(
    (fileStagingId: string) => {
      removeSecurentUploadMutation.mutate(
        {
          fileStagingId,
        },
        {
          onSuccess: () => {
            securentActions.onRemoveFile(fileStagingId);
            gridRef.current?.reset();
          },
        },
      );
    },
    [removeSecurentUploadMutation, securentActions],
  );

  const onDeleteConfirm = React.useCallback(
    async (rowData: FileGridData) => {
      const dialogOptions: ConfirmationDialogOptions = {
        title: `Delete Loan`,
        detail: `Are you sure you want to delete ${rowData.documentName}? This cannot be undone`,
        confirmText: 'Delete',
        cancelText: 'Cancel',
      };

      const response = await confirm(dialogOptions);

      if (response) {
        onRemoveSecurentUpload(rowData.fileStagingId);
      }
    },
    [onRemoveSecurentUpload],
  );

  const fields: GridFieldConfiguration[] = [
    {
      headerName: 'Filename',
      field: 'documentName',
      type: 'string',
      width: 210,
    },
    {
      headerName: 'Borrower Name',
      field: 'borrowerName',
      type: 'string',
      width: 185,
    },
    {
      headerName: 'Address',
      field: 'address',
      type: 'string',
      width: 280,
    },
    {
      headerName: 'Loan Amount',
      field: 'loanAmountFormatted',
      type: 'string',
      width: 130,
    },
    {
      field: '',
      headerName: '',
      cellRenderer: 'DeleteBtCellRenderer',
      cellRendererParams: {
        onClick(rowData: FileGridData) {
          onDeleteConfirm(rowData);
        },
      },
      valueFormatter: () => 'Delete',
      tooltipValueGetter: (params: ITooltipParams) =>
        params.valueFormatted || '',
      width: 40,
      filter: false,
    },
  ];

  return (
    <StepContainer align="center">
      {setSecurentUploadMutation.isLoading && (
        <Loading message="Processing XML File..." />
      )}
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
        <Instructions>
          Please{' '}
          <strong>upload the ULAD (MISMO 3.4) XML files in your Pool.</strong>
        </Instructions>
        <Dropzone
          type="wide"
          extension="XML"
          multiple
          fileType="ULAD (MISMO 3.4) XML Files"
          onDrop={onDrop}
        />
      </div>
      {order.securentFiles.length > 0 && (
        <GridWrapper>
          <Grid
            ref={gridRef}
            fields={fields}
            data={filesGridData}
            idField="fileStagingId"
            onGridReady={onGridReady}
            frameworkComponents={{ DeleteBtCellRenderer }}
            autoHeight
            sizeColumnsToFit
            suppressColumnFilters
            suppressRowSelector
            suppressPaginationPanel
          />
        </GridWrapper>
      )}
    </StepContainer>
  );
}

export default SecurentUploadLoan;
