/* eslint-disable react/function-component-definition */
import React, { useEffect, useContext, useState } from 'react';
import {
  setCurrentUser,
  DefaultPublisher,
  CurrentUserSetEvent,
} from '@samc/common';
import CurrentUser from '../models/CurrentUser';
import { useClient } from './ClientContext';
import { TermsOfUseAcceptedEvent } from '../events';
import { WithChildren } from '../types';

const UserContext = React.createContext<CurrentUser | undefined>(undefined);

const CurrentUserProvider: React.FC<Partial<WithChildren>> = ({ children }) => {
  const client = useClient();
  const [user, setUser] = useState(new CurrentUser());

  const loadUser = () => {
    setCurrentUser(null); // Wipe current user
    let isCanceled = false;
    if (client.isReady) {
      // User is authenticated and has a token, so we can get the current user's data.
      client.userInfo().then(userInfo => {
        const currentUser = new CurrentUser();
        currentUser.populate(userInfo);
        if (!isCanceled) {
          setCurrentUser(currentUser);
        }
      });
    }
    return () => {
      isCanceled = true;
    };
  };
  useEffect(loadUser, [client]);

  useEffect(() => {
    const subscriptionId = DefaultPublisher.subscribe<CurrentUserSetEvent>(
      CurrentUserSetEvent.eventName,
      (evt: CurrentUserSetEvent) => {
        if (evt.currentUser) {
          setUser(evt.currentUser as CurrentUser);
        }
      },
    );
    const termsAcceptanceSubscriptionId =
      DefaultPublisher.subscribe<TermsOfUseAcceptedEvent>(
        TermsOfUseAcceptedEvent.eventName,
        loadUser,
      );
    return () => {
      DefaultPublisher.unsubscribe(
        CurrentUserSetEvent.eventName,
        subscriptionId,
      );
      DefaultPublisher.unsubscribe(
        TermsOfUseAcceptedEvent.eventName,
        termsAcceptanceSubscriptionId,
      );
    };
  });

  // Authorize was built on assuming CurrentUser can never be null, thus CurrentUserContext only shows children when user has a value.
  return user ? (
    <UserContext.Provider value={user}>{children}</UserContext.Provider>
  ) : null;
};

function useCurrentUser() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error(
      'useCurrentUser must be used within a CurrentUserProvider!',
    );
  }
  return context;
}

export { CurrentUserProvider, useCurrentUser };
