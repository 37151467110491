import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import api from '../services/api';

type Request = {
  clientLoanId: string;
};

type Response = {
  orderIds: string[];
};

export const getOrderTrackingLoanSearch = async (
  request: Request,
): Promise<Response> => {
  const response = await api.get<Response>(
    `/Order/ordertrackingloansearch?clientLoanId=${encodeURIComponent(
      request.clientLoanId,
    )}`,
  );
  return response.data;
};

export const useOrderTrackingLoanSearch = () => {
  return useMutation<Response, AxiosError<ErrorData>, Request>(request =>
    getOrderTrackingLoanSearch(request),
  );
};

// export const useOrderTrackingLoanSearch = (
//   request: Request,
// ): UseQueryResult<Response, AxiosError<ErrorData>> =>
//   useQuery(['orderTrackingLoanSearch', request], () =>
//     getOrderTrackingLoanSearch(request),
//   );
