import { IComboBox, IComboBoxOption } from 'office-ui-fabric-react';
import * as React from 'react';
import { Container, Label, ComboBox } from './styles';

type Vendor = {
  vendorId: number;
  vendorName: string;
};

interface VendorsProps {
  isLoading: boolean;
  vendors: Vendor[];
  selectedVendorKey?: string | number;
  onSelectVendor: (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
  ) => void;
}

function Vendors({
  isLoading,
  selectedVendorKey,
  onSelectVendor,
  vendors,
}: VendorsProps) {
  if (isLoading) {
    return null;
  }

  const vendorsComboBoxData: IComboBoxOption[] = vendors.map(
    ({ vendorId: key, vendorName: text }) => ({
      key,
      text,
    }),
  );
  vendorsComboBoxData.unshift({ key: 0, text: 'All' });

  const comboboxStyle = {
    container: { marginTop: '16px !important', maxWidth: 196 },
    callout: { minWidth: 196 },
  };
  return vendors.length > 1 ? (
    <Container>
      <Label>Vendor</Label>
      <ComboBox
        id="vendors"
        selectedKey={selectedVendorKey}
        options={vendorsComboBoxData}
        allowFreeform
        autoComplete="on"
        onChange={onSelectVendor}
        styles={comboboxStyle}
      />
    </Container>
  ) : null;
}

export default Vendors;
