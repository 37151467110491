import React from 'react';
import { ITooltipParams } from 'ag-grid-community';
import { BaseGrid } from '@samc/react-ui-grid';
import { ConfirmationDialogOptions, confirm } from '@samc/react-ui-core';
import { Instructions, useStepper } from '../../Common';
import { BPOLoanData } from '../../../context/order/bpo/types';
import ExtendedGridFieldConfiguration from '../../../types/ExtendedGridFieldConfiguration';
import {
  DeleteBtCellRenderer,
  LoanIdRenderer,
  PopUpRenderer,
  SelectableRenderer,
  cellStyle,
} from './config';
import { useOrder } from '../../../context/order';
import { extractAddresses } from '../../../context/order/bpo/util';

type AddressSuggestionGridProps = {
  instructions?: JSX.Element;
  data: BPOLoanData[];
  suggestedAddressLabel: string;
};

function AddressSuggestionGrid({
  instructions,
  data,
  suggestedAddressLabel,
}: AddressSuggestionGridProps) {
  const {
    order,
    bpoActions: { onChangeSingleBPOValidationData },
  } = useOrder();
  const { onRefreshData } = useStepper();

  const onDeleteConfirm = React.useCallback(
    async (rowData: BPOLoanData) => {
      const selectedRows = [rowData.row];

      if (selectedRows.length === 0) {
        return;
      }

      const dialogOptions: ConfirmationDialogOptions = {
        title: `Delete Loan`,
        detail: `Are you sure you want to delete loan ${rowData.clientLoanId}? This cannot be undone`,
        confirmText: 'Delete',
        cancelText: 'Cancel',
      };

      const response = await confirm(dialogOptions);

      if (response && rowData.bpoOrderStagingId) {
        let loan = order.bpoLoanValidation.data.find(
          possibleMatch => possibleMatch.row === rowData.row,
        );
        if (loan === undefined) return;

        loan = {
          ...loan,
          ...rowData,
          isDeleted: true,
          hasChange: true,
        };

        onChangeSingleBPOValidationData(loan);
        onRefreshData();

        /*
        deleteBPOStagingLoansMutation.mutate(
          {
            orderId,
            loans: [{ ...rowData, isDeleted: true }],
          },
          {
            onSuccess: deleteResponse => {
              const lookup: { [index: number]: BPOLoanData } = {};
              order.bpoLoanValidation.data.forEach(loan => {
                lookup[loan.row] = loan;
              });

              deleteResponse.data.forEach((loan, index) => {
                if (lookup[loan.row]) {
                  deleteResponse.data[index] = lookup[loan.row];
                }
              });

              onChangeBPOValidationData(deleteResponse);
              onRefreshData();
            },
          },
        );
        */
      }
    },
    [
      onChangeSingleBPOValidationData,
      onRefreshData,
      order.bpoLoanValidation.data,
    ],
  );

  const fields: ExtendedGridFieldConfiguration[] = React.useMemo(
    () => [
      {
        field: 'clientLoanId',
        headerName: 'Loan ID',
        width: 100,
        cellRenderer: 'LoanIdRenderer',
      },
      {
        field: 'originalAddress',
        headerName: 'Original Address',
        cellRenderer: 'PopUpRenderer',
        tooltipValueGetter: () => '',
        cellStyle: {
          height: '100%',
          ...cellStyle,
        },
        flex: 1,
      },
      {
        field: 'selectedAddress',
        headerName: suggestedAddressLabel,
        cellRenderer: 'SelectableRenderer',
        valueFormatter: () => '',
        cellStyle: {
          color: 'black',
          ...cellStyle,
        },
        tooltipValueGetter: () => '',
        autoHeight: true,
        flex: 1,
      },
      {
        field: '',
        headerName: '',
        cellRenderer: 'DeleteBtCellRenderer',
        cellRendererParams: {
          onClick(rowData: BPOLoanData) {
            onDeleteConfirm(rowData);
          },
        },
        valueFormatter: () => 'Delete',
        tooltipValueGetter: (params: ITooltipParams) =>
          params.valueFormatted || '',
        width: 120,
        filter: false,
        isPinned: 'right',
      },
    ],
    [onDeleteConfirm, suggestedAddressLabel],
  );

  const gridData = React.useMemo(
    () =>
      data
        .filter(
          item =>
            item.isDeleted !== true &&
            extractAddresses(item).suggested.length === 1 &&
            order.bpoLoanValidation.correctionLoans?.includes(item.row),
        )
        .concat(
          data.filter(
            item =>
              item.isDeleted !== true &&
              extractAddresses(item).suggested.length > 1 &&
              order.bpoLoanValidation.correctionLoans?.includes(item.row),
          ),
        )
        .concat(
          data.filter(item => {
            const addressLookup = extractAddresses(item);
            return (
              item.isDeleted !== true &&
              addressLookup.suggested.length === 0 &&
              (addressLookup.original === undefined ||
                addressLookup.original.isValidated !== true ||
                addressLookup.original.isSelected !== true ||
                (addressLookup.original.isValidated === true &&
                  addressLookup.original.isSelected === true &&
                  addressLookup.edited !== undefined)) &&
              order.bpoLoanValidation.correctionLoans?.includes(item.row)
            );
          }),
        ),
    [data, order.bpoLoanValidation.correctionLoans],
  );

  return (
    <>
      {instructions && <Instructions>{instructions}</Instructions>}
      <BaseGrid
        data={gridData}
        fields={fields}
        idField="row"
        // onGridReady={onGridReady}
        frameworkComponents={{
          LoanIdRenderer,
          PopUpRenderer,
          DeleteBtCellRenderer,
          SelectableRenderer,
        }}
        sizeColumnsToFit
        suppressColumnFilters
        suppressRowSelector
        rowsPerPage={100}
      />
    </>
  );
}

export default AddressSuggestionGrid;
