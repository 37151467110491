import * as React from 'react';
import { Field, FieldProps, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { IComboBoxOption } from 'office-ui-fabric-react';
import { Dropdown, TextField } from '../../Common/Form';
import { Button, Modal } from '../../Common';
import { useStates } from '../../../queries/useStates';
import { Address } from '../../../context/order/bpo/types';
import { Row } from './styles';
import { OrderTrackingLoan } from '../../../context/order/appraisal/types';

const validationSchema = Yup.object().shape({
  street: Yup.string().required('Required'),
  unitNumber: Yup.string(),
  city: Yup.string().min(5, 'Too Short!').required('Required'),
  state: Yup.string().min(2, 'Too Short!').required('Required'),
  zip: Yup.string().min(5, 'Too Short!').required('Required'),
});

export interface EditAddressModalProps {
  isEditAddressModalOpen: boolean;
  isOpenPBOEditAddressCancelConfirm: boolean;
  isLoading: boolean;
  rowData: OrderTrackingLoan;
  handleCancelClose: () => void;
  handleSaveAddress: (address: Address) => void;
  handleCancelLoan: () => void;
}

function EditAddressModal({
  isEditAddressModalOpen,
  isOpenPBOEditAddressCancelConfirm,
  isLoading,
  rowData,
  handleCancelClose,
  handleSaveAddress,
  handleCancelLoan,
}: EditAddressModalProps) {
  const states = useStates();

  const statesComboBoxData: IComboBoxOption[] = React.useMemo(() => {
    return states.data
      ? states.data.map(({ code, name }) => ({
          key: code,
          text: `${code} - ${name}`,
        }))
      : [];
  }, [states.data]);

  return (
    <Modal
      isOpen={isEditAddressModalOpen}
      onDismiss={handleCancelClose}
      width={500}
      height={440}
    >
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          street: rowData.address,
          unitNumber: rowData.unitNumber,
          city: rowData.city,
          state: rowData.state,
          zip: rowData.zip,
        }}
        onSubmit={async ({ street, unitNumber, city, state, zip }) => {
          handleSaveAddress({ street, unitNumber, city, state, zip });
        }}
      >
        {({ isValid, dirty }) => (
          <Form
            translate="no"
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <h3
              style={{
                fontSize: 18,
                fontWeight: 700,
              }}
            >
              Edit Address Or <span style={{ color: 'red' }}>Cancel Loan</span>
            </h3>
            <Field name="street">
              {(fieldProps: FieldProps) => (
                <TextField
                  styles={{ root: { height: 86 } }}
                  label="Street"
                  required
                  {...fieldProps}
                />
              )}
            </Field>
            <Field name="unitNumber">
              {(fieldProps: FieldProps) => (
                <TextField
                  styles={{ root: { height: 86 } }}
                  label="Suite/Unit (optional)"
                  {...fieldProps}
                />
              )}
            </Field>
            <Field name="city">
              {(fieldProps: FieldProps) => (
                <TextField
                  styles={{ root: { height: 86 } }}
                  label="City"
                  required
                  {...fieldProps}
                />
              )}
            </Field>
            <Row>
              <Field name="state">
                {(fieldProps: FieldProps) => (
                  <Dropdown
                    styles={{ root: { height: 86, width: 225 } }}
                    id="state"
                    label="State"
                    options={statesComboBoxData}
                    calloutProps={{ calloutMinWidth: 300 }}
                    required
                    {...fieldProps}
                  />
                )}
              </Field>
              <Field name="zip">
                {(fieldProps: FieldProps) => (
                  <TextField
                    styles={{ root: { height: 86, width: 225 } }}
                    label="Zip"
                    required
                    {...fieldProps}
                  />
                )}
              </Field>
            </Row>

            <Row>
              <Button
                type="reset"
                width="32%"
                height="30px"
                variant="secondary"
                alternate
                onClick={handleCancelClose}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                width="32%"
                height="30px"
                variant="primary"
                isLoading={isLoading && !isOpenPBOEditAddressCancelConfirm}
                disabled={!(isValid && dirty)}
              >
                Save Address
              </Button>
              <Button
                type="reset"
                width="32%"
                height="30px"
                variant="tertiary"
                disabled={isLoading}
                onClick={handleCancelLoan}
              >
                Cancel Loan
              </Button>
            </Row>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default EditAddressModal;
