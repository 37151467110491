import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import api from '../services/api';

type RemoveOrderParams = {
  orderId: string;
};

async function removeOrder(params: RemoveOrderParams) {
  await api.delete(`Order/unsubmitted/${params.orderId}`);
}

export function useRemoveOrder() {
  return useMutation<void, AxiosError<ErrorData>, RemoveOrderParams>(params =>
    removeOrder(params),
  );
}
