import * as React from 'react';
import { UseStepperData } from '../../../../hooks/useStepperData';
import { ActionTypes, StepperData, IStep } from './types';

interface IUseActions {
  onInitSteps: (data: StepperData[], initialStep?: number | string) => void;
  onNextStep: () => void;
  onPreviousStep: () => void;
  onClearStep: () => void;
  onNavigateToStep: (index: number) => void;
  onReviewStep: (stepNumber: number) => void;
  onFinalizeStep: () => void;
  onResetSteps: () => void;
  onRefreshData: () => void;
}

interface Props {
  dispatch: React.Dispatch<ActionTypes>;
}
export default function useActions({ dispatch }: Props): IUseActions {
  const getInitialData = (data: StepperData[]) => {
    const initialData = data.map((props, index: number) => ({
      id: index + 1,
      ...props,
    }));
    return initialData as IStep[];
  };

  const onInitSteps = React.useCallback(
    (data, initialStep) => {
      dispatch({
        type: 'INIT_STEPPER',
        payload: { steps: getInitialData(data), initialStep },
      });
    },
    [dispatch],
  );

  const onNextStep = () => {
    dispatch({ type: 'NEXT_STEP' });
  };

  const onPreviousStep = () => {
    dispatch({ type: 'PREVIOUS_STEP' });
  };

  const onClearStep = () => {
    dispatch({ type: 'CLEAR_STEP' });
  };

  const onNavigateToStep = (index: number) => {
    dispatch({ type: 'NAVIGATE_TO', payload: { index } });
  };

  const onReviewStep = (stepNumber: number) => {
    dispatch({ type: 'JUMP_TO', payload: { stepNumber } });
  };

  const onFinalizeStep = () => {
    dispatch({ type: 'SET_SUBMITTED' });
  };

  const onResetSteps = () => {
    dispatch({ type: 'RESET_STEPPER' });
  };

  const onRefreshData = (stepperData?: UseStepperData) => {
    dispatch({ type: 'SET_REFRESH_DATA', payload: { stepperData } });
  };

  return {
    onInitSteps,
    onNextStep,
    onPreviousStep,
    onClearStep,
    onNavigateToStep,
    onReviewStep,
    onFinalizeStep,
    onResetSteps,
    onRefreshData,
  };
}
