import * as React from 'react';
import { useAgGridApi, ChangeDataArgs } from '@samc/react-ui-grid';
import { ITooltipParams, ValueFormatterParams } from 'ag-grid-community';
import { RequestUpdateModalProps } from '..';
import ExtendedGridFieldConfiguration from '../../../../../types/ExtendedGridFieldConfiguration';
import { Button } from '../../../../Common';
import { ButtonsWrapper, Required, Container, GridWrapper } from './styles';
import { cellStyle, EditableRenderer, SelectableRenderer } from './config';

const Grid = React.lazy(() => import('../../../../Common/Grid'));

type DisputedLoan = {
  loanId: string;
  clientLoanId: string;
  disputeComment: string;
  disputeReason: string;
};

function MultipleRequestsDisputeGrid({
  handleCloseRequestUpdate,
  handleRequestUpdate,
  isLoading,
  selectedLoans,
}: RequestUpdateModalProps) {
  const [gridApi, onGridReady] = useAgGridApi();
  const [disputedLoans, setDisputedLoans] = React.useState<DisputedLoan[]>([]);

  React.useEffect(() => {
    setDisputedLoans(() =>
      selectedLoans.map(loan => ({
        ...loan,
        disputeReason: '',
        disputeComment: '',
      })),
    );
  }, [selectedLoans]);

  const isInvalidRequestGrid = React.useMemo(
    () =>
      disputedLoans.some(
        loan => loan.disputeReason === '' || loan.disputeComment === '',
      ),
    [disputedLoans],
  );

  const onChangeData = React.useCallback(
    (args: ChangeDataArgs) => {
      const rowIndex = args.cellValueChangedEvent?.rowIndex;
      const newValue = args.cellValueChangedEvent?.newValue as string;
      const colId = args.cellValueChangedEvent?.column.getColId();

      if (rowIndex !== undefined && rowIndex !== null && colId) {
        setDisputedLoans(prevState => {
          const updatedState = [...prevState];
          updatedState[rowIndex] = {
            ...prevState[rowIndex],
            [colId]: newValue,
          };
          return [...updatedState];
        });
      }
    },
    [setDisputedLoans],
  );

  const onSubmit = React.useCallback(() => {
    if (gridApi) {
      handleRequestUpdate(disputedLoans);
      handleCloseRequestUpdate();
    }
  }, [disputedLoans, gridApi, handleCloseRequestUpdate, handleRequestUpdate]);

  const fields: ExtendedGridFieldConfiguration[] = [
    {
      field: 'clientLoanId',
      headerName: 'Loan ID',
      type: 'string',
      width: 100,
      flex: 1,
    },
    {
      field: 'addresses',
      headerName: 'Address',
      type: 'string',
      valueFormatter: (params: ValueFormatterParams) => {
        return (
          `${`${params.data.address} ${params.data.unitNumber}`.trim()}, ` +
          `${params.data.city}, ${params.data.state}, ${params.data.zip}`
        );
      },
      tooltipValueGetter: (params: ITooltipParams) => {
        return (
          `${`${params.data.address} ${params.data.unitNumber}`.trim()}, ` +
          `${params.data.city}, ${params.data.state}, ${params.data.zip}`
        );
      },
      width: 200,
    },
    {
      field: 'disputeReason',
      headerName: 'Request Reason',
      type: 'string',
      cellRenderer: 'SelectableRenderer',
      cellStyle,
      width: 200,
    },
    {
      field: 'disputeComment',
      headerName: 'Comment',
      type: 'string',
      cellRenderer: 'EditableRenderer',
      cellStyle,
      width: 200,
    },
  ];

  return (
    <Container>
      <GridWrapper>
        <Grid
          onGridReady={onGridReady}
          data={disputedLoans}
          fields={fields}
          onChangeData={onChangeData}
          frameworkComponents={{
            EditableRenderer,
            SelectableRenderer,
          }}
          sizeColumnsToFit
          suppressRowSelector
          suppressColumnFilters
          suppressPaginationPanel
          idField="orderItemId"
        />
      </GridWrapper>
      <Required>
        Reasons are required for all loans. Empty reasons are highlighted above.
      </Required>
      <ButtonsWrapper>
        <Button
          type="reset"
          width="49.25%"
          height="30px"
          variant="secondary"
          alternate
          onClick={handleCloseRequestUpdate}
        >
          Cancel
        </Button>
        <Button
          onClick={onSubmit}
          type="button"
          width="49.25%"
          height="30px"
          variant="secondary"
          isLoading={isLoading}
          disabled={isInvalidRequestGrid}
        >
          Request Update for {selectedLoans.length} Loans
        </Button>
      </ButtonsWrapper>
    </Container>
  );
}

export default MultipleRequestsDisputeGrid;
