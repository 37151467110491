import {
  ColumnApi,
  GridApi,
  ICellRendererParams,
  RowNode,
} from 'ag-grid-enterprise';
import React from 'react';
import {
  FaCheckCircle,
  FaClock,
  FaExclamationCircle,
  FaEye,
} from 'react-icons/fa';
import { MdEdit } from 'react-icons/md';
import { FiDownload } from 'react-icons/fi';
import { IoMdArrowDropdown } from 'react-icons/io';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { useCurrentUser } from '../../../features/authorization';
import {
  Editable,
  ProductCell,
  LinkButton,
  ContainerIcons,
  WrapperButton,
} from './styles';
import appraisalIcon from '../../../assets/icon-product-appraisal.svg';
import indexingIcon from '../../../assets/icon-product-indexing.svg';
import bpoIcon from '../../../assets/icon-product-bpo.svg';
import ausIcon from '../../../assets/icon-product-aus.svg';
import Callout from '../../Common/Callout';
import { OrderTrackingData } from '../../../queries/useOrders';
import { ProductType } from '../../../context/order/types';

export interface Params {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  node: RowNode;
  rowIndex: number;
  api: GridApi;
  columnApi: ColumnApi;
}

interface Icons {
  [name: string]: string;
}

interface BtnCellRendererProps extends ICellRendererParams {
  data: OrderTrackingData;
  clicked: (order: OrderTrackingData) => void;
  value: string;
}

interface IViewResultsProps extends ICellRendererParams {
  data: OrderTrackingData;
  clicked: (order: OrderTrackingData) => void;
  value: string;
  handleDownloadResults: (
    orderId: string,
    orderNumber: string,
    reportType: string,
  ) => Promise<void>;
}

interface Actions {
  label: string;
  color?: string;
  icon?: React.ReactElement;
  handleClick?: () => void;
  options?: LinkOption[];
  entitlement?: boolean;
}
interface OrderStatusContentActions {
  [key: string]: {
    color: string;
    icon: React.ReactElement;
    message: string;
    action: Actions[];
  };
}

interface LinkOption {
  label: string;
  action?: () => void;
}
interface OrderStatusContent {
  [key: string]: {
    color: string;
    icon: React.ReactElement;
    message: string;
    action: Actions;
  };
}

export function BtnCellRenderer({
  clicked,
  value,
  data,
}: BtnCellRendererProps) {
  const handleClick = () => {
    clicked(data);
  };

  const cellValue =
    data.productName === 'Broker Price Opinion' && value === 'In Progress'
      ? 'BPO In Progress'
      : value;

  const orderStatusContent: OrderStatusContent = {
    Completed: {
      color: '#36b300',
      icon: <FaCheckCircle size={14} />,
      message: 'Order Complete',
      action: { label: 'View Results', color: '#133D91', handleClick },
    },
    Error: {
      color: '#BD0E08',
      icon: <FaExclamationCircle size={14} />,
      message: 'Error',
      action: { label: 'Unable to process order' },
    },
    'Action Required': {
      color: '#e6930a',
      icon: <FaExclamationCircle size={14} />,
      message: `Action Required: ${data.status || '__'}`,
      action: {
        label: 'Upload Additional Information',
        color: '#133D91',
      },
    },
    'In Progress': {
      color: '#7a7f87',
      icon: <FaClock size={14} />,
      message: 'In Progress',
      action: { label: '' },
    },
    'BPO In Progress': {
      color: '#7a7f87',
      icon: <FaClock size={14} />,
      message: `In Progress: ${data.status || '__'}`,
      action: {
        label: `${data.status || '__'}`,
        color: '#133D91',
        handleClick,
      },
    },
  };
  if (!orderStatusContent[cellValue]) {
    return <div />;
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        color: orderStatusContent[cellValue].color,
      }}
    >
      {orderStatusContent[cellValue].icon}

      <p style={{ marginLeft: 10, color: '#000' }}>
        {`${orderStatusContent[cellValue].message}`}
      </p>
    </div>
  );
}

export function BtnCellRendererActions({
  clicked,
  value,
  data,
  handleDownloadResults,
}: IViewResultsProps) {
  const currentUser = useCurrentUser();
  const buttonId = useId('callout-button');
  const labelId = useId('callout-label');
  const descriptionId = useId('callout-description');
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
    useBoolean(false);
  const handleClick = () => {
    clicked(data);
  };

  const cellValue =
    data.productName === 'Broker Price Opinion' && value === 'In Progress'
      ? 'BPO In Progress'
      : value;
  const productName = data.productName as ProductType;
  // This is the grid for Order Tracking Order Status page
  const orderStatusContent: OrderStatusContentActions = {
    Completed: {
      color: '#133D91',
      icon: <FaCheckCircle size={14} />,
      message: 'Order Complete',
      action:
        productName === 'Broker Price Opinion'
          ? [
              {
                label: 'Order Details',
                color: '#133D91',
                icon: <FaEye size={14} />,
                handleClick,
                entitlement: currentUser.hasEntitlement(
                  'Order Tracking - BPO Order Details',
                ),
              },
            ]
          : [
              {
                label: 'Results',
                color: '#133D91',
                icon: <FaEye size={14} />,
                handleClick,
                entitlement: currentUser.hasEntitlement(
                  'Order Tracking - Appraisal Results',
                ),
              },
              {
                label: 'Reports',
                color: '#133D91',
                icon: <FiDownload size={15} />,
                handleClick: toggleIsCalloutVisible,
                entitlement: currentUser.hasEntitlement(
                  'Order Tracking - Appraisal Reports',
                ),
                options: [
                  {
                    label: 'Initial Report',
                    action: async () => {
                      await handleDownloadResults(
                        data.orderId,
                        data.orderNumber.toString(),
                        'Initial',
                      );
                    },
                  },
                  {
                    label: 'Updated Report',
                    action: async () => {
                      await handleDownloadResults(
                        data.orderId,
                        data.orderNumber.toString(),
                        'Completed',
                      );
                    },
                  },
                ],
              },
            ],
    },
    Error: {
      color: '#133D91',
      icon: <FaExclamationCircle size={14} />,
      message: 'Error:',
      action: [
        {
          label: 'Unable to process order',
          entitlement: currentUser.hasEntitlement('Order Tracking - Errors'),
        },
      ],
    },
    'Action Required': {
      color: '#133D91',
      icon: <FaExclamationCircle size={14} />,
      message: 'Action Required',
      action: [
        {
          label: 'Order Details',
          color: '#133D91',
          icon: <FaEye size={15} />,
          handleClick,
          entitlement: currentUser.hasEntitlement(
            'Order Tracking - BPO Order Details',
          ),
        },
      ],
    },
    'In Progress': {
      color: '#7a7f87',
      icon: <FaClock size={14} />,
      message: 'In Progress',
      action: [{ label: '' }],
    },
    'BPO In Progress': {
      color: '#7a7f87',
      icon: <FaClock size={14} />,
      message: 'In Progress: ',
      action: [
        {
          label: `${'Order Details' || '__'}`,
          color: '#133D91',
          handleClick,
          icon: <FaEye size={14} />,
          entitlement: currentUser.hasEntitlement(
            'Order Tracking - BPO Order Details',
          ),
        },
      ],
    },
  };

  if (!orderStatusContent[cellValue]) {
    return <div />;
  }

  return (
    <ContainerIcons>
      {orderStatusContent[cellValue].action.map((element, index) =>
        element.entitlement ? (
          <LinkButton
            key={index}
            color={
              element.color
                ? element.color
                : orderStatusContent[cellValue].color
            }
            onKeyUp={element.handleClick}
            onClick={element.handleClick}
            role="button"
            tabIndex={0}
          >
            <span>{element.icon}</span>
            {element.label}
            {element.options && element.options?.length > 0 && (
              <>
                <IoMdArrowDropdown size={14} id={buttonId} />
                <Callout
                  isCalloutVisible={isCalloutVisible}
                  buttonId={buttonId}
                  descriptionId={descriptionId}
                  labelId={labelId}
                  toggleIsCalloutVisible={toggleIsCalloutVisible}
                >
                  <WrapperButton>
                    {element.options.map((option, indexbutton) => (
                      <button
                        key={indexbutton}
                        type="button"
                        onClick={option.action}
                      >
                        {option.label}
                      </button>
                    ))}
                  </WrapperButton>
                </Callout>
              </>
            )}
          </LinkButton>
        ) : null,
      )}
    </ContainerIcons>
  );
}

const icons: Icons = {
  'Appraisal Review': appraisalIcon && appraisalIcon,
  'Acuity Indexing': indexingIcon && indexingIcon,
  Securent: indexingIcon && indexingIcon,
  AUS: ausIcon && ausIcon,
  'Broker Price Opinion': bpoIcon && bpoIcon,
};

export function ProductCellRenderer({ value }: ICellRendererParams) {
  return (
    <ProductCell>
      <img src={icons[value]} alt="product-icon" />
      {value}
    </ProductCell>
  );
}

export function EditableRenderer({ value, setValue }: ICellRendererParams) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const handleBlur = () => {
    if (inputRef.current && value !== inputRef.current.value && setValue) {
      setValue(inputRef.current.value);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      e.key === 'Enter' &&
      inputRef.current &&
      value !== inputRef.current.value &&
      setValue
    ) {
      setValue(inputRef.current.value);
    }
  };

  return (
    <Editable>
      <input
        ref={inputRef}
        defaultValue={value}
        type="text"
        placeholder="Click to add identifier"
        onBlur={handleBlur}
        onKeyUp={handleKeyPress}
      />
      <MdEdit size={16} />
    </Editable>
  );
}
