import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import api from '../services/api';

export async function removeAUSLoanFile({ stagingId }: { stagingId: string }) {
  await api.delete('AUSOrder/loanFileStaging', {
    params: { stagingId },
  });
}

export function useRemoveAUSLoanFile() {
  return useMutation<void, AxiosError<ErrorData>, { stagingId: string }>(
    ({ stagingId }) => removeAUSLoanFile({ stagingId }),
  );
}
