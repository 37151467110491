import * as React from 'react';
import { Field, FieldProps, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { IComboBoxOption } from 'office-ui-fabric-react';
import { Dropdown, TextField } from '../../../Common/Form';
import { Button, Modal } from '../../../Common';
import { useStates } from '../../../../queries/useStates';
import {
  Address,
  BPOLoanAddress,
  BPOLoanData,
} from '../../../../context/order/bpo/types';
import { Row } from './styles';
import { extractAddresses } from '../../../../context/order/bpo/util';
import FormikComboBox from '../../BPOLoanDetails/ManualBPOLoans/FormikComboBox';

const validationSchema = Yup.object().shape({
  street: Yup.string().required('Required'),
  unitNumber: Yup.string(),
  city: Yup.string().min(2, 'Too Short!').required('Required'),
  state: Yup.string().min(2, 'Too Short!').required('Required'),
  zip: Yup.string().min(5, 'Too Short!').required('Required'),
});

export interface OriginalAddressModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  isLoading: boolean;
  rowData: BPOLoanData;
  onSubmit: (address: Address) => void;
}

function OriginalAddressModal({
  isOpen,
  onDismiss,
  isLoading,
  rowData,
  onSubmit,
}: OriginalAddressModalProps) {
  const states = useStates();

  const statesComboBoxData: IComboBoxOption[] = React.useMemo(() => {
    return states.data
      ? states.data.map(({ code, name }) => ({
          key: code,
          text: `${code} - ${name}`,
        }))
      : [];
  }, [states.data]);

  const addressLookup = React.useMemo(
    () => extractAddresses(rowData),
    [rowData],
  );
  const address = addressLookup.original || ({} as BPOLoanAddress);

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} width={340} height={440}>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          street: address.street,
          unitNumber: address.unitNumber,
          city: address.city,
          state: address.state,
          zip: address.zip,
        }}
        onSubmit={async ({ street, unitNumber, city, state, zip }) => {
          onSubmit({ street, unitNumber, city, state, zip });

          onDismiss();
        }}
      >
        {({ isValid, handleReset, dirty, values }) => (
          <Form
            translate="no"
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <h3>Edit Address</h3>
            <Field name="street">
              {(fieldProps: FieldProps) => (
                <TextField
                  styles={{ root: { height: 86 } }}
                  label="Street"
                  required
                  {...fieldProps}
                />
              )}
            </Field>
            <Field name="unitNumber">
              {(fieldProps: FieldProps) => (
                <TextField
                  styles={{ root: { height: 86 } }}
                  label="Suite/Unit (optional)"
                  {...fieldProps}
                />
              )}
            </Field>
            <Field name="city">
              {(fieldProps: FieldProps) => (
                <TextField
                  styles={{ root: { height: 86 } }}
                  label="City"
                  required
                  {...fieldProps}
                />
              )}
            </Field>
            <Row>
              <Field name="state">
                {(fieldProps: FieldProps) => (
                  <FormikComboBox
                    styles={{ container: { height: 86, width: 145 } }}
                    id="state"
                    label="State"
                    options={statesComboBoxData}
                    // calloutProps={{ calloutMinWidth: 300 }}
                    index={0}
                    selectedKey={values.state}
                    required
                    onSetValue={(helpers, option?) => {
                      if (option && option.key) {
                        helpers.setValue(option.key);
                      }
                    }}
                    {...fieldProps}
                  />
                )}
              </Field>
              <Field name="zip">
                {(fieldProps: FieldProps) => (
                  <TextField
                    styles={{ root: { height: 86, width: 145 } }}
                    label="Zip"
                    required
                    {...fieldProps}
                  />
                )}
              </Field>
            </Row>

            <Row>
              <Button
                type="reset"
                width="48.5%"
                height="30px"
                variant="secondary"
                alternate
                onClick={() => {
                  onDismiss();
                  handleReset();
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                width="48.5%"
                height="30px"
                variant="secondary"
                isLoading={isLoading}
                disabled={!(isValid && dirty)}
              >
                Save Address
              </Button>
            </Row>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default OriginalAddressModal;
