import * as React from 'react';
import {
  HubConnection,
  HubConnectionBuilder,
  HttpTransportType,
} from '@microsoft/signalr';
import { IEventContextData } from './types';

const EventContext = React.createContext<IEventContextData>(
  {} as IEventContextData,
);

interface EventProviderProps {
  token: string | null;
  children: React.ReactNode;
}

function EventProvider({ children, token }: EventProviderProps) {
  const [connection, setConnection] = React.useState<null | HubConnection>(
    null,
  );

  React.useEffect(() => {
    if (token) {
      let baseUrl = window.config.gatewayApiUrl;
      if (process.env.REACT_APP_GATEWAY_API_URL) {
        baseUrl = process.env.REACT_APP_GATEWAY_API_URL;
      }

      const connect = new HubConnectionBuilder()
        .withUrl(`${baseUrl}/Hubs/OrderHub`, {
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets,
          accessTokenFactory: () => {
            return token;
          },
        })
        .withAutomaticReconnect()
        .build();

      setConnection(connect);
    }
  }, [token]);

  React.useEffect(() => {
    async function start() {
      try {
        if (connection) {
          await connection.start();
        }
      } catch (err) {
        setTimeout(start, 5000);
      }
    }

    start();
  }, [connection]);

  const value = React.useMemo((): IEventContextData => {
    return {
      connection,
    };
  }, [connection]);

  return (
    <EventContext.Provider value={value}>{children}</EventContext.Provider>
  );
}

function useEvent(): IEventContextData {
  const context = React.useContext(EventContext);

  if (!context) {
    throw new Error('useEvent must be used within a EventProvider');
  }

  return context;
}

export { EventProvider, useEvent };
