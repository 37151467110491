import * as React from 'react';
import { ConfirmationDialogOptions, confirm } from '@samc/react-ui-core';
import { Stack, IStackStyles, IStackTokens } from 'office-ui-fabric-react';
import { FileRejection } from 'react-dropzone';
import { useOrder } from '../../../context/order';
import { Dropzone, Instructions, Loading, StepContainer } from '../../Common';
import { useSetAUSUploadCredit } from '../../../mutations/useSetAUSUploadCredit';
import { useRemoveAUSCreditFile } from '../../../mutations/useRemoveAUSCreditFile';
import { DeleteButton, Grid } from './styles';

interface CreditGridRow {
  rowId: string;
  borrowerNameLoan?: string;
  borrowerNameCredit?: string;
  hasMatch: boolean;
  documentName?: string;
  creditStagingId?: string;
}

function AUSUploadCreditFiles() {
  const {
    order,
    ausActions: { onChangeAUSCredits, onDeleteAUSCreditDocument },
  } = useOrder();

  const { isLoading, mutate: mutateSet } = useSetAUSUploadCredit();
  const { mutate: mutateRemove } = useRemoveAUSCreditFile();

  const stackStylesDropZone: Partial<IStackStyles> = {
    root: { width: 700 },
  };

  const stackToken: IStackTokens = {
    childrenGap: 10,
  };

  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    if (
      fileRejections.length === 0 &&
      order.ausLoan &&
      order.ausLoan.loanStagingId
    ) {
      let creditStagingId;
      if (order.ausLoan.creditInfo && order.ausLoan.creditInfo.length) {
        creditStagingId = order.ausLoan.creditInfo[0].creditStagingId;
      }
      mutateSet(
        {
          loanStagingId: order.ausLoan.loanStagingId,
          creditStagingId,
          files: acceptedFiles,
        },
        {
          onSuccess: creditFile => {
            onChangeAUSCredits(creditFile.creditsInfo);
          },
        },
      );
    }
  };

  const hasAnyFileUploaded = React.useMemo(() => {
    return (
      order.appraisalLoan[0].documents.length > 0 && order.stagingId !== ''
    );
  }, [order.appraisalLoan, order.stagingId]);

  const onDeleteFile = async (creditInfo: CreditGridRow) => {
    if (creditInfo.creditStagingId) {
      const dialogOptions: ConfirmationDialogOptions = {
        title: `Are you sure you want to delete this credit file?`,
        detail: `This cannot be undone.`,
        confirmText: 'Delete File',
        cancelText: 'Cancel',
      };

      const response = await confirm(dialogOptions);

      if (response) {
        mutateRemove(
          { stagingId: creditInfo.creditStagingId },
          {
            onSuccess: () => {
              if (creditInfo.creditStagingId) {
                onDeleteAUSCreditDocument(creditInfo.creditStagingId);
              }
            },
          },
        );
      }
    }
  };

  const gridData: CreditGridRow[] = [];
  const matchLookup: { [key: string]: Array<CreditGridRow> } = {};

  if (order.ausLoan.creditInfo) {
    order.ausLoan.creditInfo.forEach(info => {
      const row = {
        rowId: `credit${info.creditStagingId}`,
        borrowerNameCredit: info.borrowerName,
        documentName: info.documentName,
        hasMatch: info.hasMatch || false,
        creditStagingId: info.creditStagingId,
      };
      gridData.push(row);
      if (matchLookup[info.ssn] === undefined) {
        matchLookup[info.ssn] = [];
      }
      matchLookup[info.ssn].push(row);
    });
  }

  order.ausLoan.borrowerInfos?.forEach(info => {
    if (!info.hasMatch) {
      gridData.push({
        rowId: `loan${info.ssn}`,
        borrowerNameLoan: info.borrowerName,
        hasMatch: info.hasMatch || false,
      });
    } else if (matchLookup[info.ssn]) {
      matchLookup[info.ssn].forEach(row => {
        row.borrowerNameLoan = info.borrowerName;
      });
    }
  });

  return (
    <StepContainer align="center">
      {isLoading && <Loading message="Processing XML File..." />}

      <Stack styles={stackStylesDropZone} tokens={stackToken}>
        {!hasAnyFileUploaded && (
          <Instructions>
            Next, <strong>upload Credit Files (XML).</strong>
          </Instructions>
        )}

        <Dropzone
          onDrop={onDrop}
          extension="XML"
          multiple
          type="wide"
          fileType="Credit File XML"
        />
      </Stack>

      <Grid>
        <thead>
          <tr>
            <th style={{ width: '28px' }}>&nbsp;</th>
            <th style={{ width: '180px' }}>File Name</th>
            <th style={{ width: '195px' }}>Borrower Name (Loan File)</th>
            <th style={{ width: '200px' }}>Borrower Name (Credit File)</th>
            <th style={{ width: '130px' }}>SSN</th>
            <th style={{ width: '100px' }}>Comments</th>
            <th style={{ width: '28px' }}>&nbsp;</th>
          </tr>
        </thead>
        {gridData.map(row => {
          return (
            <tr className={row.hasMatch ? 'hasMatch' : ''}>
              <td aria-label="None" />
              <td style={{ color: 'black' }}>{row.documentName}</td>
              <td>{row.borrowerNameLoan}</td>
              <td>{row.borrowerNameCredit}</td>
              <td>{row.hasMatch ? 'Match' : 'No Match'}</td>
              <td>{row.hasMatch && 'Successful'}</td>
              <td>
                {row.creditStagingId && (
                  <DeleteButton onClick={() => onDeleteFile(row)} />
                )}
              </td>
            </tr>
          );
        })}
      </Grid>
    </StepContainer>
  );
}

export default AUSUploadCreditFiles;
