import * as React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  ConfirmationDialogOptions,
  ToastMessage,
  toastSuccess,
  confirm,
} from '@samc/react-ui-core';
import api from '../services/api';
import { useOrder } from '../context/order';
import {
  IProduct,
  ISubProduct,
  IUnsubmittedOrder,
} from '../context/order/types';
import SelectProduct from '../components/NewOrder/SelectProduct';
import AppraisalLoanDetails from '../components/NewOrder/AppraisalLoanDetails';
import IndexingLoanDetails from '../components/NewOrder/IndexingLoanDetails';
import BPOLoanDetails from '../components/NewOrder/BPOLoanDetails';
import BPOError from '../components/NewOrder/BPOError';
import BPOReviewSubmit from '../components/NewOrder/BPOReviewSubmit';
import AppraisalReviewSubmit from '../components/NewOrder/AppraisalReviewSubmit';
import {
  StepperContextData,
  StepperData,
} from '../components/Common/Stepper/context/types';
import { useToast } from '../context/toaster';
import BPOAddressSuggestion from '../components/NewOrder/BPOAddressSuggestion';
import { useSetAppraisalOrder } from '../mutations/useSetAppraisalOrder';
import {
  SetBPOLoansUploadParams,
  useSetBPOLoans,
} from '../mutations/useSetBPOLoans';
import { useSetBPOOrder } from '../mutations/useSetBPOOrder';
import { useRemoveOrder } from '../mutations/useRemoveOrder';
import {
  GetAppraisalOrderResponse,
  useAppraisalOrder,
} from '../queries/useAppraisalOrder';
import { queryClient } from '../services/queryClient';
import { GetBPOOrderResponse, useBPOOrder } from '../queries/useBPOOrder';
import { useSubmitBPOOrder } from '../mutations/useSubmitBPOOrder';
import { useSubmitAppraisalOrder } from '../mutations/useSubmitAppraisalOrder';
import SecurentUploadLoan from '../components/NewOrder/SecurentUploadLoan';
import AUSUploadLoanFile from '../components/NewOrder/AUSUploadLoanFile';
import AUSUploadCreditFiles from '../components/NewOrder/AUSUploadCreditFiles';
import AUSReviewSubmit from '../components/NewOrder/AUSReviewSubmit';
import { formatErrorMessage } from '../utils/helpers';
import { useDivisions } from '../queries/useDivisions';
import { useProducts } from '../queries/useProducts';

interface ProductsStepperData {
  [key: string]: StepperData[];
}

interface Data {
  stepperData: StepperData[];
  initialStep?: number | string;
}

interface ValidateBPOOrderProps {
  acknowledgeWarnings?: boolean;
  onNextStep: (response: GetBPOOrderResponse) => void;
}

export interface UseStepperData {
  data: Data;
  isLoading: boolean;
  isSaving: boolean;
  savingMessage: string;
  onValidateBPOOrder: (options: ValidateBPOOrderProps) => void;
}

function useStepperData(): UseStepperData {
  const isMounted = React.useRef(false);
  const history = useHistory();
  const location = useLocation();
  const { id, productOrder } = useParams<Record<string, string | undefined>>();
  const { order, orderActions, bpoActions } = useOrder();
  const { onSaveOrderId, onLoadOrder, onInitOrder, onLoadBPOOrder } =
    orderActions;
  const { onChangeBPOLoanError, onChangeBPOValidationData } = bpoActions;
  const setAppraisalOrderMutation = useSetAppraisalOrder();
  const submitAppraisalOrderMutation = useSubmitAppraisalOrder();
  const setBPOLoansMutation = useSetBPOLoans();
  const setBPOOrderMutation = useSetBPOOrder();
  const submitBPOOrderMutation = useSubmitBPOOrder();
  const removeOrderMutation = useRemoveOrder();
  const { toastWarning } = useToast();
  const divisionsQuery = useDivisions();

  const [error, setError] = React.useState<string | undefined>(undefined);

  const appraisalOrder = useAppraisalOrder({
    orderId: id || '',
    productName: productOrder || '',
  });

  const bpoOrder = useBPOOrder({
    orderId: id || '',
    productName: productOrder || '',
  });

  const isLoading = React.useMemo(() => {
    return (
      appraisalOrder.isLoading || bpoOrder.isLoading
      // setBPOOrderMutation.isLoading
      // setBPOLoansMutation.isLoading ||
      // setAppraisalOrderMutation.isLoading ||
      // setBPOLoanMutation.isLoading ||
      // submitBPOOrderMutation.isLoading
    );
  }, [appraisalOrder.isLoading, bpoOrder.isLoading]);

  const { isSaving, savingMessage } = React.useMemo(() => {
    let message = 'Saving';
    if (setBPOLoansMutation.isLoading) {
      message = 'Checking for Errors';
    } else if (submitBPOOrderMutation.isLoading) {
      message = 'Submitting';
    }

    return {
      isSaving:
        setBPOOrderMutation.isLoading ||
        setBPOLoansMutation.isLoading ||
        submitBPOOrderMutation.isLoading,
      savingMessage: message,
    };
  }, [
    submitBPOOrderMutation.isLoading,
    setBPOLoansMutation.isLoading,
    setBPOOrderMutation.isLoading,
  ]);

  const onSetAppraisalOrder = React.useCallback(
    (onNextStep?: () => void) => {
      const formData = {
        orderId: order.orderId,
        companyDivision: order.division ? order.division : undefined,
        stagingId: order.stagingId,
        vendorId: order.product.vendorId,
        productId: order.product.productId,
        clientLoanId: order.appraisalLoan[0].clientLoanId,
        productPrice: {
          productId: order.product.productId,
          productName: order.product.productName,
          price: order.product.cvpPrice,
        },
      };

      setAppraisalOrderMutation.mutate(formData, {
        onSuccess: ({ orderId }) => {
          onSaveOrderId(orderId);

          if (onNextStep) {
            onNextStep();
          }

          queryClient.invalidateQueries(['appraisalOrder', order.orderId]);
          queryClient.invalidateQueries(['orders', 'UnsubmittedOrders']);
        },
      });
    },
    [
      onSaveOrderId,
      order.appraisalLoan,
      order.division,
      order.orderId,
      order.product.cvpPrice,
      order.product.productId,
      order.product.productName,
      order.product.vendorId,
      order.stagingId,
      setAppraisalOrderMutation,
    ],
  );

  const onSubmitAppraisalOrder = React.useCallback(
    onFinalizeStep => {
      submitAppraisalOrderMutation.mutate(
        { orderId: order.orderId, orderIdentifier: order.orderIdentifier },
        {
          onSuccess: data => {
            queryClient.invalidateQueries('orders');
            orderActions.onChangeOrderNumber(data.orderNumber);

            onFinalizeStep();
          },
        },
      );
    },
    [
      order.orderId,
      order.orderIdentifier,
      orderActions,
      submitAppraisalOrderMutation,
    ],
  );

  const onSubmitIndexingLoan = React.useCallback(
    async onNextStep => {
      if (order.indexingLoan.metaData) {
        const formData = {
          ProductId: order.product && order.product.productId,
          VendorId: order.product && order.product.vendorId,
          DocumentId: order.indexingLoan.metaData.id,
          ColumnName: order.indexingLoan.columnName,
          Validation: false,
          companyDivision: order.division ? order.division : undefined,
        };

        if (!order.orderId) {
          const response = await api.post('/IndexingOrder', formData);
          const { orderId } = response.data;
          onSaveOrderId(orderId);
        } else {
          Object.assign(formData, {
            orderId: order.orderId,
          });
          await api.patch('/IndexingOrder', formData);
        }
        onNextStep();
      }
    },
    [
      onSaveOrderId,
      order.division,
      order.indexingLoan.columnName,
      order.indexingLoan.metaData,
      order.orderId,
      order.product,
    ],
  );

  const onSetBPOLoans = React.useCallback(
    (onNextStep?: () => void) => {
      if (order.bpoLoan.metaData) {
        const productsPrice = order.product.subProducts.map(product => ({
          productId: product.productId,
          productName: product.productName,
          price: product.price,
        }));

        const manualData = order.bpoLoan.manualLoans.filter(
          loan =>
            loan.clientLoanId !== '' ||
            loan.street !== '' ||
            loan.city !== '' ||
            loan.state !== '' ||
            loan.zip !== '' ||
            loan.bpoProduct.id !== null ||
            loan.customerReferenceId1 !== '' ||
            loan.customerReferenceId2 !== '' ||
            loan.customerReferenceId3 !== '',
        );

        const params: SetBPOLoansUploadParams = {
          formData: {
            data: manualData,
            orderId: order.orderId,
            productId: order.product.productId,
            vendorId: order.product.vendorId,
            documentId: order.bpoLoan.metaData.id,
            companyDivision: order.division,
            productsPrice,
          },
        };

        setBPOLoansMutation.mutate(params, {
          onSuccess: response => {
            onChangeBPOValidationData(response, true);

            const { orderId, productInfo, isCompletedSuccessfully, data } =
              response;

            if (data === undefined || data.length === 0) {
              setError('No Loans');
            } else if (productInfo) {
              setError(undefined);
              if (productInfo.subProducts) {
                const didPriceChange: boolean = productInfo.subProducts.some(
                  (x: ISubProduct) => x.didPriceChange,
                );

                if (didPriceChange && productInfo.subProducts.length > 0) {
                  const message = productInfo.subProducts
                    .filter((x: ISubProduct) => x.didPriceChange)
                    .map(
                      (product: ISubProduct) =>
                        `The price of ${product.productName} has changed from $${product.price} to $${product.currentPrice} per loan      `,
                    );

                  toastWarning(
                    <ToastMessage
                      title="Price Change"
                      message={JSON.stringify(message)}
                      primaryText=""
                      secondaryText=""
                    />,
                  );
                }
              }
            }

            onChangeBPOLoanError(isCompletedSuccessfully);

            if (order.orderId === '') {
              onSaveOrderId(orderId);
              // history.push(
              //   `/unsubmitted-orders/${orderId}/${order.product.productName}`,
              // );
            }

            queryClient.invalidateQueries(['orders', 'UnsubmittedOrders']);

            if (onNextStep) {
              // Use a timeout to let the state update
              setTimeout(() => {
                onNextStep();
              }, 300);
            }
          },
        });
      }
    },
    [
      onChangeBPOLoanError,
      onChangeBPOValidationData,
      onSaveOrderId,
      order.bpoLoan.manualLoans,
      order.bpoLoan.metaData,
      order.division,
      order.orderId,
      order.product.productId,
      order.product.subProducts,
      order.product.vendorId,
      setBPOLoansMutation,
      toastWarning,
    ],
  );

  const myOrderIdentifier = React.useRef(order.orderIdentifier);

  React.useEffect(() => {
    myOrderIdentifier.current = order.orderIdentifier;
  }, [order.orderIdentifier]);

  const onValidateBPOOrder = React.useCallback(
    ({ acknowledgeWarnings, onNextStep }: ValidateBPOOrderProps) => {
      const formData = {
        orderId: order.orderId,
        vendorId: order.product.vendorId,
        productId: order.product.productId,
        data: order.bpoLoanValidation.data,
      };

      formData.data = formData.data
        .filter(loan => {
          let include = loan.hasChange === true;

          if (acknowledgeWarnings) {
            loan.errors?.forEach(loanError => {
              if (
                loanError.type === 'Warning' &&
                loanError.errorAcknowledged !== true
              ) {
                loanError.errorAcknowledged = true;
                loan.hasChange = true;
                include = true;
              }
            });
          }

          return include;
        })
        .map(loan => {
          const loanData = { ...loan };
          loanData.addresses = loanData.addresses.map(address => {
            const addressData = { ...address };
            if (addressData.bpoOrderStagingAddressId === 'edited') {
              addressData.bpoOrderStagingAddressId = undefined;
            }
            return addressData;
          });
          return loanData;
        });

      if (formData.data.length === 0) {
        if (onNextStep) {
          onNextStep({
            ...order.bpoLoanValidation,
            orderId: order.orderId,
            isCompletedSuccessfully: false,
            identifier: order.orderIdentifier || '',
            companyDivision: order.division || 0,
            summary: order.bpoLoanValidation.summary || {
              loansCant: 0,
              exteriorCant: 0,
              exteriorPrice: 0,
              interiorCant: 0,
              interiorPrice: 0,
              orderTotal: 0,
            },
            productInfo: {
              productId: order.product.productId,
              productName: order.product.productName,
              vendorId: order.product.vendorId,
              price: order.product.price || 0,
              subProducts: [],
            },
          });
        }
      } else {
        setBPOOrderMutation.mutate(formData, {
          onSuccess: response => {
            onChangeBPOValidationData(response);

            if (onNextStep) {
              onNextStep(response);
            }

            queryClient.invalidateQueries(['orders', 'UnsubmittedOrders']);
          },
          onError: async err => {
            const message = await formatErrorMessage(err);
            setError(message);
          },
        });
      }
    },
    [
      onChangeBPOValidationData,
      order.bpoLoanValidation,
      order.division,
      order.orderId,
      order.orderIdentifier,
      order.product.price,
      order.product.productId,
      order.product.productName,
      order.product.vendorId,
      setBPOOrderMutation,
    ],
  );

  const onSubmitBPOLoans = React.useCallback(
    (onFinalizeStep, stepper) => {
      if (order.bpoLoanValidation.data.some(loan => loan.hasChange)) {
        onValidateBPOOrder({
          acknowledgeWarnings: true,
          onNextStep: () => {
            // Stay on screen
          },
        });
      } else if (
        order.bpoLoanValidation.hasError ||
        order.bpoLoanValidation.hasUnacknowledgedWarning ||
        order.bpoLoanValidation.hasUnselected
      ) {
        if (
          order.bpoLoanValidation.hasError ||
          order.bpoLoanValidation.hasUnacknowledgedWarning
        ) {
          stepper?.onNavigateToStep(2);
        } else if (
          order.bpoLoanValidation.errorLoans &&
          order.bpoLoanValidation.errorLoans.length
        ) {
          stepper?.onNavigateToStep(3);
        } else {
          stepper?.onNavigateToStep(2);
        }
      } else {
        submitBPOOrderMutation.mutate(
          {
            orderId: order.orderId,
            orderIdentifier: myOrderIdentifier.current,
          },
          {
            onSuccess: response => {
              orderActions.onChangeOrderNumber(response.orderNumber);

              onFinalizeStep();

              queryClient.invalidateQueries('orders');
            },
          },
        );
      }
    },
    [
      order.bpoLoanValidation.data,
      order.bpoLoanValidation.hasError,
      order.bpoLoanValidation.hasUnacknowledgedWarning,
      order.bpoLoanValidation.hasUnselected,
      order.bpoLoanValidation.errorLoans,
      order.orderId,
      onValidateBPOOrder,
      submitBPOOrderMutation,
      orderActions,
    ],
  );

  const submitBPOLoansLabel = React.useMemo(() => {
    if (order.bpoLoanValidation.data.some(loan => loan.hasChange)) {
      return 'Save';
    }

    if (
      order.bpoLoanValidation.hasError ||
      order.bpoLoanValidation.hasUnacknowledgedWarning ||
      order.bpoLoanValidation.hasUnselected
    ) {
      return 'Re-check for Errors';
    }

    return 'Submit Order';
  }, [
    order.bpoLoanValidation.data,
    order.bpoLoanValidation.hasError,
    order.bpoLoanValidation.hasUnacknowledgedWarning,
    order.bpoLoanValidation.hasUnselected,
  ]);

  const onValidateBPOErrors = React.useCallback(
    (onNextStep?: () => void) => {
      const hadChanges = order.bpoLoanValidation.data.some(
        loan => loan.hasChange,
      );
      onValidateBPOOrder({
        acknowledgeWarnings: true,
        onNextStep: response => {
          if (!hadChanges && onNextStep && !response.hasError) {
            onNextStep();
          } else {
            // Not expected to get here
          }
        },
      });
    },
    [onValidateBPOOrder, order.bpoLoanValidation.data],
  );

  const onValidateBPOCorrections = React.useCallback(
    (onNextStep?: () => void, stepper?: StepperContextData) => {
      const hasChanges = order.bpoLoanValidation.data.some(
        loan => loan.hasChange,
      );

      if (hasChanges) {
        onValidateBPOOrder({
          onNextStep: () => {
            // Do nothing
          },
        });
      } else if (
        !order.bpoLoanValidation.hasUnselected &&
        !order.bpoLoanValidation.hasError &&
        !order.bpoLoanValidation.hasUnacknowledgedWarning &&
        onNextStep
      ) {
        onNextStep();
      } else if (
        order.bpoLoanValidation.hasError ||
        order.bpoLoanValidation.hasUnacknowledgedWarning
      ) {
        stepper?.onNavigateToStep(2);
      } else {
        // Not exptected to get here
      }
    },
    [
      onValidateBPOOrder,
      order.bpoLoanValidation.data,
      order.bpoLoanValidation.hasError,
      order.bpoLoanValidation.hasUnacknowledgedWarning,
      order.bpoLoanValidation.hasUnselected,
    ],
  );

  const onRemoveOrder = React.useCallback(
    onResetSteps => {
      removeOrderMutation.mutate(
        { orderId: order.orderId },
        {
          onSuccess: () => {
            toastSuccess(
              <ToastMessage
                title="Order Canceled"
                message="The order was successfully canceled."
              />,
            );

            const [, route] = location.pathname.split('/');

            if (route === 'unsubmitted-orders') {
              history.push('/unsubmitted-orders');
            }
            onInitOrder();
            onResetSteps();
            queryClient.invalidateQueries(['orders', 'UnsubmittedOrders']);
          },
        },
      );
    },
    [
      history,
      location.pathname,
      onInitOrder,
      order.orderId,
      removeOrderMutation,
    ],
  );

  const onDeleteConfirm = React.useCallback(
    async onResetSteps => {
      const dialogOptions: ConfirmationDialogOptions = {
        title: `Cancel order?`,
        detail: `Are you sure you want to cancel this order? This cannot be undone`,
        confirmText: 'Cancel Order',
        cancelText: "Don't Cancel",
      };

      const response = await confirm(dialogOptions);
      if (response) {
        onRemoveOrder(onResetSteps);
      }
    },
    [onRemoveOrder],
  );

  const productsQuery = useProducts();
  const productLookup = React.useMemo(() => {
    const lookup: {
      [key: string]: {
        product: IProduct;
        subProductLookup: { [key: string]: ISubProduct };
      };
    } = {};
    if (productsQuery.data) {
      productsQuery.data.forEach(product => {
        lookup[product.productId] = {
          product,
          subProductLookup: product.subProducts.reduce<{
            [key: string]: ISubProduct;
          }>((ret, subProduct) => {
            ret[subProduct.productName] = subProduct;
            return ret;
          }, {}),
        };
      });
    }
    return lookup;
  }, [productsQuery.data]);

  const stepperData: StepperData[] = React.useMemo(() => {
    const isLoanValid =
      order.appraisalLoan.length === 1 &&
      order.appraisalLoan[0].clientLoanId !== '' &&
      order.appraisalLoan[0].documents.length === 1;

    const isIndexingLoadValid =
      !!order.indexingLoan.metaData &&
      !!order.indexingLoan.metaData.id &&
      !!order.indexingLoan.columnName;

    const isBPOLoanValid =
      !!order.bpoLoan.metaData && !!order.bpoLoan.metaData.id;

    const nonEmptyRows = order.bpoLoan.manualLoans.filter(
      loan =>
        loan.clientLoanId !== '' ||
        loan.street !== '' ||
        loan.city !== '' ||
        loan.state !== '' ||
        loan.zip !== '' ||
        loan.bpoProduct.id !== null,
    );

    const hasNonEmptyRows = nonEmptyRows.every(
      loan =>
        loan.clientLoanId !== '' &&
        loan.street !== '' &&
        loan.bpoProduct.id !== null &&
        loan.city !== '' &&
        loan.state !== '' &&
        loan.zip !== '',
    );

    const interiorLoans = nonEmptyRows.filter(
      loan =>
        loan.bpoProduct.name !== null &&
        productLookup[order.product.productId] &&
        productLookup[order.product.productId].subProductLookup[
          loan.bpoProduct.name
        ] &&
        productLookup[order.product.productId].subProductLookup[
          loan.bpoProduct.name
        ].subCategory === 3,
    );

    const interiorBpoHasAccessDetails =
      interiorLoans.length > 0
        ? interiorLoans.every(loan => loan.accessDetails !== '')
        : true;

    const isManualBPOValid =
      nonEmptyRows.length > 0 && hasNonEmptyRows && interiorBpoHasAccessDetails;

    const selectProductStep = {
      title: 'Select Product',
      content: <SelectProduct />,
      isValid:
        !!order.product &&
        !!order.product.productId &&
        ((divisionsQuery.data && divisionsQuery.data.length === 0) ||
          !!order.division),
      onCancel: order.orderId ? onDeleteConfirm : undefined,
    };

    const productsStepperData: ProductsStepperData = {
      'Appraisal Review': [
        selectProductStep,
        {
          title: 'Loan Details',
          content: <AppraisalLoanDetails />,
          isValid: isLoanValid,
          onSubmit: onSetAppraisalOrder,
          loadingMessage: 'Getting Everything Ready...',
          onCancel: order.orderId ? onDeleteConfirm : undefined,
        },
        {
          title: 'Review & Submit',
          contentTitle: 'Your order has been submitted',
          content: <AppraisalReviewSubmit />,
          isValid: true,
          onCancel: order.orderId ? onDeleteConfirm : undefined,
          onSubmit: onSubmitAppraisalOrder,
          nextButtonLabel: 'Submit',
        },
      ],
      'Acuity Indexing': [
        selectProductStep,
        {
          title: 'Loan Details',
          content: <IndexingLoanDetails />,
          isValid: isIndexingLoadValid,
          onSubmit: onSubmitIndexingLoan,
        },
        {
          title: 'Upload Files',
          contentTitle:
            'Please upload documents to support the loans added in step 2.',
          content: <h1>Upload Files</h1>,
          isValid: true,
        },
        {
          title: 'Match Results',
          contentTitle: 'Review your order and upload supporting documents',
          content: <h1>Match Results</h1>,
          isValid: true,
        },
        {
          title: 'Review & Submit',
          content: <h2>Review</h2>,
          isValid: true,
          onCancel: onDeleteConfirm,
        },
      ],
      'Broker Price Opinion': [
        selectProductStep,
        {
          title: 'Loan Details',
          content: <BPOLoanDetails />,
          isValid:
            order.bpoLoan.bpoLoanMode === 'upload'
              ? isBPOLoanValid
              : isManualBPOValid,
          onSubmit: onSetBPOLoans,
          onCancel: order.orderId ? onDeleteConfirm : undefined,
          loadingMessage: 'Checking for Errors...',
        },
        {
          title: 'Errors',
          content: <BPOError error={error} />,
          isValid:
            (!order.bpoLoanValidation.hasError &&
              order.bpoLoanValidation.data.length > 0) ||
            order.bpoLoanValidation.data.some(loan => loan.hasChange),
          onSubmit: onValidateBPOErrors,
          onCancel: onDeleteConfirm,
          loadingMessage: 'Checking for Errors...',
          isVisible:
            order.bpoLoanValidation.errorLoans !== undefined &&
            order.bpoLoanValidation.errorLoans.length > 0,
          nextButtonLabel: order.bpoLoanValidation.data.some(
            loan => loan.hasChange,
          )
            ? 'Save'
            : 'Next',
        },
        {
          title: 'Address Corrections',
          content: <BPOAddressSuggestion />,
          isValid:
            (!order.bpoLoanValidation.hasUnselectedNonSingleSuggestion &&
              order.bpoLoanValidation.data.length > 0) ||
            order.bpoLoanValidation.data.some(loan => loan.hasChange),
          onCancel: onDeleteConfirm,
          onSubmit: onValidateBPOCorrections,
          loadingMessage: 'Checking for Errors...',
          refreshData: true,
          isVisible:
            order.bpoLoanValidation.correctionLoans !== undefined &&
            order.bpoLoanValidation.correctionLoans.length > 0,
          nextButtonLabel: order.bpoLoanValidation.data.some(
            loan => loan.hasChange,
          )
            ? 'Save'
            : 'Next',
        },
        {
          title: 'Review & Submit',
          content: <BPOReviewSubmit />,
          isValid: true,
          onCancel: onDeleteConfirm,
          onSubmit: onSubmitBPOLoans,
          nextButtonLabel: submitBPOLoansLabel,
          refreshData:
            order.bpoLoanValidation.hasError ||
            order.bpoLoanValidation.hasUnacknowledgedWarning ||
            order.bpoLoanValidation.hasUnselected,
          // || order.bpoLoanValidation.hasUnvalidated,
        },
      ],
      Securent: [
        selectProductStep,
        {
          title: 'Upload Loan Files',
          content: <SecurentUploadLoan />,
          isValid: order.securentFiles.length > 0,
          onCancel: order.orderId ? onDeleteConfirm : undefined,
        },
        {
          title: 'Review & Submit',
          content: <p>Review & Submit</p>,
          onCancel: onDeleteConfirm,
          isValid: true,
          nextButtonLabel: 'Submit',
        },
      ],
      AUS: [
        selectProductStep,
        {
          title: 'Upload Loan File',
          content: <AUSUploadLoanFile />,
          isValid: !!order.ausLoan && !!order.ausLoan.loanStagingId,
          onCancel: order.orderId ? onDeleteConfirm : undefined,
        },
        {
          title: 'Upload Credit Files',
          content: <AUSUploadCreditFiles />,
          isValid:
            !!order.ausLoan &&
            !!order.ausLoan.creditInfo &&
            !!order.ausLoan.creditInfo &&
            !order.ausLoan.creditInfo.some(
              creditInfo => !creditInfo.hasMatch,
            ) &&
            !!order.ausLoan.borrowerInfos &&
            !order.ausLoan.borrowerInfos.some(
              borrowerInfo => !borrowerInfo.hasMatch,
            ),
          onCancel: order.orderId ? onDeleteConfirm : undefined,
        },
        {
          title: 'Review & Submit',
          content: <AUSReviewSubmit />,
          isValid: true,
          onCancel: order.orderId ? onDeleteConfirm : undefined,
          nextButtonLabel: 'Submit',
        },
      ],
    };

    return (
      (order.product && productsStepperData[order.product.productName]) ||
      productsStepperData['Broker Price Opinion']
    );
  }, [
    order.appraisalLoan,
    order.indexingLoan.metaData,
    order.indexingLoan.columnName,
    order.bpoLoan.metaData,
    order.bpoLoan.manualLoans,
    order.bpoLoan.bpoLoanMode,
    order.product,
    order.division,
    order.orderId,
    order.bpoLoanValidation.hasError,
    order.bpoLoanValidation.data,
    order.bpoLoanValidation.errorLoans,
    order.bpoLoanValidation.hasUnselectedNonSingleSuggestion,
    order.bpoLoanValidation.correctionLoans,
    order.bpoLoanValidation.hasUnacknowledgedWarning,
    order.bpoLoanValidation.hasUnselected,
    order.securentFiles.length,
    order.ausLoan,
    divisionsQuery.data,
    onDeleteConfirm,
    onSetAppraisalOrder,
    onSubmitAppraisalOrder,
    onSubmitIndexingLoan,
    onSetBPOLoans,
    error,
    onValidateBPOErrors,
    onValidateBPOCorrections,
    onSubmitBPOLoans,
    submitBPOLoansLabel,
    productLookup,
  ]);

  const loadOrder = React.useCallback(async () => {
    if (appraisalOrder.data) {
      const {
        orderId,
        orderNumber,
        orderStatus,
        stagingId,
        identifier: orderIdentifier,
        companyDivision: division,
        productInfo: { productId, productName, vendorId, price },
        loans,
      } = appraisalOrder.data as GetAppraisalOrderResponse;

      const product: IProduct = {
        productId,
        productName,
        vendorId,
        vendorName: 'Situs AMC',
        available: true,
        cvpPrice: price,
        price,
        description: '',
        clarityCompanyId: 0,
        clarityProductId: '',
        clarityVendorId: 0,
        companyId: '',
        companyName: '',
        pricePer: '',
        vpPrice: price,
        subProducts: [],
      };

      const resumedOrder: IUnsubmittedOrder = {
        stagingId,
        orderId,
        orderNumber,
        orderStatus,
        orderIdentifier,
        loans,
        product,
        division,
      };

      onLoadOrder(resumedOrder);
    }
  }, [appraisalOrder.data, onLoadOrder]);

  const loadBPOOrder = React.useCallback(() => {
    if (!bpoOrder.isLoading) {
      if (bpoOrder.data) {
        onLoadBPOOrder(bpoOrder.data);
      }
    }
  }, [bpoOrder.data, bpoOrder.isLoading, onLoadBPOOrder]);

  React.useEffect(() => {
    if (id && id !== order.orderId) {
      if (productOrder === 'Appraisal Review') {
        loadOrder();
      } else {
        loadBPOOrder();
      }
    }
  }, [id, productOrder, loadOrder, loadBPOOrder, order.orderId]);

  const initialStep = React.useMemo(() => {
    if (productOrder === 'Appraisal Review') {
      if (appraisalOrder.data) {
        return 2;
      }
    }

    if (productOrder === 'Broker Price Opinion') {
      if (!bpoOrder.isLoading && bpoOrder.data) {
        const hasErrors =
          bpoOrder.data.errorLoans && bpoOrder.data.errorLoans.length > 0;
        const hasCorrections =
          bpoOrder.data.correctionLoans &&
          bpoOrder.data.correctionLoans.length > 0;

        if (bpoOrder.data.data.length === 0) {
          return 'Loan Details';
        }

        if (
          hasErrors &&
          (bpoOrder.data.hasError || bpoOrder.data.hasUnacknowledgedWarning)
        ) {
          return 'Errors';
        }

        if (hasCorrections) {
          if (bpoOrder.data.hasUnselected) {
            return 'Address Corrections';
          }
        }

        return 'Review & Submit';
      }
      return 'Loan Details';
    }
    return 0;
  }, [appraisalOrder.data, bpoOrder.data, bpoOrder.isLoading, productOrder]);

  React.useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
    }

    return () => {
      isMounted.current = false;
    };
  }, []);

  return {
    data: { initialStep, stepperData },
    onValidateBPOOrder,
    isLoading,
    isSaving,
    savingMessage,
  };
}

export default useStepperData;
