import React from 'react';
import { TextField } from 'office-ui-fabric-react';
import { FaCheckCircle } from 'react-icons/fa';
import { StepContainer, useStepper } from '../../Common';
import {
  Content,
  Title,
  Row,
  Overview,
  Description,
  Total,
  OrderIdentifier,
  OrderSubmitted,
  Info,
  XmlContainerLink,
} from './styles';
import { formatCurrency } from '../../../utils/helpers';
import { useOrder } from '../../../context/order';
import OrderCardList from '../OrderCardList';
import Box from '../../AccountSettings/Box';

function AUSReviewSubmit() {
  const {
    order,
    orderActions: { onUpdateOrderIdentifier },
  } = useOrder();
  const { onPreviousStep, onNavigateToStep, state } = useStepper();

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const loanAmount =
    order.ausLoan.loanAmount !== undefined
      ? formatter.format(order.ausLoan.loanAmount)
      : '';

  return (
    <StepContainer align="center">
      <Content>
        {!state.isSubmitted && (
          <Title>
            Please <strong>review your order.</strong>
          </Title>
        )}
        <Box>
          <Row>
            <strong>ULAD (MISMO 3.4)</strong>
            <span>{order.ausLoan.documentName}</span>
          </Row>
          <Row>
            <strong>Borrower Name</strong>
            <span>
              {order.ausLoan.borrowerInfos &&
                order.ausLoan.borrowerInfos.map(borrower => {
                  return borrower.borrowerName;
                })}
            </span>
          </Row>
          {order.ausLoan.subjectPropertyInfo && (
            <Row>
              <strong>Address</strong>
              <span>
                <div>{order.ausLoan.subjectPropertyInfo.street}</div>
                <div>
                  {order.ausLoan.subjectPropertyInfo.city},{' '}
                  {order.ausLoan.subjectPropertyInfo.state}{' '}
                  {order.ausLoan.subjectPropertyInfo.zip}
                </div>
              </span>
            </Row>
          )}
          <Row>
            <strong>Loan Amount</strong>
            <span>{loanAmount}</span>
          </Row>
          {order.ausLoan.creditInfo?.map((creditInfo, index) => (
            <Row key={creditInfo.creditStagingId}>
              <strong>Credit File {index + 1}</strong>
              <span>
                <div>{creditInfo.documentName}</div>
                <div>{creditInfo.borrowerName}</div>
              </span>
            </Row>
          ))}
        </Box>
        {!state.isSubmitted && (
          <XmlContainerLink>
            If the above information is not correct, please go back and
            <button
              type="button"
              onClick={() => {
                onNavigateToStep(1);
              }}
            >
              &nbsp;update the ULAD
            </button>
            &nbsp;or
            <button
              type="button"
              onClick={() => {
                onPreviousStep();
              }}
            >
              &nbsp;update the Credit Files.
            </button>
          </XmlContainerLink>
        )}
        <Overview>
          <Description>
            <strong>BRES Credit (AUS)</strong>
            <p>{formatCurrency(order.product.cvpPrice)} per loan</p>
          </Description>
          <Total>
            <strong>Order Total</strong>
            <span>{formatCurrency(order.product.cvpPrice)}</span>
          </Total>
        </Overview>
        {state.isSubmitted ? (
          <>
            <OrderSubmitted>
              <FaCheckCircle size={40} />
              <div>
                <h2>Order #{order.orderNumber} has been submitted.</h2>
                {order.orderIdentifier && (
                  <p>Order Identifier: {order.orderIdentifier}</p>
                )}
              </div>
            </OrderSubmitted>
            <Info>
              <strong>Your order is in progress.</strong> We&apos;ll notify you
              when updates are available.
            </Info>
            <OrderCardList orderId={order.orderId} />
          </>
        ) : (
          <OrderIdentifier>
            <TextField
              id="Order"
              label="Order Identifier (Optional)"
              value={order.orderIdentifier}
              onChange={(_e, val) => {
                onUpdateOrderIdentifier(val || '');
              }}
              placeholder="Enter a code or phrase to help identify this order"
            />
          </OrderIdentifier>
        )}
      </Content>
    </StepContainer>
  );
}

export default AUSReviewSubmit;
