/* eslint-disable react/function-component-definition */
import React, { CSSProperties } from 'react';
import {
  ColDef,
  ColGroupDef,
  FirstDataRenderedEvent,
  MenuItemDef,
} from 'ag-grid-community';
import { DefaultPublisher } from '@samc/common';
import Grid from '../components/grid/Grid';
import User from '../models/User';
import Patience from '../components/Patience';
import { useUserArray } from '../contexts/UsersContext';
import { dateFilterParams } from '../helpers/columnDef';
import { Renderers } from '../components/grid/GridRenderers';
import { EditUserEvent } from '../events/EditUserEvent';
import { useClient } from '../contexts';
import { AdditionalUserSettings } from '../models/AdditionalUserSettings';

const minWidth = 177;

const columnDefs: (ColDef | ColGroupDef)[] = [
  {
    headerName: 'Disabled',
    field: '',
    cellRenderer: Renderers.UserStatusRenderer,
    type: 'rightAligned',
    sortable: false,
    resizable: false,
    width: 200,
  },
  {
    headerName: 'Username',
    field: 'username',
    cellRenderer: Renderers.EditorActionRenderer,
    cellClass: 'cursor-pointer',
    floatingFilter: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Email',
    field: 'email',
    floatingFilter: true,
    filter: 'agTextColumnFilter',
    filterParams: { applyMiniFilterWhileTyping: true },
  },
  {
    headerName: 'Last Name',
    field: 'lastName',
    floatingFilter: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'First Name',
    field: 'firstName',
    floatingFilter: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Company',
    field: 'company.name',
    floatingFilter: true,
    filter: 'agTextColumnFilter',
    floatingFilterComponentParams: { suppressFloatingFilterButton: false },
  },
  {
    headerName: 'Created',
    minWidth,
    field: 'created',
    cellRenderer: Renderers.DateRenderer,
    filter: 'agDateColumnFilter',
    floatingFilter: true,
    type: 'leftAligned',
    filterParams: dateFilterParams,
    floatingFilterComponentParams: { suppressFloatingFilterButton: false },
  },
  {
    headerName: 'Last Login',
    minWidth,
    field: 'lastLogin',
    cellRenderer: Renderers.DateRenderer,
    filter: 'agDateColumnFilter',
    floatingFilter: true,
    type: 'leftAligned',
    filterParams: dateFilterParams,
    floatingFilterComponentParams: { suppressFloatingFilterButton: false },
  },
  {
    headerName: 'Updated',
    minWidth,
    field: 'updated',
    cellRenderer: Renderers.DateRenderer,
    filter: 'agDateColumnFilter',
    floatingFilter: true,
    type: 'leftAligned',
    filterParams: dateFilterParams,
    floatingFilterComponentParams: { suppressFloatingFilterButton: false },
  },
  {
    headerName: 'Updated By',
    field: 'updatedByUser',
    cellRenderer: Renderers.UserRenderer,
    floatingFilter: true,
    filter: 'agTextColumnFilter',
    floatingFilterComponentParams: { suppressFloatingFilterButton: false },
  },
  {
    headerName: 'System Account',
    field: 'isSystemAccount',
    cellRenderer: Renderers.CheckRenderer,
    floatingFilter: false,
    filter: 'agTextColumnFilter',
    filterParams: { applyMiniFilterWhileTyping: true },
  },
];

type UsersProps = {
  gridContainerStyling?: CSSProperties;
  userDataFieldSettings?: AdditionalUserSettings;
};

const Users: React.FC<UsersProps> = ({
  gridContainerStyling,
  userDataFieldSettings = new AdditionalUserSettings(),
}) => {
  userDataFieldSettings =
    userDataFieldSettings === undefined
      ? new AdditionalUserSettings()
      : userDataFieldSettings;

  columnDefs.forEach((x: ColDef) => {
    if (x.field === 'username' || x.field === 'updatedByUser') {
      x.cellRendererParams = { userDataFieldSettings };
    }
  });

  const client = useClient();

  const users = useUserArray();
  const filtered = users ? User.RemoveSystemAccount(users) : new Array<User>();

  const createUser = () => {
    DefaultPublisher.publish(
      new EditUserEvent(new User(), false, userDataFieldSettings),
    );
  };

  const onFirstDataRendered = (event: FirstDataRenderedEvent) => {
    event.api.autoSizeAllColumns();
  };

  const buttons = [];
  buttons.push({
    key: 'createUser',
    children: (
      <span className="font-proxima text-1">
        <i className="mdi mdi-plus-circle-outline text-4" /> USER
      </span>
    ),
    action: createUser,
    entitlement: 'Create User',
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const contextMenu = (data?: any) => {
    const result = new Array<MenuItemDef>();
    if (data instanceof User) {
      result.push({
        name: 'Clone as a new user',
        action: () => {
          DefaultPublisher.publish(
            new EditUserEvent(data, true, userDataFieldSettings),
          );
        },
      });
    }
    return result;
  };

  return (
    <Patience showPatience={!users}>
      <Grid
        columnDefs={columnDefs}
        rowData={filtered}
        buttonProps={buttons}
        onFirstDataRendered={onFirstDataRendered}
        paging
        excelExportAction={() => client.users.downloadUsersExport()}
        canUseFullScreen
        containerStyling={gridContainerStyling}
        getContextMenuItems={contextMenu}
      />
    </Patience>
  );
};

export default Users;
