import React from 'react';
import { TextField } from 'office-ui-fabric-react';
import { FaCheckCircle } from 'react-icons/fa';
import { Dialog, IButtonStyles, PrimaryButton } from '@fluentui/react';
import { StepContainer, useStepper } from '../../Common';
import {
  Content,
  Title,
  Row,
  Overview,
  Description,
  Total,
  OrderIdentifier,
  OrderSubmitted,
  Info,
  XmlContainerLink,
  DialogTitle,
  DialogMessage,
} from './styles';
import { formatCurrency } from '../../../utils/helpers';
import { useOrder } from '../../../context/order';
import OrderCardList from '../OrderCardList';
import Box from '../../AccountSettings/Box';
import { useProducts } from '../../../queries/useProducts';
import {
  useSetAppraisalOrder,
  SetAppraisalOrderData,
} from '../../../mutations/useSetAppraisalOrder';
import { IProduct } from '../../../context/order/types';

function BPOReviewSubmit() {
  const {
    order,
    orderActions: { onUpdateOrderIdentifier, onChangeOrderPrice },
  } = useOrder();
  const { onPreviousStep, state } = useStepper();

  const productsQuery = useProducts(order.product.vendorId);
  const { mutate: mutateSet } = useSetAppraisalOrder();

  let product: IProduct | undefined;
  if (productsQuery.data) {
    product = productsQuery.data.find(row => {
      return row.productId === order.product.productId;
    });
  }

  const priceChanged = React.useMemo(() => {
    return !!(product && product.cvpPrice !== order.product.cvpPrice);
  }, [product, order.product.cvpPrice]);

  const dialogContentProps = {
    title: <DialogTitle>Price Change</DialogTitle>,
  };

  const modalProps = {
    styles: {
      main: {
        selectors: {
          ' .ms-Dialog-title': {
            padding: '0px',
          },
          ' button': {
            width: '100%',
          },
        },
      },
    },
  };

  const buttonStyles: IButtonStyles = {
    root: {
      marginTop: '10px',
    },
  };

  return (
    <StepContainer align="center">
      <Content>
        {!state.isSubmitted && (
          <Title>
            Please <strong>verify the name and address </strong> extracted from
            your XML.
          </Title>
        )}
        {product && product.subProducts.length > 0 && (
          <Dialog
            dialogContentProps={dialogContentProps}
            modalProps={modalProps}
            isOpen={priceChanged}
          >
            <DialogMessage>
              The price of <strong>{product.productName}</strong> has changed
              from <strong>{formatCurrency(order.product.cvpPrice)}</strong> per
              loan to <strong>{formatCurrency(product.cvpPrice)}</strong> per
              loan.
            </DialogMessage>
            <PrimaryButton
              styles={buttonStyles}
              onClick={() => {
                if (product) {
                  const formData: SetAppraisalOrderData = {
                    orderId: order.orderId,
                    stagingId: order.stagingId,
                    vendorId: order.product.vendorId,
                    clientLoanId: order.appraisalLoan[0].clientLoanId,
                    productId: order.product.productId,
                    companyDivision: order.division,
                    productPrice: {
                      productId: product.productId,
                      productName: product.productName,
                      price: product.cvpPrice || 0,
                    },
                  };
                  mutateSet(formData, {
                    onSuccess: () => {
                      if (product) {
                        onChangeOrderPrice(product.cvpPrice);
                      }
                    },
                  });
                }
              }}
            >
              OK
            </PrimaryButton>
          </Dialog>
        )}
        <Box>
          <Row>
            <strong>Appraisal XML</strong>
            <span>{order.appraisalLoan[0].documents[0].documentName}</span>
          </Row>
          <Row>
            <strong>Loan ID</strong>
            <span>{order.appraisalLoan[0].clientLoanId}</span>
          </Row>
          <Row>
            <strong>Borrower Name</strong>
            <span>{order.appraisalLoan[0].borrowerLastName}</span>
          </Row>
          <Row>
            <strong>Street Address</strong>
            <span>{order.appraisalLoan[0].address}</span>
          </Row>
          <Row>
            <strong>Apt / Unit #</strong>
            <span>
              {order.appraisalLoan[0].unitNumber === ''
                ? '--'
                : order.appraisalLoan[0].unitNumber}
            </span>
          </Row>
          <Row>
            <strong>City</strong>
            <span>{order.appraisalLoan[0].city}</span>
          </Row>
          <Row>
            <strong>State</strong>
            <span>{order.appraisalLoan[0].state}</span>
          </Row>
          <Row>
            <strong>ZIP</strong>
            <span>{order.appraisalLoan[0].zip}</span>
          </Row>
        </Box>
        {!state.isSubmitted && (
          <XmlContainerLink>
            If the above information is not correct,
            <button
              type="button"
              onClick={() => {
                onPreviousStep();
              }}
            >
              &nbsp;verify you uploaded the correct XML
            </button>
          </XmlContainerLink>
        )}
        <Overview>
          <Description>
            <strong>Appraisal Review</strong>
            <p>{formatCurrency(order.product.cvpPrice)} per loan</p>
          </Description>
          <Total>
            <strong>Order Total</strong>
            <span>{formatCurrency(order.product.cvpPrice)}</span>
          </Total>
        </Overview>
        {state.isSubmitted ? (
          <>
            <OrderSubmitted>
              <FaCheckCircle size={40} />
              <div>
                <h2>
                  <strong>
                    Order #{order.orderNumber} has been submitted.
                  </strong>
                </h2>
                {order.orderIdentifier && (
                  <p>Order Identifier: {order.orderIdentifier}</p>
                )}
              </div>
            </OrderSubmitted>
            <Info>
              <strong>Your order is in progress.</strong> We&apos;ll notify you
              when updates are available.
            </Info>
            <OrderCardList orderId={order.orderId} />
          </>
        ) : (
          <OrderIdentifier>
            <TextField
              id="Order"
              label="Order Identifier (Optional)"
              value={order.orderIdentifier}
              onChange={(_e, val) => {
                onUpdateOrderIdentifier(val || '');
              }}
              placeholder="Enter a code or phrase to help identify this order"
            />
          </OrderIdentifier>
        )}
      </Content>
    </StepContainer>
  );
}

export default BPOReviewSubmit;
