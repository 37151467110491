/* eslint-disable react/function-component-definition */
import React, { useEffect } from 'react';
import Client from '../client/AuthorizationClient';
import { useAuthorizationConfig } from './AuthorizationConfigContext';
import { WithChildren } from '../types';

const ClientContext = React.createContext<Client | undefined>(undefined);

type ClientProviderProps = Partial<WithChildren> & {
  jwt?: string | null;
};

const ClientProvider: React.FC<ClientProviderProps> = ({ jwt, children }) => {
  const { apiUrl, defaultHeaders } = useAuthorizationConfig();
  const [client, setClient] = React.useState(
    new Client(apiUrl, undefined, defaultHeaders),
  );

  useEffect(() => {
    if (jwt) {
      const newClient = new Client(apiUrl, jwt, defaultHeaders);
      setClient(newClient);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwt]);

  return (
    <ClientContext.Provider value={client}>{children}</ClientContext.Provider>
  );
};

function useClient() {
  const context = React.useContext(ClientContext);
  if (context === undefined) {
    throw new Error('useClient must be used within a ClientProvider!');
  }
  return context;
}

export { ClientProvider, useClient };
