import React, { Suspense } from 'react';
import { IComboBox, IComboBoxOption } from 'office-ui-fabric-react/lib/index';
import Products from './Products';
import Divisions from './Divisions';
import { useOrder } from '../../../context/order';
import { Instructions, Separator, StepContainer } from '../../Common';
import Vendors from './Vendors';
import { useProducts } from '../../../queries/useProducts';
import { useDivisions } from '../../../queries/useDivisions';
import { useVendors } from '../../../queries/useVendors';

function SelectProduct() {
  const {
    order,
    orderActions: { onSelectDivision },
  } = useOrder();

  const [selectedVendorKey, setSelectedVendorKey] = React.useState<
    string | number | undefined
  >(0);

  const vendorsQuery = useVendors();
  const productsQuery = useProducts(selectedVendorKey);
  const divisionsQuery = useDivisions();

  React.useEffect(() => {
    if (
      divisionsQuery.data &&
      divisionsQuery.data.length > 0 &&
      !order.division
    ) {
      onSelectDivision(divisionsQuery.data[0].id);
    }
  }, [divisionsQuery.data, onSelectDivision, order.division]);

  const availableProducts = productsQuery.data
    ? productsQuery.data.filter(product => product.available)
    : [];

  const moreProducts = productsQuery.data
    ? productsQuery.data.filter(product => !product.available)
    : [];

  const divisions = divisionsQuery.data ? divisionsQuery.data : [];

  const vendors = vendorsQuery.data ? vendorsQuery.data : [];

  const onSelectVendor = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
  ) => {
    if (option) {
      setSelectedVendorKey(option.key);
    }
  };

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <StepContainer>
        <Instructions>
          <strong>Select a product</strong> below.
        </Instructions>

        <Vendors
          isLoading={vendorsQuery.isLoading}
          onSelectVendor={onSelectVendor}
          selectedVendorKey={selectedVendorKey}
          vendors={vendors}
        />

        <Products
          loading={productsQuery.isLoading}
          products={availableProducts}
        />

        {moreProducts.length > 0 && (
          <Products title="More products" products={moreProducts} />
        )}
        <Separator fullwidth />
        <Divisions
          isLoading={divisionsQuery.isLoading}
          divisions={divisions}
          onSelectDivision={onSelectDivision}
          selectedDivision={order.division}
        />
      </StepContainer>
    </Suspense>
  );
}

export default SelectProduct;
