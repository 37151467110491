import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import api from '../services/api';

export type AppraisalUploaAPIResponse = {
  stagingId: string;
  documentId: string;
  documentName: string;
  xmL_Borrower_Name: string;
  xmL_Property_Address: string;
  xmL_City: string;
  xmL_State: string;
  xmL_Apt: string;
  xmL_Zip: string;
};

export type AppraisalUploaResponse = {
  stagingId: string;
  borrowerLastName: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  unitNumber: string;
  documents: { documentId: string; documentName: string }[];
};

export type AppraisalUploadParams = {
  files: File[];
  stagingId: string;
};

async function setAppraisalUpload({
  files,
  ...params
}: AppraisalUploadParams): Promise<AppraisalUploaResponse> {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  const formData = new FormData();
  formData.append('Document', files[0]);

  let response;

  if (!params.stagingId) {
    response = await api.post<AppraisalUploaAPIResponse>(
      '/AppraisalOrder/upload',
      formData,
      config,
    );
  } else {
    formData.append('StagingId', params.stagingId);
    response = await api.patch<AppraisalUploaAPIResponse>(
      '/AppraisalOrder/upload',
      formData,
      config,
    );
  }

  const {
    stagingId,
    documentId,
    documentName,
    xmL_Borrower_Name,
    xmL_Property_Address,
    xmL_City,
    xmL_State,
    xmL_Zip,
    xmL_Apt,
  } = response.data;

  const loan: AppraisalUploaResponse = {
    stagingId,
    borrowerLastName: xmL_Borrower_Name,
    address: xmL_Property_Address,
    city: xmL_City,
    state: xmL_State,
    zip: xmL_Zip,
    unitNumber: xmL_Apt,
    documents: [{ documentId, documentName }],
  };

  return loan;
}

export { setAppraisalUpload };

export function useSetAppraisalUpload() {
  return useMutation<
    AppraisalUploaResponse,
    AxiosError<ErrorData>,
    AppraisalUploadParams
  >(params => setAppraisalUpload(params));
}
