import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { LoanDispute } from '../components/OrderTracking/BPOPanel';
import api from '../services/api';

interface CreateRequestUpdateParams {
  orderId: string;
  loansDispute: LoanDispute[];
}

async function createRequestUpdate(params: CreateRequestUpdateParams) {
  const response = await api.post('BPOOrder/SetLoansDispute', params);
  return response.data;
}

export function useCreateRequestUpdate() {
  return useMutation<void, AxiosError<ErrorData>, CreateRequestUpdateParams>(
    params => createRequestUpdate(params),
  );
}
