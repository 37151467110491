import styled, { css } from 'styled-components';

export const PanelTitle = styled.header`
  font-size: 18px;
  width: 100%;
  padding: 10;
  display: flex;
  align-items: center;

  span {
    margin-left: 5px;
  }
`;

export const ButtonText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;

  p {
    margin-top: -4px;
    font-size: ${props => props.theme.fontSize.sm};
    font-weight: 400;
  }
`;

export const GridWrapper = styled.div`
  flex: 1;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: calc(100vh - 200px);
`;

export const ActionsWrapper = styled.div`
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  display: flex;

  p {
    margin-right: 10px;
    font-weight: 600;
  }

  button + button {
    margin-left: 10px;
  }
  div + div {
    margin-left: 10px;
  }
`;
export const ActionsWrapperContainer = styled.div`
  align-items: center;
  justify-content: flex-end;
  display: flex;

  p {
    margin-right: 10px;
    font-weight: 600;
  }

  button + button {
    margin-left: 10px;
  }
`;
export const LoanActionButton = styled.span`
  & + span:before {
    content: ' • ';
  }
`;

interface LinkButtonProps {
  color?: string;
}

export const LinkButton = styled.span<LinkButtonProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
  font-weight: 600;
  cursor: pointer;

  ${props =>
    props.color &&
    css`
      color: ${props.color};
    `}

  &:hover {
    text-decoration: underline;
  }
  span {
    margin-right: 3px;
  }
`;

export const ContainerIcons = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Editable = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 105%;

  input {
    font-size: 14px;
    background: transparent;
    width: 100%;
  }

  svg {
    color: ${props => props.theme.colors.primary};
    display: none;
    margin-bottom: 4px;
  }

  &:hover {
    svg {
      display: unset;
    }
  }
`;
