import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import api from '../services/api';

export async function removeSecurentUpload({
  fileStagingId,
}: {
  fileStagingId: string;
}) {
  await api.delete('RWOrder/fileStagingId', {
    params: { fileStagingId },
  });
}

export function useRemoveSecurentUpload() {
  return useMutation<void, AxiosError<ErrorData>, { fileStagingId: string }>(
    ({ fileStagingId }) => removeSecurentUpload({ fileStagingId }),
  );
}
