import { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import api from '../services/authApi';

interface IUserInfo {
  company: {
    name: string;
  };
  emulatedCompany?: {
    id: string;
    name: string;
  };
}

export const getUserInfo = async (): Promise<IUserInfo> => {
  const response = await api.get<IUserInfo>('/api/v2/jwt/userInfo');
  return response.data;
};

export const useUserInfo = (): UseQueryResult<
  IUserInfo,
  AxiosError<ErrorData>
> => useQuery('userInfo', getUserInfo);
