/* eslint-disable react/function-component-definition */
import React from 'react';
import { useCurrentUser } from '../contexts';

type CheckboxProps = {
  label?: string;
  classNames?: string;
  value: boolean;
  setValue: (val: boolean) => void;
  entitlement?: string;
  readonly?: boolean;
};

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  classNames,
  value,
  setValue,
  entitlement,
  readonly = false,
}) => {
  const currentUser = useCurrentUser();

  let isReadOnly = readonly;
  if (entitlement && !isReadOnly) {
    isReadOnly = currentUser.hasEntitlement(entitlement);
  }

  let classes = isReadOnly
    ? 'text-mono-8 p-1 text-2'
    : 'cursor-pointer text-blue-7 p-1 text-2';
  if (classNames) {
    classes = `${classes} ${classNames}`;
  }
  const click = isReadOnly
    ? undefined
    : () => {
        setValue(!value);
      };
  if (label) {
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <span className={classes} onClick={click}>
        <span
          className={
            value ? 'mdi mdi-checkbox-marked' : 'mdi mdi-checkbox-blank-outline'
          }
        />
        <span className="ml-xs select-none">{label}</span>
      </span>
    );
  }
  classes = value
    ? `${classes} mdi mdi-checkbox-marked`
    : `${classes} mdi mdi-checkbox-blank-outline`;
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
  return <span className={classes} onClick={click} />;
};

export default Checkbox;
