import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import api from '../services/api';

interface UpdateOrderIdentifierParams {
  orderId: string;
  orderIdentifier?: string;
}

async function updateOrderIdentifier({
  orderId,
  orderIdentifier,
}: UpdateOrderIdentifierParams) {
  const encodedId = encodeURIComponent(orderId);
  const encodedIdentifier = encodeURIComponent(orderIdentifier || '');
  const response = await api.patch(
    `/Order/${encodedId}/identifier?Identifier=${encodedIdentifier}`,
  );

  return response.data;
}

export function useUpdateOrderIdentifier() {
  return useMutation<void, AxiosError<ErrorData>, UpdateOrderIdentifierParams>(
    params => updateOrderIdentifier(params),
  );
}
