import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import api from '../services/api';

export type AUSUploadCreditResponse = {
  loanStagingId: string;
  creditsInfo: [
    {
      creditStagingId: string;
      documentId: string;
      documentName: string;
      borrowerName: string;
      ssn: string;
    },
  ];
};

export type AUSUploadCreditParams = {
  files: File[];
  loanStagingId: string;
  creditStagingId?: string;
};

async function setAUSUploadCredit({
  files,
  ...params
}: AUSUploadCreditParams): Promise<AUSUploadCreditResponse> {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  const formData = new FormData();

  formData.append('LoanStagingId', params.loanStagingId);

  files.forEach(file => {
    formData.append('Document', file);
  });

  let response;

  if (!params.creditStagingId) {
    response = await api.post<AUSUploadCreditResponse>(
      '/AUSOrder/uploadCredit',
      formData,
      config,
    );
  } else {
    formData.append('CreditStagingId', params.loanStagingId);
    response = await api.patch<AUSUploadCreditResponse>(
      '/AUSOrder/uploadCredit',
      formData,
      config,
    );
  }

  const { loanStagingId, creditsInfo } = response.data;

  const loan: AUSUploadCreditResponse = {
    loanStagingId,
    creditsInfo,
  };

  return loan;
}

export { setAUSUploadCredit };

export function useSetAUSUploadCredit() {
  return useMutation<
    AUSUploadCreditResponse,
    AxiosError<ErrorData>,
    AUSUploadCreditParams
  >(params => setAUSUploadCredit(params));
}
