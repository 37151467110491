import React, { useState, ReactNode, useEffect } from 'react';
import { useDrop } from 'react-dnd';
import { DefaultPublisher } from '@samc/common';
import Base from '../../models/Base';
import groupBy from '../../helpers/groupBy';
import { Draggable, DraggableCollection, DragData } from './Draggable';
import './Droppable.css';
import FillRemainingContainer from '../FillRemainingContainer';
import { getSelectedModels } from '../../helpers/selection';
import {
  DragStartedEvent,
  GroupSelectionEvent,
  SelectionEvent,
} from '../../events';

type DroppableProps<T extends Base<T>> = {
  title: string;
  models: T[];
  typeName: string;
  canFilter: boolean;
  groupProperty?: string;
  display?: (model: T) => string;
  containerClasses?: string;
  /** Passed onto a FillRemainingContainer to point to the parent that defines the available height for the form. */
  querySelectorForParent?: string;
  dropped: (model: T[]) => void;
  selectedModels: T[];
  setSelectedModels: (selected: T[]) => void;
};

function ItemsRender<T extends Base<T>>(
  models: T[],
  typeName: string,
  display: (model: T) => string,
  selectedModels: T[],
  lastFocusIndex: number,
  groupProperty?: string,
) {
  // eslint-disable-next-line react/destructuring-assignment
  if (!models.length || lastFocusIndex >= models.length) return null; // No items, or the render ran before lastFocusIndex has updated, so this is a 'throwaway' render.

  // eslint-disable-next-line react/destructuring-assignment
  const lastFocusId = models[lastFocusIndex].id;
  const draggableRender = (items: T[]) => {
    return items.map(e => (
      <Draggable
        typeName={typeName}
        key={e.id.toString()}
        model={e}
        display={display}
        isSelected={selectedModels.indexOf(e) !== -1}
        isFocus={e.id.equals(lastFocusId)}
      />
    ));
  };
  if (groupProperty) {
    const renders = new Array<ReactNode>();
    const groupedModels = groupBy<T, string>(models, m =>
      String(m[groupProperty as keyof Base<T>]),
    );
    groupedModels.forEach((items, name) => {
      renders.push(
        <DraggableCollection key={name} models={items} title={name}>
          {draggableRender(items)}
        </DraggableCollection>,
      );
    });
    return renders;
  }

  return draggableRender(models);
}

function Droppable<T extends Base<T>>(props: DroppableProps<T>) {
  const {
    title,
    models,
    typeName,
    canFilter,
    groupProperty,
    display = m => m.name,
    containerClasses = 'bg-mono-1',
    dropped,
    querySelectorForParent,
    selectedModels,
    setSelectedModels,
  } = props;
  const [lastSelectionIndex, setLastSelectionIndex] = useState(-1);
  const [lastFocusIndex, setLastFocusIndex] = useState(0);
  const [filterText, setFilterText] = useState('');
  const [{ classes }, dropRef] = useDrop({
    accept: typeName,
    canDrop: item => {
      const data = item as DragData<T>;
      // only allow dropping if at least one model in the drag isn't in the list being dropped on.
      const canDrop = !models.some(m => m.id.equals(data.model.id));
      return canDrop;
    },
    drop: item => {
      const data = item as DragData<T>;
      if (data.type === typeName) {
        dropped(
          selectedModels.indexOf(data.model) === -1
            ? // item is not in selection, therefore we only add it
              [data.model]
            : // item is in selection, so we 'drop' all the selected models
              selectedModels,
        );
      }
    },
    collect: monitor => {
      if (!monitor.isOver()) {
        return { classes: '' };
      }
      return {
        classes: monitor.canDrop()
          ? 'cursor-pointer draggedOn'
          : 'badDraggedOn',
      };
    },
  });

  const filterModels = () => {
    return filterText
      ? models.filter(m =>
          display(m).toLowerCase().includes(filterText.toLowerCase()),
        )
      : models;
  };

  useEffect(() => {
    setSelectedModels(new Array<T>());
    setLastSelectionIndex(-1);
    setLastFocusIndex(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [models]);

  useEffect(() => {
    const subId = DefaultPublisher.subscribe<SelectionEvent>(
      SelectionEvent.eventName,
      e => {
        const [newSelections, selectionIndex, focusIndex] = getSelectedModels(
          models,
          filterModels(),
          selectedModels,
          e,
          lastSelectionIndex,
        );
        if (newSelections !== null) {
          setSelectedModels(newSelections);
        }
        setLastSelectionIndex(selectionIndex);
        setLastFocusIndex(focusIndex);
      },
    );
    const groupSubId = DefaultPublisher.subscribe<GroupSelectionEvent>(
      GroupSelectionEvent.eventName,
      e => {
        if (e.ids.some(id => models.some(m => m.id.equals(id)))) {
          const selectionIndexes = e.ids.map(id =>
            models.findIndex(m => m.id.equals(id)),
          );
          const highIndex = Math.max(...selectionIndexes);
          setLastSelectionIndex(highIndex);
          setLastFocusIndex(highIndex);
          setSelectedModels(selectionIndexes.map(i => models[i]));
        }
      },
    );
    const dragSubId = DefaultPublisher.subscribe<DragStartedEvent>(
      DragStartedEvent.eventName,
      e => {
        if (
          selectedModels.findIndex(m => m.id.equals(e.id)) === -1 &&
          selectedModels.length
        ) {
          setSelectedModels(new Array<T>());
          setLastSelectionIndex(-1);
          setLastFocusIndex(0);
        }
      },
    );
    return () => {
      DefaultPublisher.unsubscribe(SelectionEvent.eventName, subId);
      DefaultPublisher.unsubscribe(GroupSelectionEvent.eventName, groupSubId);
      DefaultPublisher.unsubscribe(DragStartedEvent.eventName, dragSubId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText, selectedModels, lastSelectionIndex]);

  const items = filterModels();

  const keyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'ArrowDown') {
      if (lastSelectionIndex + 1 >= models.length) return;
      const newSelections = e.shiftKey ? [...selectedModels] : [];
      newSelections.push(models[lastSelectionIndex + 1]);
      setSelectedModels(newSelections);
      setLastSelectionIndex(lastSelectionIndex + 1);
      setLastFocusIndex(lastSelectionIndex + 1);
    } else if (e.key === 'ArrowUp') {
      if (lastSelectionIndex === 0) return;
      const newSelections = e.shiftKey ? [...selectedModels] : [];
      newSelections.push(models[lastSelectionIndex - 1]);
      setSelectedModels(newSelections);
      setLastSelectionIndex(lastSelectionIndex - 1);
      setLastFocusIndex(lastSelectionIndex - 1);
    }
  };

  const setFilter = (text: string) => {
    setFilterText(text);
    setSelectedModels(new Array<T>());
    setLastSelectionIndex(-1);
  };

  const className = `${classes} min-h-full droppable border border-mono-5`;
  const children = ItemsRender(
    items,
    typeName,
    display,
    selectedModels,
    lastFocusIndex,
    groupProperty,
  );

  return (
    <div className={containerClasses}>
      <h5 className="text-1 title w-full border-t border-l border-r border-mono-5 text-blue-7 p-1 font-bold">
        {title}
      </h5>
      {canFilter && (
        <div className="border-t border-l border-r border-mono-5 p-1 text-1 w-full">
          <input
            type="text"
            className="filter w-full border border-mono-5 block pl-1 focus:border-mono-14"
            value={filterText}
            onChange={e => setFilter(e.target.value)}
            placeholder="Filter"
          />
        </div>
      )}
      <FillRemainingContainer
        querySelectorForParent={querySelectorForParent}
        className="overflow-y-auto"
        leaveSpace={20}
      >
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div
          ref={dropRef}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0}
          className={className}
          onKeyDown={keyPress}
        >
          {children}
          <div className="overlay" />
        </div>
      </FillRemainingContainer>
    </div>
  );
}

export default Droppable;
