import * as React from 'react';
import { useToken } from '@samc/auth-authentication-okta';
import '@samc/auth-authentication-okta/index.css';
import '@samc/icons/css/materialdesignicons.css';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AgGridLicenseProvider } from '@samc/react-ui-grid';
import { Authorization } from './features/authorization';
import './features/authorization/index.css';
import api from './services/api';
import authApi from './services/authApi';
import { authConfig } from './utils/authConfig';
import Main from './Main';
import { EventProvider } from './context/event';
import { queryClient } from './services/queryClient';

function App() {
  const accessToken = useToken();

  if (accessToken) {
    api.defaults.headers.authorization = `Bearer ${accessToken}`;
    authApi.defaults.headers.authorization = `Bearer ${accessToken}`;
  }

  return (
    <AgGridLicenseProvider license={authConfig.agGridLicense}>
      <Authorization config={authConfig} jwt={accessToken}>
        <EventProvider token={accessToken}>
          <QueryClientProvider client={queryClient}>
            <Main />
            <ReactQueryDevtools />
          </QueryClientProvider>
        </EventProvider>
      </Authorization>
    </AgGridLicenseProvider>
  );
}

export default App;
