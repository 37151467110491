import AnimatedLoader from '@samc/react-ui-core/lib/atoms/AnimatedLoader/AnimatedLoader';
import * as React from 'react';
import { Modal, Button } from '..';

interface DownloadingProps {
  ref?: React.LegacyRef<HTMLDivElement>;
  isOpen: boolean;
  onDismiss?: () => void;
  width?: number | string;
  height?: number | string;
}

function Downloading({
  ref,
  isOpen,
  onDismiss,
  width,
  height,
  ...props
}: DownloadingProps) {
  return (
    <Modal
      onDismiss={onDismiss}
      width={width}
      height={height}
      isOpen={isOpen}
      suppressCloseButton
      {...props}
    >
      <div ref={ref} />
      <AnimatedLoader size={100} dark />
      <h3>Download Processing</h3>
      <p>Please wait...</p>
      <Button variant="tertiary" alternate width="100%" onClick={onDismiss}>
        Cancel
      </Button>
    </Modal>
  );
}

export default Downloading;
