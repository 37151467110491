import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { SecurentFile } from '../context/order/securent/types';
import api from '../services/api';
import { formatCurrency } from '../utils/helpers';

type SecurentUploadParams = {
  uploadedFiles: File[];
  stagingId?: string;
};

type SecurentUploadResponse = {
  stagingId: string;
  files: SecurentFile[];
};

async function setSecurentUpload({
  uploadedFiles,
  ...params
}: SecurentUploadParams): Promise<SecurentUploadResponse> {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  const formData = new FormData();

  uploadedFiles.forEach(file => {
    formData.append('Documents', file);
  });

  let response;

  if (!params.stagingId) {
    response = await api.post<SecurentUploadResponse>(
      '/RWOrder/upload',
      formData,
      config,
    );
  } else {
    formData.append('StagingId', params.stagingId);
    response = await api.patch<SecurentUploadResponse>(
      '/RWOrder/upload',
      formData,
      config,
    );
  }

  const { stagingId, files: rawFiles } = response.data;

  const files = rawFiles.map(file => {
    return {
      ...file,
      loanAmountFormatted: formatCurrency(file.loanAmount),
    };
  });

  const loan: SecurentUploadResponse = {
    stagingId,
    files,
  };

  return loan;
}

export { setSecurentUpload };

export function useSetSecurentUpload() {
  return useMutation<
    SecurentUploadResponse,
    AxiosError<ErrorData>,
    SecurentUploadParams
  >(params => setSecurentUpload(params));
}
