import * as React from 'react';
import { useOktaAuth } from '@okta/okta-react';

interface UseMonitorProps {
  // amount of seconds before logout to show the logout warning
  warningTimeout: number;
  handleLogout?: () => void;
  handleContinue?: () => void;
  disabled?: boolean;
}

interface UseMonitorState {
  isIdle: boolean;
  isIdleError: boolean;
  timerMinutes: string;
  timerSecconds: string;
  onContinue: () => void;
  onLogOut: () => void;
}

function useMonitorOkta({
  warningTimeout,
  handleLogout,
  handleContinue,
  disabled = false,
}: UseMonitorProps): UseMonitorState {
  const { oktaAuth } = useOktaAuth();
  const [showWarnDialog, setShowWarnDialog] = React.useState(false);
  const [showWarnDialogError, setShowWarnDialogError] = React.useState(false);

  const [logoutCountdown, setLogoutCountdown] = React.useState(warningTimeout);
  const [lastInteractionTime, setLastInteractionTime] = React.useState(
    new Date().getTime() / 1000,
  );
  const [lastRenewTime, setLastRenewTime] = React.useState(
    new Date().getTime() / 1000,
  );
  const [lastRenewAttemptTime, setLastRenewAttemptTime] = React.useState(
    new Date().getTime() / 1000,
  );
  const [attemptRenew, setAttemptRenew] = React.useState(false);

  const { timerMinutes, timerSecconds } = React.useMemo(() => {
    const minute = 60;
    const hour = minute * 60;
    const minutes = `${Math.floor((logoutCountdown % hour) / minute)}`;
    const seconds = `${Math.floor(logoutCountdown % minute)}`;
    const formatTime = (time: string): string => {
      if (time.length > 1) {
        return time;
      }
      return `0${time}`;
    };
    return {
      timerMinutes: formatTime(minutes),
      timerSecconds: formatTime(seconds),
    };
  }, [logoutCountdown]);

  const warnTimeout = React.useRef<NodeJS.Timeout | null>();
  const logoutInterval = React.useRef<NodeJS.Timeout | null>();

  const getOktaTimes = React.useCallback(() => {
    const currentTime = new Date().getTime() / 1000;
    const authState = oktaAuth.authStateManager.getAuthState();
    if (
      authState == null ||
      !authState.isAuthenticated ||
      !authState.accessToken
    ) {
      return {
        currentTime,
        expireTime: currentTime,

        maxTimeBeforeWarn: 0,
        timeTilWarn: 0,

        maxTimeBeforeLogout: 0,
        timeTilLogout: 0,

        logOutUser: true,
        warnUser: false,
      };
    }
    // ALL TIMES ARE IN SECONDS
    const issueAtTime = authState.accessToken?.claims?.iat ?? currentTime;

    // okta expires token 30 secs before JWT expires
    // if it's already expired it's redirecting to LoggedOut instead of logout, so take another 30 seconds
    const expireTime = (authState.accessToken?.claims?.exp ?? 0) - 60;

    // const timeSinceLastInteraction = currentTime - lastInteractionTime; // 16

    const maxTimeBeforeLogout = expireTime - issueAtTime;
    const timeTilLogout = expireTime - currentTime;

    const maxTimeBeforeWarn = maxTimeBeforeLogout - warningTimeout;
    const timeTilWarn = expireTime - warningTimeout - currentTime;

    let logOutUser = false;
    let warnUser = false;

    if (timeTilLogout <= 0) {
      logOutUser = true;
    } else if (timeTilWarn <= 0) {
      warnUser = true;
    }

    return {
      currentTime,
      expireTime,

      maxTimeBeforeWarn,
      timeTilWarn,

      maxTimeBeforeLogout,
      timeTilLogout,

      logOutUser,
      warnUser,
    };
  }, [oktaAuth, warningTimeout]);

  const clearWarnTimeout = React.useCallback(() => {
    if (warnTimeout.current) {
      clearTimeout(warnTimeout.current);
      warnTimeout.current = null;
    }
  }, [warnTimeout]);

  const clearLogoutInterval = React.useCallback(() => {
    if (logoutInterval.current) {
      clearInterval(logoutInterval.current);
      logoutInterval.current = null;
    }
  }, [logoutInterval]);

  const resetLogoutCountdown = React.useCallback(() => {
    setLogoutCountdown(warningTimeout);
  }, [warningTimeout]);

  const onLogOut = React.useCallback(() => {
    setShowWarnDialog(false);
    clearLogoutInterval();
    if (handleLogout) handleLogout();
  }, [clearLogoutInterval, handleLogout]);

  const RefreshToken = React.useCallback(async () => {
    await oktaAuth.session.refresh();
    await oktaAuth.tokenManager.renew('idToken');
    await oktaAuth.tokenManager.renew('accessToken');
  }, [oktaAuth]);

  const finishCheck = React.useCallback(() => {
    const times = getOktaTimes();
    if (times.logOutUser) {
      onLogOut();
    } else if (times.warnUser && attemptRenew === false) {
      setShowWarnDialog(true);
      clearWarnTimeout();
    } else {
      setLogoutCountdown(times.timeTilLogout);
      setShowWarnDialog(false);
      setShowWarnDialogError(false);
      clearLogoutInterval();
      resetLogoutCountdown();
    }
  }, [
    getOktaTimes,
    onLogOut,
    clearWarnTimeout,
    clearLogoutInterval,
    resetLogoutCountdown,
    attemptRenew,
  ]);

  const CheckTimes = React.useCallback(async () => {
    let times = getOktaTimes();
    const timeSinceRenew = times.currentTime - lastRenewTime;
    const timeSinceRenewAttempt = times.currentTime - lastRenewAttemptTime;
    const renewEverySeconds = 120;

    // we don't want this firing constantly so only fire it's been more than renewEverySeconds since last time
    if (
      timeSinceRenew > renewEverySeconds &&
      timeSinceRenewAttempt > 10 &&
      attemptRenew === false
    ) {
      setLastRenewAttemptTime(times.currentTime);
      setAttemptRenew(true);

      RefreshToken()
        .then(() => {
          times = getOktaTimes();
          setLastRenewTime(times.currentTime);
          setAttemptRenew(false);
          finishCheck();
        })
        .catch(e => {
          // eslint-disable-next-line
          console.error(e);
          setAttemptRenew(false);
          finishCheck();
        });
    } else {
      finishCheck();
    }
  }, [
    finishCheck,
    RefreshToken,
    getOktaTimes,
    lastRenewTime,
    lastRenewAttemptTime,
    attemptRenew,
  ]);

  const onContinue = React.useCallback(() => {
    let times = getOktaTimes();
    setShowWarnDialog(false);
    setShowWarnDialogError(false);

    if (attemptRenew === false) {
      setLastRenewAttemptTime(times.currentTime);
      setAttemptRenew(true);

      RefreshToken()
        .then(() => {
          times = getOktaTimes();
          setLastRenewTime(times.currentTime);
          finishCheck();
          if (handleContinue) handleContinue();
          setAttemptRenew(false);
        })
        .catch(e => {
          // eslint-disable-next-line
          console.error(e);
          setShowWarnDialogError(true);
          setAttemptRenew(false);
          finishCheck();
        });
    } else {
      finishCheck();
      if (handleContinue) handleContinue();
    }
  }, [finishCheck, RefreshToken, getOktaTimes, attemptRenew, handleContinue]);

  const resetWarnTimeout = React.useCallback(() => {
    const interactionTime = new Date().getTime() / 1000;
    const currentTime = new Date().getTime() / 1000;

    if (currentTime - lastInteractionTime >= 5) {
      // only update this every 5 seconds
      setLastInteractionTime(interactionTime);
      CheckTimes();
    }
  }, [CheckTimes, lastInteractionTime]);

  React.useEffect(() => {
    const events = [
      'load',
      'mousemove',
      'mousedown',
      'click',
      'scroll',
      'keypress',
    ];

    if (!showWarnDialog) {
      events.forEach(event => window.addEventListener(event, resetWarnTimeout));
    }
    return () => {
      events.forEach(event => {
        window.removeEventListener(event, resetWarnTimeout);
      });
    };
  }, [showWarnDialog, CheckTimes, resetWarnTimeout]);

  React.useEffect(() => {
    if (!disabled && !showWarnDialog) {
      warnTimeout.current = setInterval(() => {
        const times = getOktaTimes();

        // eslint-disable-next-line
        // console.log({ warn: times.timeTilWarn });
        // eslint-disable-next-line
        // console.log(times);

        if (times.warnUser) {
          setShowWarnDialog(true);
          clearWarnTimeout();
        }
      }, 10 * 1000);
    }
    return () => {
      clearWarnTimeout();
    };
  }, [
    clearWarnTimeout,
    setShowWarnDialog,
    disabled,
    showWarnDialog,
    getOktaTimes,
  ]);

  React.useEffect(() => {
    if (!disabled && showWarnDialog) {
      logoutInterval.current = setInterval(() => {
        const times = getOktaTimes();

        // eslint-disable-next-line
        // console.log({ logout: times.timeTilLogout });
        // eslint-disable-next-line
        // console.log(times);

        setLogoutCountdown(times.timeTilLogout);

        if (times.logOutUser) {
          onLogOut();
        }
      }, 1000);
    }
    return () => {
      clearLogoutInterval();
    };
  }, [
    clearLogoutInterval,
    logoutCountdown,
    onLogOut,
    disabled,
    showWarnDialog,
    getOktaTimes,
  ]);

  return {
    timerMinutes,
    timerSecconds,
    isIdle: showWarnDialog,
    isIdleError: showWarnDialogError,
    onLogOut,
    onContinue,
  };
}

export default useMonitorOkta;
