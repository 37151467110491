import {
  IFileSource,
  IRawMetaData,
} from '../../../components/Common/ImportComponents/interfaces';
import { ActionTypes } from '../types';

import {
  BPOLoanMode,
  IManualLoan,
  BPOLoanValidationData,
  UseBPOActions,
  BPOLoanData,
  Address,
} from './types';

interface Props {
  dispatch: (action: ActionTypes) => void;
}

export default function useBPOActions({ dispatch }: Props): UseBPOActions {
  const onChangeBPOLoanMode = (bpoLoanMode: BPOLoanMode) => {
    dispatch({
      type: 'SET_BPO_LOAN_MODE',
      payload: { bpoLoanMode },
    });
  };

  const onChangeBPODocument = () => {
    dispatch({
      type: 'CLEAR_BPO_DOCUMENT',
    });
  };

  const onChangeBPOPreviewData = () => {
    dispatch({
      type: 'CLEAR_BPO_DOC_PREVIEW_DATA',
    });
  };

  const onChangeBPOData = (
    fileSource: IFileSource | null,
    metaData?: IRawMetaData,
  ) => {
    dispatch({
      type: 'SET_BPO_DOC_DATA',
      payload: { fileSource, metaData },
    });
  };

  const onChangeBPOLoanError = (isCompletedSuccessfully: boolean) => {
    dispatch({
      type: 'SET_BPO_LOAN_ERROR',
      payload: { isCompletedSuccessfully },
    });
  };

  const onAddManualBPOLoanRow = () => {
    dispatch({
      type: 'ADD_MANUAL_BPO_LOAN_ROW',
    });
  };

  const onRemoveManualBPOLoanRow = (index: number) => {
    dispatch({
      type: 'REMOVE_MANUAL_BPO_LOAN_ROW',
      payload: { index },
    });
  };

  const onChangeManualBPO = (manualLoans: IManualLoan[]) => {
    dispatch({
      type: 'SET_MANUAL_BPO_LOAN_DATA',
      payload: { manualLoans },
    });
  };

  const onChangeBPOValidationData = (
    bpoLoanValidationData: BPOLoanValidationData,
    resetErrorsCorrections?: boolean,
  ) => {
    dispatch({
      type: 'SET_BPO_LOAN_VALIDATION_DATA',
      payload: { bpoLoanValidationData, resetErrorsCorrections },
    });
  };

  const onChangeSingleBPOValidationData = (loan: BPOLoanData) => {
    dispatch({
      type: 'SET_SINGLE_BPO_LOAN_VALIDATION_DATA',
      payload: { loan },
    });
  };

  const onEditBPOLoanAddress = (row: number, editedAddress: Address) => {
    dispatch({
      type: 'EDIT_BPO_LOAN_ADDRESS',
      payload: { row, editedAddress },
    });
  };

  const onUpdateBPOValidationData = (record: BPOLoanData) => {
    dispatch({
      type: 'PATCH_BPO_LOAN_VALIDATION_DATA',
      payload: { record },
    });
  };

  const onRemoveRowBPOValidationData = (rowIndex: number) => {
    dispatch({
      type: 'REMOVE_ROW_LOAN_VALIDATION_DATA',
      payload: { rowIndex },
    });
  };

  const onRemoveDuplicatesBPOValidationData = () => {
    dispatch({
      type: 'REMOVE_DUPLICATES_LOAN_VALIDATION_DATA',
    });
  };

  const onRemoveSelectedBPOValidationData = (rows: number[]) => {
    dispatch({
      type: 'REMOVE_SELECTED_LOAN_VALIDATION_DATA',
      payload: { rows },
    });
  };

  const onSelectBPOLoanAddress = (
    row: number,
    bpoOrderStagingAddressId: string,
  ) => {
    dispatch({
      type: 'SET_SELECTED_ADDRESS',
      payload: { row, bpoOrderStagingAddressId },
    });
  };

  const onUpdateBPOLoanData = (updatedLoan: BPOLoanData) => {
    dispatch({
      type: 'UPDATE_BPO_LOAN_DATA',
      payload: { updatedLoan },
    });
  };

  const onSetShowSavePrompt = (value: boolean) => {
    dispatch({
      type: 'SET_SHOW_SAVE_PROMPT',
      payload: { value },
    });
  };

  return {
    onChangeBPOLoanMode,
    onChangeBPOData,
    onChangeBPODocument,
    onChangeBPOPreviewData,
    onChangeBPOLoanError,
    onAddManualBPOLoanRow,
    onRemoveManualBPOLoanRow,
    onChangeManualBPO,
    onChangeBPOValidationData,
    onChangeSingleBPOValidationData,
    onEditBPOLoanAddress,
    onUpdateBPOValidationData,
    onRemoveRowBPOValidationData,
    onRemoveDuplicatesBPOValidationData,
    onRemoveSelectedBPOValidationData,
    onSelectBPOLoanAddress,
    onUpdateBPOLoanData,
    onSetShowSavePrompt,
  };
}
