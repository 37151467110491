import styled from 'styled-components';

export const Container = styled.label`
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;

  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    display: none;
  }

  /* Create a custom radio button */
  span {
    /* position: absolute; */
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08),
      inset 0px 0px 0px 1px rgba(130, 135, 145, 1);
  }

  p {
    margin: 0;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  /* When the radio button is checked, add a blue background */
  input:checked ~ span {
    background-color: #fff;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  span:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  input:checked ~ span:after {
    display: block;
  }

  input:checked ~ p {
    font-weight: 700;
    opacity: 1;
    color: #000;
  }

  /* Style the indicator (dot/circle) */
  span:after {
    top: 10px;
    left: 4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #2196f3;
  }

  /* On mouse-over, add a grey background color */
  &:hover input:not(:checked) ~ span:after {
    display: block;
    top: 10px;
    left: 4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #ccc;
  }
`;
