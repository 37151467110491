import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import api from '../services/api';

export async function removeAUSCreditFile({
  stagingId,
}: {
  stagingId: string;
}) {
  await api.delete('AUSOrder/creditFileStaging', {
    params: { creditStagingId: stagingId },
  });
}

export function useRemoveAUSCreditFile() {
  return useMutation<void, AxiosError<ErrorData>, { stagingId: string }>(
    ({ stagingId }) => removeAUSCreditFile({ stagingId }),
  );
}
