import { BaseGrid, useAgGridApi } from '@samc/react-ui-grid';
import * as React from 'react';
import { ITooltipParams, ValueGetterParams } from 'ag-grid-community';
import { confirm, ConfirmationDialogOptions } from '@samc/react-ui-core';
import { IComboBoxOption } from 'office-ui-fabric-react';
import { useOrder } from '../../../context/order';
import { ControlBar, Loading, Separator, useStepper } from '../../Common';
import { useStates } from '../../../queries/useStates';
import {
  BPOErrorHeader,
  BPOEValidationFooter,
  Container,
  Content,
  GridWrapper,
  LinkButton,
} from './styles';
import {
  FrameworkComp,
  errorType,
  cellStyle,
  ErrorRenderer,
  DeleteBtCellRenderer,
  AddressPopUpRenderer,
  PopUpRenderer,
} from './config';
import ExtendedGridFieldConfiguration from '../../../types/ExtendedGridFieldConfiguration';
import { BPOLoanAddress, BPOLoanData } from '../../../context/order/bpo/types';
import BPONoLoanData from '../BPONoLoanData';
import { BPOLoanError } from '../../../context/order/types';
import { extractAddresses } from '../../../context/order/bpo/util';
import { useSetBPOLoan } from '../../../mutations/useSetBPOLoan';

type BPOErrorProps = {
  error?: string;
};

function BPOError({ error }: BPOErrorProps) {
  const isMounted = React.useRef(false);
  const {
    order,
    bpoActions: { onChangeSingleBPOValidationData },
  } = useOrder();

  const { onPreviousStep, onRefreshData } = useStepper();

  const [gridApi, handleGridReady] = useAgGridApi();
  const [frameworkComponents, setFrameworkComponents] = React.useState<
    FrameworkComp | undefined
  >();

  const states = useStates();

  const useSetBPOLoanMutation = useSetBPOLoan();

  const statesComboBoxData: IComboBoxOption[] = React.useMemo(() => {
    return states.data
      ? states.data.map(({ code, name }) => ({
          key: code,
          text: `${code} - ${name}`,
        }))
      : [];
  }, [states.data]);

  const mergedData: BPOLoanData[] = React.useMemo(() => {
    return order.bpoLoanValidation.data.filter(loan => {
      return (
        loan.isDeleted !== true &&
        order.bpoLoanValidation.errorLoans &&
        order.bpoLoanValidation.errorLoans.includes(loan.row)
      );
    });
  }, [order.bpoLoanValidation.data, order.bpoLoanValidation.errorLoans]);

  React.useEffect(() => {
    isMounted.current = true;

    if (statesComboBoxData.length > 1) {
      setFrameworkComponents({
        AddressPopUpRenderer,
        PopUpRenderer,
        ErrorRenderer,
        DeleteBtCellRenderer,
      });
    }
    return () => {
      if (isMounted.current) {
        isMounted.current = false;
        setFrameworkComponents(undefined);
      }
    };
  }, [statesComboBoxData]);

  const {
    duplicateLoanIds,
    warnings,
    duplicated,
    totalCount: totalErrorCount,
  } = React.useMemo(() => {
    const results = {
      duplicateLoanIds: [] as BPOLoanError[],
      warnings: [] as BPOLoanError[],
      duplicated: [] as BPOLoanError[],
      totalCount: 0,
    };
    order.bpoLoanValidation.data.forEach(loan => {
      if (loan.errors) {
        loan.errors.forEach(loanError => {
          if (loanError.type === 'Warning') {
            if (
              /^There are multiple rows with the Loan ID/.test(
                loanError.message,
              )
            ) {
              results.duplicateLoanIds.push({ ...loanError, row: loan.row });
            }
            results.warnings.push({ ...loanError, row: loan.row });
            results.totalCount++;
          } else if (loanError.type === 'Duplicated') {
            results.duplicated.push({ ...loanError, row: loan.row });
            results.totalCount++;
          } else if (loanError.type !== 'Address') {
            results.totalCount++;
          }
        });
      }
    });
    return results;
  }, [order.bpoLoanValidation.data]);

  const onGridExport = () => {
    if (gridApi) {
      gridApi.exportDataAsExcel({
        processCellCallback: params => {
          const colDef = params.column.getColDef();
          switch (colDef.field) {
            case 'addresses': {
              if (params.node) {
                const addresses = extractAddresses(params.node.data);
                const address =
                  addresses.selected || addresses.edited || addresses.original;
                return address ? formatAddress(address) : '';
              }
              return '';
            }
            case 'errors': {
              if (params.value) {
                return formatErrors(params.value);
              }
              return '';
            }
            default: {
              return params.value;
            }
          }
        },
      });
    }
  };

  const onDeleteConfirm = React.useCallback(
    async (selectedRows: number[]) => {
      if (selectedRows.length === 0) {
        return;
      }

      const detail = `Are you sure you want to delete ${
        selectedRows.length === 1
          ? `row ${selectedRows[0]}`
          : `these ${selectedRows.length} rows`
      }? This cannot be undone`;

      const dialogOptions: ConfirmationDialogOptions = {
        title:
          selectedRows.length === 1
            ? `Delete
           row ${selectedRows[0]}?`
            : `Delete ${selectedRows.length} rows?`,
        detail,
        confirmText: 'Delete',
        cancelText: 'Cancel',
      };

      const response = await confirm(dialogOptions);

      const rowLookup: { [key: number]: BPOLoanData } = {};
      order.bpoLoanValidation.data.forEach(loan => {
        rowLookup[loan.row] = loan;
      });

      if (response) {
        order.bpoLoanValidation.data.forEach(loan => {
          if (selectedRows.includes(loan.row)) {
            onChangeSingleBPOValidationData({
              ...loan,
              isDeleted: true,
              hasChange: true,
            });
          }
        });

        onRefreshData();

        /*
        deleteBPOStagingLoansMutation.mutate(
          {
            orderId: order.orderId,
            loans: order.bpoLoanValidation.data.reduce((ret, loan) => {
              if (selectedRows.includes(loan.row)) {
                ret.push({ ...loan, isDeleted: true });
              }
              return ret;
            }, [] as BPOLoanData[]),
          },
          {
            onSuccess: deleteResponse => {
              const lookup: { [index: number]: BPOLoanData } = {};
              order.bpoLoanValidation.data.forEach(loan => {
                lookup[loan.row] = loan;
              });

              deleteResponse.data.forEach((loan, index) => {
                if (lookup[loan.row]) {
                  deleteResponse.data[index] = lookup[loan.row];
                }
              });

              onChangeBPOValidationData(deleteResponse);
              onRefreshData();
            },
          },
        );
        */
      }
    },
    [
      onChangeSingleBPOValidationData,
      onRefreshData,
      order.bpoLoanValidation.data,
    ],
  );

  const isLoading = React.useMemo(() => {
    return useSetBPOLoanMutation.isLoading;
  }, [useSetBPOLoanMutation.isLoading]);

  const errorMessage = React.useMemo<string | undefined>(() => {
    return (
      error ||
      (order.bpoLoanValidation.data.length === 0
        ? 'There are no loans in this order. Please return to step 2 and re-enter your loan details.'
        : undefined)
    );
  }, [error, order.bpoLoanValidation.data.length]);

  // const productsQuery = useProducts();
  // const subProductLookup = React.useMemo(() => {
  //   const lookup: { [key: string]: ISubProduct } = {};
  //   if (productsQuery.data) {
  //     productsQuery.data.some(product => {
  //       if (product.productId === order.product.productId) {
  //         product.subProducts.forEach(subProduct => {
  //           lookup[subProduct.productId] = subProduct;
  //         });
  //         return true;
  //       }
  //       return false;
  //     });
  //   }
  //   return lookup;
  // }, [order.product.productId, productsQuery.data]);

  if (!frameworkComponents) {
    return null;
  }

  const defaultColDefs: ExtendedGridFieldConfiguration = {
    field: '',
    suppressMenu: true,
    type: 'string',
    cellStyle,
    width: 110,
  };

  const formatAddress = (address: BPOLoanAddress): string => {
    const parts = [
      address?.street,
      address?.unitNumber,
      address?.city,
      address?.state,
      address?.zip,
    ].filter(part => part !== undefined && part !== '');

    return parts.join(', ');
  };

  const formatErrors = (errors: BPOLoanError[]): string => {
    return errors
      .filter(v => v.type !== 'Address')
      .map(v => v.message)
      .join('\n');
  };

  const fields: ExtendedGridFieldConfiguration[] = [
    {
      ...defaultColDefs,
      suppressMenu: true,
      cellStyle: undefined,
      field: 'row',
      headerName: 'Excel Row',
      width: 90,
    },
    {
      ...defaultColDefs,
      field: 'clientLoanId',
      headerName: 'Loan ID',
      cellRenderer: 'PopUpRenderer',
    },
    {
      ...defaultColDefs,
      field: 'addresses',
      headerName: 'Address',
      cellRenderer: 'AddressPopUpRenderer',
      width: 350,
      tooltipValueGetter: (params: ITooltipParams): string => {
        const addresses = extractAddresses(params.data);
        const address =
          addresses.selected || addresses.edited || addresses.original;
        return address ? formatAddress(address) : '';
      },
    },
    {
      ...defaultColDefs,
      field: 'bpoProduct',
      headerName: 'BPO Type',
      cellRenderer: 'PopUpRenderer',
      width: 200,
      valueGetter: (params: ValueGetterParams): string => {
        return params.data.bpoProduct.name;
      },
    },
    {
      ...defaultColDefs,
      field: 'accessDetails',
      headerName: 'Interior Access Details',
      cellRenderer: 'PopUpRenderer',
      width: 160,
    },
    {
      ...defaultColDefs,
      field: 'customerReferenceId1',
      headerName: 'Customer Reference ID1',
      cellRenderer: 'PopUpRenderer',
      width: 180,
    },
    {
      ...defaultColDefs,
      field: 'customerReferenceId2',
      headerName: 'Customer Reference ID2',
      cellRenderer: 'PopUpRenderer',
      width: 180,
    },
    {
      ...defaultColDefs,
      field: 'customerReferenceId3',
      headerName: 'Customer Reference ID3',
      cellRenderer: 'PopUpRenderer',
      width: 180,
    },
    {
      ...defaultColDefs,
      field: 'errors',
      headerName: 'Errors',
      tooltipValueGetter: (params: ITooltipParams): string => {
        const value = params.value as BPOLoanError[];
        const errors = (value || ([] as BPOLoanError[]))
          .filter(v => v.type !== 'Address')
          .map(v => v.message);
        return errors.join('\n');
      },
      cellRenderer: 'ErrorRenderer',
      minWidth: 400,
      flex: 1,
      autoHeight: true,
      wrapText: true,
    },
    {
      ...defaultColDefs,
      field: 'row',
      cellStyle,
      cellRenderer: 'DeleteBtCellRenderer',
      cellRendererParams: {
        onClick(value: number) {
          onDeleteConfirm([value]);
        },
      },
      valueFormatter: () => 'Delete',
      tooltipValueGetter: (params: ITooltipParams) =>
        params.valueFormatted || '',
      width: 120,
      filter: false,
      isPinned: 'right',
    },
  ];

  if (errorMessage) {
    return <BPONoLoanData message={errorMessage} />;
  }

  return (
    <Container>
      {isLoading && <Loading message="Saving loan" />}
      <BPOErrorHeader>
        <p>
          We found
          <strong style={{ color: '#BD0E08' }}>
            {` ${totalErrorCount} issue${totalErrorCount === 1 ? ' ' : 's '}`}
          </strong>
          with your loan information.
          {totalErrorCount > 0 &&
            ` Please correct
          ${totalErrorCount === 1 ? ' it' : ' them'} below.`}
        </p>
        <p>
          <strong>Click on a highlighted cell to edit it.</strong> You may also
          <LinkButton fontSize={16} onClick={onPreviousStep}>
            {' '}
            go back to step 2
          </LinkButton>{' '}
          and re-upload a corrected Excel file.
        </p>
      </BPOErrorHeader>
      <Content>
        <ControlBar onExport={onGridExport} suppressDeleteButton />
        <GridWrapper>
          <BaseGrid
            onBodyGridReady={handleGridReady}
            data={mergedData}
            fields={fields}
            frameworkComponents={frameworkComponents}
            gridOptions={{ suppressScrollOnNewData: true }}
            suppressPaginationPanel
            suppressColumnFilters
            suppressRowSelector
            rowSelection="none"
          />
        </GridWrapper>
      </Content>
      {totalErrorCount > 0 && <Separator fullwidth />}
      <BPOEValidationFooter>
        {duplicateLoanIds.length > 0 && (
          <p>
            You have
            <strong style={{ color: errorType.Warning.color }}>
              {` ${duplicateLoanIds.length} matching loan ID${
                duplicateLoanIds.length === 1 ? '' : 's'
              }`}
            </strong>
            . Please verify these are correct before proceeding.
            <LinkButton
              onClick={() => {
                const warningRows = duplicateLoanIds.map(item => item.row);
                onDeleteConfirm(warningRows);
              }}
            >
              {warnings.length === 1 ? 'Delete Loan' : 'Delete All'}
            </LinkButton>
          </p>
        )}
        {duplicated.length > 0 && (
          <p>
            You have
            <strong style={{ color: errorType.Duplicated.color }}>
              {` ${duplicated.length} loan${
                duplicated.length === 1 ? ' that is' : 's that are'
              } in an existing order.`}
            </strong>
            <LinkButton
              onClick={() => {
                const duplicatedRows = duplicated.map(item => item.row);
                onDeleteConfirm(duplicatedRows);
              }}
            >
              {duplicated.length === 1 ? 'Delete Loan' : 'Delete All'}
            </LinkButton>
          </p>
        )}
      </BPOEValidationFooter>
    </Container>
  );
}

export default BPOError;
