/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from 'react';
import { MenuItemDef, FirstDataRenderedEvent } from 'ag-grid-community';
import { DefaultPublisher } from '@samc/common';
import { useClient, useCurrentUser, useAuthConfig } from '../../../contexts';
import { AdditionalUserSettings, Company, Role, User } from '../../../models';
import Grid from '../../grid/Grid';
import Title from '../Title';
import Entitlements from '../../../Entitlements';
import Patience from '../../Patience';
import { columnDef } from '../../../helpers/columnDef';
import { Renderers } from '../../grid/GridRenderers';
import useComplexState from '../../../hooks/useComplexState';
import UserFields from './UserFields';
import { ValidateUser, BuildErrorsForUser } from '../../../validators';
import FillRemainingContainer from '../../FillRemainingContainer';
import { UserSystemData } from '../../../models/UserSystemData';
import UserSystemDataRefresh from './UserSystemDataRefresh';
import Alert, { AlertType } from '../../Alert';
import { UserSavedEvent } from '../../../events/UserSavedEvent';
import { useServiceConfig } from '../../../contexts/ServiceConfigContext';
import { useCompanies } from '../../../contexts/CompaniesContext';
import Base from '../../../models/Base';
import { ISelectOption } from '../../../interfaces';

type UserFormProps = {
  user: User;
  isClone?: boolean;
  userDataFieldSettings: AdditionalUserSettings;
};

type SelectedRole = {
  selected: boolean;
  role: Role;
};

const roleColumnDefs = [
  columnDef(
    'Assigned',
    'selected',
    Renderers.CheckboxRenderer,
    {
      resizable: false,
    },
    {
      width: 100,
      autoHeight: true,
      cellClass: 'ag-cell-wrap-text',
    },
  ),
  columnDef(
    'Name',
    'role.displayName',
    undefined,
    { resizable: false },
    {
      width: 225,
      autoHeight: true,
      cellClass: 'ag-cell-wrap-text',
    },
  ),
  columnDef(
    'Description',
    'role.description',
    undefined,
    { resizable: false },
    {
      flex: 5,
      autoHeight: true,
      cellClass: 'ag-cell-wrap-text',
    },
  ),
];

const readOnlyColumnDefs = [
  columnDef('Name', 'role.displayName'),
  columnDef('Description', 'role.description'),
];

function getTitle(isNew: boolean, isClone: boolean) {
  if (!isNew) {
    return 'Edit User';
  }
  if (isClone) {
    return 'Clone User';
  }
  return 'Create User';
}

const UserForm: React.FC<UserFormProps> = ({
  user,
  isClone = false,
  userDataFieldSettings,
}) => {
  const config = useAuthConfig();
  const serviceConfig = useServiceConfig();
  const isNewUser = user.id.isEmpty();
  const [currentState, updateCurrentState, replaceState] = useComplexState(
    user.clone(),
  );
  const client = useClient();
  const currentUser = useCurrentUser();
  const relevantEntitlement =
    isNewUser || isClone
      ? Entitlements.Users.Create
      : Entitlements.Users.Update;
  const canEdit =
    currentUser.hasEntitlement(relevantEntitlement) &&
    !currentState.isSystemControlled;
  const canAssignRoles =
    currentUser.hasEntitlement(Entitlements.Users.AssignRoles) &&
    !currentState.isSystemControlled;
  const [isLoaded, setIsLoaded] = useState(false);
  const [roles, setRoles] = useState(new Array<SelectedRole>());
  const [systemData, setSystemData] = useState<UserSystemData | undefined>(
    undefined,
  );
  const [showSystemDataOption, setShowSystemDataOption] = useState(canEdit);
  const [wasSaveAttempted, setWasSaveAttempted] = useState(false);
  const [companyOptions, setCompanyOptions] = useState<
    Array<ISelectOption<Company>>
  >([]);
  const [originalSelectedCompanyOption, setOriginalSelectedCompanyOption] =
    useState<ISelectOption<Company> | null>(null);
  const [validationErrors, updateValidationErrors, setValidationErrors] =
    useComplexState(BuildErrorsForUser(userDataFieldSettings));
  const companiesList = useCompanies();
  const usesClarityCompanies = serviceConfig?.usesClarityCompanies ?? false;
  const [showResetPasswordMsg, setShowResetPasswordMsg] = useState<string>('');

  const update = (propertyNames: string[], doUpdate: (user: User) => void) => {
    updateCurrentState(doUpdate);
    updateValidationErrors(errors => {
      propertyNames.forEach(p => {
        errors.clearServerErrorForProperty(p);
      });
      ValidateUser(
        errors,
        usesClarityCompanies,
        userDataFieldSettings,
        currentState,
      );
    });
  };

  useEffect(() => {
    const clone = user.clone();
    replaceState(clone);
    const errors = BuildErrorsForUser(userDataFieldSettings);
    ValidateUser(errors, usesClarityCompanies, userDataFieldSettings, clone);
    setValidationErrors(errors);
    setIsLoaded(false);
    setShowSystemDataOption(canEdit);
    setWasSaveAttempted(false);
    client.roles.listRoles().then(newRoles => {
      setRoles(
        newRoles.map(r => {
          return {
            selected: user.roles.some(ur => ur.id.equals(r.id)),
            role: r,
          };
        }),
      );
      setIsLoaded(true);
    });
    if (
      !isNewUser &&
      user.systemId &&
      currentUser.hasEntitlement(relevantEntitlement)
    ) {
      // Go check Okta to see if the user's data has been updated there
      client.users
        .getSystemDataById(user.systemId)
        .then(sysData => setSystemData(sysData));
    }
    const companySelectOptions = Base.toSelectOptions(
      companiesList,
      user.company,
    );
    setCompanyOptions(companySelectOptions.all);
    setOriginalSelectedCompanyOption(companySelectOptions.selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const canSaveEdits = canEdit && currentState.isDirty(user);
  const canSaveRoles =
    canAssignRoles &&
    ((isNewUser && currentState.roles.length > 0) ||
      currentState.areRolesDirty(user));
  // Only highlight changed fields for an existing model (and where there are in fact changes).
  const dirtyFields =
    !isNewUser && canSaveEdits ? currentState.getDirtyFields(user) : [];
  const canSave =
    !validationErrors.hasAnyErrors &&
    (canSaveEdits ||
      canSaveRoles ||
      currentState.isDisabled !== user.isDisabled);
  const save = async () => {
    if (canSave) {
      let success = !canSaveEdits; // if we're not saving edits, we can skip the create/edit, so it 'succeeded'.
      if (canSaveEdits) {
        if (isNewUser || isClone) {
          currentState.authenticationScheme = config.authenticationScheme;
          const [result, id] = await client.users.createUser(
            currentState,
            updateValidationErrors,
          );
          success = result;
          if (success) {
            currentState.id = id;
          }
        } else {
          success = await client.users.editUser(
            currentState,
            updateValidationErrors,
          );
        }
      }
      if (success && canSaveRoles) {
        if (currentState.roles.length > 0 && isNewUser) {
          await client.users.addRoles(
            currentState.id,
            currentState.roles.map(r => r.id),
          );
        } else {
          const newRoleIds = isClone
            ? currentState.roles.map(r => r.id)
            : currentState.roles
                .filter(r => !user.roles.some(ur => ur.id.equals(r.id)))
                .map(r => r.id);
          const removedRoleIds = user.roles
            .filter(r => !currentState.roles.some(ur => ur.id.equals(r.id)))
            .map(r => r.id);
          await client.users.updateUserRoles(
            currentState.id,
            newRoleIds,
            removedRoleIds,
          );
        }
      }
      // check if user clicked the Disabled checkbox
      if (success && canEdit && currentState.isDisabled !== user.isDisabled) {
        await client.users.setDisabledUser(
          currentState.id,
          currentState.isDisabled,
        );
      }

      if (success) {
        DefaultPublisher.publish(new UserSavedEvent(currentState));
      } else {
        setWasSaveAttempted(true);
      }
    }
  };

  const entitlementUpdate = () => {
    const selectedRoles = roles.filter(e => e.selected);
    updateCurrentState(u => {
      u.roles = selectedRoles.map(e => e.role);
    });
  };

  const columnDefs = canAssignRoles ? roleColumnDefs : readOnlyColumnDefs;
  const addlButtons = [];
  if (!user.id.isEmpty()) {
    addlButtons.push({
      label: 'RESET PASSWORD',
      onClick: async () => {
        setShowResetPasswordMsg('');
        const success = await client.users.resetPassword(user.id);
        if (success) {
          showPasswordResetAlertMsg('Email sent for password reset.');
        } else {
          showPasswordResetAlertMsg(
            'Error while sending email for password reset.',
          );
        }
      },
      entitlement: Entitlements.Users.Update,
    });
  }

  const checkSystemData = () => {
    if (isNewUser && showSystemDataOption) {
      client.users
        .getSystemDataByEmail(currentState.email)
        .then(sysData => setSystemData(sysData));
    }
  };

  const showPasswordResetAlertMsg = (message: string) => {
    setShowResetPasswordMsg(message);
  };

  const contextMenuItems = (): MenuItemDef[] => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return ['copy', 'copyWithHeaders', 'paste'] as any;
  };

  const headerHeightSetter = (event: FirstDataRenderedEvent) => {
    event.api.resetRowHeights();
  };

  return (
    <Patience showPatience={!isLoaded}>
      <div className="form">
        <Title
          saveAction={save}
          canSave={canSave}
          additionalButtonProps={addlButtons}
        >
          {getTitle(isNewUser, isClone)}
        </Title>
        <div className="p-5 pt-0">
          {showResetPasswordMsg && (
            <Alert type={AlertType.Boring}>{showResetPasswordMsg}</Alert>
          )}
          {currentState.isSystemControlled && (
            <Alert type={AlertType.Problem}>
              This user is managed by the system and cannot be edited.
            </Alert>
          )}
          <UserSystemDataRefresh
            user={user}
            canBeShown={showSystemDataOption}
            systemData={systemData}
            update={update}
            currentState={currentState}
            keepShowing={setShowSystemDataOption}
          />
          <UserFields
            user={currentState}
            dirtyFields={dirtyFields}
            emailBlurred={checkSystemData}
            update={update}
            isReadOnly={!canEdit}
            validationErrors={validationErrors}
            wasSaveAttempted={wasSaveAttempted}
            companyOptions={companyOptions}
            originalSelectedCompanyOption={originalSelectedCompanyOption}
            userDataFieldSettings={userDataFieldSettings}
          />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className="text-3 text-mono-16 pt-4 block font-proxima p-2">
            Roles
          </label>
          <FillRemainingContainer
            querySelectorForParent="div.editor"
            leaveSpace={12}
          >
            <Grid
              columnDefs={columnDefs}
              rowData={roles}
              onCellValueChanged={entitlementUpdate}
              onFirstDataRendered={headerHeightSetter}
              getContextMenuItems={contextMenuItems}
            />
          </FillRemainingContainer>
        </div>
      </div>
    </Patience>
  );
};

export default UserForm;
