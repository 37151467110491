import { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import api from '../services/authApi';

interface ICompany {
  id: string;
  clarityId: number;
  name: string;
}

export const getProducts = async (): Promise<ICompany[]> => {
  const response = await api.get<ICompany[]>('/api/v2/company');
  return response.data;
};

export const useCompanies = (): UseQueryResult<
  ICompany[],
  AxiosError<ErrorData>
> =>
  useQuery('companies', getProducts, {
    refetchInterval: 1000 * 60,
  });
