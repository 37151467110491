import * as React from 'react';
import { Panel } from '@fluentui/react';
import { FiDownload } from 'react-icons/fi';
import { toastError, ToastMessage } from '@samc/react-ui-core';
import { MdRefresh } from 'react-icons/md';
import axios, { AxiosError, CancelTokenSource } from 'axios';
import { ChangeDataArgs, useAgGridApi } from '@samc/react-ui-grid';
import { useCurrentUser } from '../../../features/authorization';
import {
  ActionsWrapper,
  ActionsWrapperContainer,
  ButtonText,
  PanelTitle,
} from './styles';
import bpoIcon from '../../../assets/icon-product-bpo.svg';
import { Content } from '../../../pages/OrderTracking/styles';
import {
  Button,
  Loading,
  PanelHeader,
  Tab,
  Tabs,
  Downloading,
  ConfirmModal,
} from '../../Common';
import BPOGrid from './BPOGrid';
import ExtendedGridFieldConfiguration from '../../../types/ExtendedGridFieldConfiguration';
import api from '../../../services/api';
import {
  ILoan,
  OrderTrackingLoan,
} from '../../../context/order/appraisal/types';
import {
  formatErrorMessage,
  replaceWhiteSpaceWithSpace,
} from '../../../utils/helpers';
import ProgressBar from '../../Common/ProgressBar';
import RequestUpdateModal from './RequestUpdateModal';
import { useBPOPanelOrder } from '../../../queries/useBPOPanelOrder';
import { useCreateRequestUpdate } from '../../../mutations/useCreateRequestUpdate';
import { queryClient } from '../../../services/queryClient';
import { OrderTrackingData } from '../../../queries/useOrders';
import EditAddressModal from '../EditAddressModal';
import { Address } from '../../../context/order/bpo/types';
import {
  createLoanDisputeResolutionParams,
  useCreateLoanDisputeResolution,
} from '../../../mutations/useCreateLoanDisputeResolution';
import { useUpdateCustomerOrderIds } from '../../../mutations/useUpdateCustomerOrderIds';

type ReportType = 'Pdf' | 'Excel' | 'Pdf_Zip';
type Extension = 'pdf' | 'xlsx' | 'zip';
interface ReportExtension {
  [key: string]: Extension;
}
let source: CancelTokenSource;
const reportExtension: ReportExtension = {
  Pdf: 'pdf',
  Excel: 'xlsx',
  Pdf_Zip: 'zip',
};
interface BPOPanelProps {
  selectedBPOOrder: OrderTrackingData;
  onBack: () => void;
  openClarityBpoPanel: (loan: OrderTrackingLoan) => void;
  isOpenBPOEditAddress: boolean;
  isOpenPBOEditAddressCancelConfirm: boolean;
  openEditAddressModal: (isOpen: boolean) => void;
  openEditAddressCancelConfirm: (isOpen: boolean) => void;
}

export interface LoanDispute extends Pick<ILoan, 'clientLoanId' | 'loanId'> {
  disputeReason: string;
  disputeComment: string;
}

export interface DownloadResultsParams {
  reportType?: ReportType;
  loan?: ILoan;
}

const onRenderHeader = () => {
  return (
    <PanelTitle>
      <img src={bpoIcon} alt="product icon" />
      <span>BPO Order Details</span>
    </PanelTitle>
  );
};

function BPOPanel({
  selectedBPOOrder,
  onBack,
  openClarityBpoPanel,
  isOpenBPOEditAddress,
  isOpenPBOEditAddressCancelConfirm,
  openEditAddressModal,
  openEditAddressCancelConfirm,
}: BPOPanelProps) {
  const [gridApi] = useAgGridApi();
  const downloadingRef = React.useRef<HTMLDivElement | null>(null);

  const bpoOrder = useBPOPanelOrder({
    orderId: selectedBPOOrder.orderId,
  });
  const createLoanDisputeResolutionMutation = useCreateLoanDisputeResolution();

  const createRequestUpdateMutation = useCreateRequestUpdate();
  const [isDownloading, setIsDownloading] = React.useState(false);
  const [selectedLoans, setSelectedLoans] = React.useState<OrderTrackingLoan[]>(
    [],
  );
  const [isRequestModalOpen, setIsRequesModalOpen] = React.useState(false);
  const [loanAddressToEdit, setLoanAddressToEdit] = React.useState<
    OrderTrackingLoan | undefined
  >();
  const [isBPOPanelOpen, setIsBPOPanelOpen] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [highlightedRows, setHighlightRows] = React.useState<string[]>([]);

  const updateCustomerOrderIdsMutation = useUpdateCustomerOrderIds();

  const isLoading =
    bpoOrder.isLoading ||
    bpoOrder.isRefetching ||
    createRequestUpdateMutation.isLoading ||
    createLoanDisputeResolutionMutation.isLoading ||
    updateCustomerOrderIdsMutation.isLoading;

  const onSelectTab = React.useCallback(
    async (index: number) => {
      setSelectedTab(index);

      bpoOrder.refetch();
    },
    [bpoOrder],
  );

  const loans = React.useMemo(() => {
    const flatteredLoans: OrderTrackingLoan[] = bpoOrder.data
      ? bpoOrder.data.loans.map((loan: ILoan) => {
          return {
            orderItemId: loan.orderItemId,
            loanStatus: replaceWhiteSpaceWithSpace(loan.loanStatus),
            clientLoanId: loan.clientLoanId,
            accessDetails: loan.accessDetails,
            address: loan.address,
            unitNumber: loan.unitNumber,
            borrowerLastName: loan.borrowerLastName,
            city: loan.city,
            state: loan.state,
            zip: loan.zip,
            productName: loan.productInfo.productName,
            clarityLoanNumber: loan.clarityLoanNumber,
            loanId: loan.loanId,
            loanNumber: loan.loanNumber,
            productId: loan.productInfo.productId,
            providerRequestType: loan.providerRequestType,
            disputeActionType: replaceWhiteSpaceWithSpace(
              loan.disputeActionType,
            ),
            disputeId:
              loan.clarityDisputeId !== undefined
                ? loan.clarityDisputeId
                : undefined,
            disputeReason:
              loan.clarityDisputeReason !== undefined
                ? loan.clarityDisputeReason
                : '',
            customerReferenceId1: loan.customerReferenceId1 ?? '',
            customerReferenceId2: loan.customerReferenceId2 ?? '',
            customerReferenceId3: loan.customerReferenceId3 ?? '',
            lastDownloadedExcelDate: loan.lastDownloadedExcelDate ?? '',
            lastDownloadedPdfDate: loan.lastDownloadedPdfDate ?? '',
          };
        })
      : [];
    return flatteredLoans;
  }, [bpoOrder.data]);

  const completedLoans: OrderTrackingLoan[] = React.useMemo(() => {
    return loans.filter(loan => loan.loanStatus === 'Completed');
  }, [loans]);

  const canceledLoans: OrderTrackingLoan[] = React.useMemo(() => {
    return loans.filter(loan => loan.loanStatus === 'Canceled');
  }, [loans]);

  const pendingLoans: OrderTrackingLoan[] = React.useMemo(() => {
    return loans.filter(loan => loan.loanStatus === 'Action Required');
  }, [loans]);

  const erroredLoans: OrderTrackingLoan[] = React.useMemo(() => {
    return loans.filter(loan => loan.loanStatus === 'Error');
  }, [loans]);

  const inProgressLoans: OrderTrackingLoan[] = React.useMemo(() => {
    return loans.filter(loan => /^In Progress/.test(loan.loanStatus));
  }, [loans]);

  const handleOpenRequestUpdate = React.useCallback(() => {
    setIsBPOPanelOpen(false);
    setIsRequesModalOpen(true);
  }, []);

  const hadleClarityIframe = React.useCallback(
    (loanDispute: OrderTrackingLoan) => {
      openClarityBpoPanel(loanDispute);
      setIsBPOPanelOpen(false);
      setHighlightRows([]);
    },
    [openClarityBpoPanel],
  );

  const handleCloseRequestUpdate = React.useCallback(
    (loanIds?: string[]) => {
      setSelectedLoans([]);
      setIsRequesModalOpen(false);
      setIsBPOPanelOpen(true);
      let newHighlightedRows = highlightedRows;
      if (loanIds && loanIds.length > 0) {
        newHighlightedRows = newHighlightedRows.concat(loanIds);
      }
      setHighlightRows(newHighlightedRows);
    },
    [
      setSelectedLoans,
      setIsRequesModalOpen,
      setIsBPOPanelOpen,
      setHighlightRows,
      highlightedRows,
    ],
  );

  const handleRequestUpdate = React.useCallback(
    (disputeLoans: LoanDispute[]) => {
      if (disputeLoans && disputeLoans.length > 0) {
        const formData = {
          orderId: selectedBPOOrder.orderId,
          loansDispute: disputeLoans,
        };

        createRequestUpdateMutation.mutate(formData, {
          onSuccess: () => {
            handleCloseRequestUpdate(
              disputeLoans.map((loan: LoanDispute) => {
                return loan.loanId;
              }),
            );
            queryClient.invalidateQueries([
              'bpoPanelOrder',
              selectedBPOOrder.orderId,
            ]);
            queryClient.invalidateQueries(['orders', 'TrackingOrders']);
          },
        });
      }
    },
    [
      createRequestUpdateMutation,
      selectedBPOOrder.orderId,
      handleCloseRequestUpdate,
    ],
  );

  function handleOpenDownloading() {
    setIsBPOPanelOpen(false);
    setIsDownloading(true);
  }

  const handleClosingDownloading = React.useCallback(() => {
    source.cancel();
    setSelectedLoans([]);
    setIsDownloading(false);
    setIsBPOPanelOpen(true);
  }, []);

  const handleDownloadResults = React.useCallback(
    async ({
      reportType = 'Pdf',
      loan,
    }: DownloadResultsParams): Promise<void> => {
      const { orderId, orderNumber } = selectedBPOOrder;
      const formData = [];

      if (loan) {
        formData.push({
          clientLoanId: loan.clientLoanId,
          clarityLoanNumber: loan.clarityLoanNumber,
        });
      } else if (selectedLoans.length === 0) {
        formData.push(
          ...completedLoans.map(item => {
            return {
              clientLoanId: item.clientLoanId,
              clarityLoanNumber: item.clarityLoanNumber,
            };
          }),
        );
      } else {
        formData.push(
          ...selectedLoans.map(item => {
            return {
              clientLoanId: item.clientLoanId,
              clarityLoanNumber: item.clarityLoanNumber,
            };
          }),
        );
      }

      let filename = `${orderNumber}`;
      if (formData.length === 1) {
        filename = `${formData[0].clientLoanId}`;
      }

      try {
        source = axios.CancelToken.source();
        handleOpenDownloading();

        const response = await api.post(
          `Reports/completed?orderId=${orderId}&reportType=BPO_${reportType}`,
          formData,
          {
            responseType: 'blob',
            cancelToken: source.token,
          },
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `${filename}.${reportExtension[reportType]}`,
        );
        downloadingRef.current?.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);

        bpoOrder.refetch();
      } catch (err) {
        const e: AxiosError = err as AxiosError;
        const message = await formatErrorMessage(e);

        if (!message) {
          return;
        }

        toastError(<ToastMessage title="Error" message={message} />);
      } finally {
        handleClosingDownloading();
      }
    },
    [
      selectedBPOOrder,
      selectedLoans,
      completedLoans,
      bpoOrder,
      handleClosingDownloading,
    ],
  );

  const AddressReviewNeeded = React.useCallback(
    (loan: OrderTrackingLoan) => {
      openEditAddressModal(true);
      setLoanAddressToEdit(loan);
    },
    [openEditAddressModal, setLoanAddressToEdit],
  );

  const handleCancelClose = React.useCallback(
    (closeConfirm?: boolean) => {
      if (isOpenPBOEditAddressCancelConfirm && !closeConfirm) return;
      openEditAddressCancelConfirm(false);
      openEditAddressModal(false);
      setLoanAddressToEdit(undefined);
    },
    [
      openEditAddressModal,
      setLoanAddressToEdit,
      isOpenPBOEditAddressCancelConfirm,
      openEditAddressCancelConfirm,
    ],
  );

  const handleSaveAddress = React.useCallback(
    ({ street, unitNumber, city, state, zip }: Address) => {
      if (loanAddressToEdit && loanAddressToEdit.disputeId !== undefined) {
        const address = `${street}, ${
          unitNumber && `${unitNumber}, `
        }${city}, ${state}, ${zip}`;

        const formData: createLoanDisputeResolutionParams = {
          orderId: selectedBPOOrder.orderId,
          loansDisputeResolution: [
            {
              loanId: loanAddressToEdit.loanId,
              clarityLoanId: loanAddressToEdit.clarityLoanNumber,
              clientLoanId: loanAddressToEdit.clientLoanId,
              disputeId: loanAddressToEdit.disputeId,
              disputeResponse: address,
            },
          ],
        };

        createLoanDisputeResolutionMutation.mutate(formData, {
          onSuccess: () => {
            handleCancelClose();
            queryClient.invalidateQueries([
              'bpoPanelOrder',
              selectedBPOOrder.orderId,
            ]);
            queryClient.invalidateQueries(['orders', 'TrackingOrders']);
          },
        });
      }
    },
    [
      createLoanDisputeResolutionMutation,
      loanAddressToEdit,
      handleCancelClose,
      selectedBPOOrder.orderId,
    ],
  );

  const handleCancelLoan = React.useCallback(() => {
    if (loanAddressToEdit && loanAddressToEdit.disputeId !== undefined) {
      const formData: createLoanDisputeResolutionParams = {
        orderId: selectedBPOOrder.orderId,
        loansDisputeResolution: [
          {
            loanId: loanAddressToEdit.loanId,
            clarityLoanId: loanAddressToEdit.clarityLoanNumber,
            clientLoanId: loanAddressToEdit.clientLoanId,
            disputeId: loanAddressToEdit.disputeId,
            disputeResponse: 'Cancel loan per client',
          },
        ],
      };

      createLoanDisputeResolutionMutation.mutate(formData, {
        onSuccess: () => {
          handleCancelClose(true);
          queryClient.invalidateQueries([
            'bpoPanelOrder',
            selectedBPOOrder.orderId,
          ]);
          queryClient.invalidateQueries(['orders', 'TrackingOrders']);
        },
      });
    }
  }, [
    createLoanDisputeResolutionMutation,
    loanAddressToEdit,
    handleCancelClose,
    selectedBPOOrder.orderId,
  ]);

  const onChangeData = (args: ChangeDataArgs) => {
    const {
      clarityLoanNumber: clarityLoanId,
      customerReferenceId1,
      customerReferenceId2,
      customerReferenceId3,
      loanId,
      orderItemId,
    } = args.record as Pick<
      OrderTrackingLoan,
      | 'clarityLoanNumber'
      | 'customerReferenceId1'
      | 'customerReferenceId2'
      | 'customerReferenceId3'
      | 'loanId'
      | 'orderItemId'
    >;

    updateCustomerOrderIdsMutation.mutate(
      {
        orderId: selectedBPOOrder.orderId,
        loanId,
        orderItemId,
        clarityLoanId,
        customerReferenceId1,
        customerReferenceId2,
        customerReferenceId3,
      },
      {
        onSuccess: () => {
          if (gridApi) {
            // gridApi.forEachNode(node => {
            //   const nodeData = node.data as OrderTrackingLoan;
            //   if (nodeData.loanId === loanId) {
            //     gridApi.flashCells({
            //       rowNodes: [node],
            //       columns: ['orderIdentifier'],
            //     });
            //   }
            // });
          }

          queryClient.invalidateQueries([
            'bpoPanelOrder',
            selectedBPOOrder.orderId,
          ]);
        },
      },
    );
  };

  const fields: ExtendedGridFieldConfiguration[] = React.useMemo(() => {
    return [
      {
        field: 'loanStatus',
        headerName: 'Status',
        cellRenderer: 'StatusCellRederer',
        width: 380,
      },
      {
        field: 'loanStatus',
        headerName: 'Actions',
        cellRenderer: 'BtnCellRenderer',
        cellRendererParams: {
          order: selectedBPOOrder,

          onOpenRequestDialog: (loan: OrderTrackingLoan) => {
            setSelectedLoans([loan]);
            handleOpenRequestUpdate();
          },
          openClarityBPOOrder: (loan: OrderTrackingLoan) => {
            hadleClarityIframe(loan);
          },
          openAddressReviewNeeded: (loan: OrderTrackingLoan) => {
            AddressReviewNeeded(loan);
          },
          handleDownloadResults,
          handleClosingDownloading,
        },
        width: 450,
      },
      {
        field: 'lastDownloadedExcelDate',
        headerName: 'Last Excel File Downloaded',
        type: 'date',
        width: 150,
      },
      {
        field: 'lastDownloadedPdfDate',
        headerName: 'Last PDF File Downloaded',
        type: 'date',
        width: 150,
      },
      {
        field: 'disputeReason',
        headerName: 'Details',
        type: 'string',
        width: 130,
      },
      {
        field: 'clientLoanId',
        headerName: 'Loan ID',
        type: 'string',
        width: 130,
      },
      {
        field: 'address',
        headerName: 'Street',
        width: 260,
      },
      {
        field: 'unitNumber',
        headerName: 'Suite/Unit #',
        width: 140,
      },
      {
        field: 'city',
        headerName: 'City',
        width: 200,
      },
      {
        field: 'state',
        headerName: 'State',
        width: 100,
      },
      {
        field: 'zip',
        headerName: 'Zip',
        width: 120,
      },
      {
        field: 'productName',
        headerName: 'BPO Type',
        valueFormatter: ({ value }) => value.replace('BPO -', ''),
        tooltipValueGetter: ({ valueFormatted }) => valueFormatted || '',
        width: 130,
      },
      {
        field: 'accessDetails',
        headerName: 'Interior Access Details',
      },
      {
        field: 'customerReferenceId1',
        headerName: 'Customer Reference ID1',
        width: 200,
        cellRenderer: 'EditableRenderer',
      },
      {
        field: 'customerReferenceId2',
        headerName: 'Customer Reference ID2',
        width: 200,
        cellRenderer: 'EditableRenderer',
      },
      {
        field: 'customerReferenceId3',
        headerName: 'Customer Reference ID3',
        width: 200,
        cellRenderer: 'EditableRenderer',
      },
    ];
  }, [
    handleClosingDownloading,
    handleDownloadResults,
    handleOpenRequestUpdate,
    selectedBPOOrder,
    hadleClarityIframe,
    AddressReviewNeeded,
  ]);

  React.useEffect(() => {
    if (selectedBPOOrder) {
      setIsBPOPanelOpen(true);
    } else {
      setIsBPOPanelOpen(false);
      setHighlightRows([]);
    }
  }, [selectedBPOOrder]);

  const onSelectionChanged = (
    loan: OrderTrackingLoan | OrderTrackingLoan[] | null,
    checked: boolean,
  ) => {
    if (loan && !Array.isArray(loan)) {
      const newSelectedLoans = [...selectedLoans];
      if (checked) {
        if (
          !newSelectedLoans.some(
            otherLoan => otherLoan.orderItemId === loan.orderItemId,
          )
        ) {
          newSelectedLoans.push(loan);
          setSelectedLoans(newSelectedLoans);
        }
      } else {
        setSelectedLoans(
          newSelectedLoans.filter(
            otherLoan => otherLoan.orderItemId !== loan.orderItemId,
          ),
        );
      }
    } else if (checked) {
      setSelectedLoans(
        loans.filter(myLoan => {
          return (
            myLoan.loanStatus === 'Completed' &&
            (loan === null || loan?.includes(myLoan))
          );
        }),
      );
    } else {
      setSelectedLoans([]);
    }
  };

  const completedLoansPercentage = bpoOrder.data
    ? ((completedLoans.length + canceledLoans.length) /
        bpoOrder.data.loans.length) *
      100
    : 0;

  const isButtonResultsDisable = completedLoans.length === 0;
  const currentUser = useCurrentUser();
  return (
    <>
      <Panel
        isOpen={
          isBPOPanelOpen ||
          isOpenBPOEditAddress ||
          isOpenPBOEditAddressCancelConfirm
        }
        onDismiss={() => {
          if (!isOpenBPOEditAddress && !isOpenPBOEditAddressCancelConfirm)
            onBack();
        }}
        type={7}
        customWidth="80%"
        closeButtonAriaLabel="Close"
        headerText="BPO Order Details"
        onRenderHeader={onRenderHeader}
        styles={{
          commands: {
            marginTop: 0,
            padding: 10,
            borderBottom: '1px solid #e0e0e0',
          },
          content: { padding: 0 },
        }}
      >
        {isLoading && <Loading width="100%" height="100%" />}
        <PanelHeader>
          <PanelHeader.Item>
            <PanelHeader.Item.Label>Order #</PanelHeader.Item.Label>
            <PanelHeader.Item.Value>
              {bpoOrder.data?.orderNumber}
            </PanelHeader.Item.Value>
          </PanelHeader.Item>
          <PanelHeader.Item>
            <PanelHeader.Item.Label>Order Identifier</PanelHeader.Item.Label>
            <PanelHeader.Item.Value>
              {bpoOrder.data?.identifier}
            </PanelHeader.Item.Value>
          </PanelHeader.Item>
          <PanelHeader.Item>
            <PanelHeader.Item.Label>Submitted By</PanelHeader.Item.Label>
            <PanelHeader.Item.Value>
              {bpoOrder.data?.createdBy}
            </PanelHeader.Item.Value>
          </PanelHeader.Item>
          <PanelHeader.Item>
            <PanelHeader.Item.Label>Submitted Date</PanelHeader.Item.Label>
            <PanelHeader.Item.Value>
              {selectedBPOOrder.submittedDate}
            </PanelHeader.Item.Value>
          </PanelHeader.Item>
          <PanelHeader.Item>
            <PanelHeader.Item.Label>
              {`${completedLoans.length + canceledLoans.length}/${
                bpoOrder.data?.loans.length
              } Loan(s) Complete`}
            </PanelHeader.Item.Label>
            <PanelHeader.Item.Value>
              <ProgressBar progress={completedLoansPercentage} />
            </PanelHeader.Item.Value>
          </PanelHeader.Item>
          <PanelHeader.Item>
            <ActionsWrapper>
              {currentUser.hasEntitlement(
                'Order Tracking - BPO Panel - Results',
              ) && (
                <ActionsWrapperContainer>
                  <p>Download Results:</p>
                  <Button
                    width="fit-content"
                    height="40px"
                    onClick={() =>
                      handleDownloadResults({ reportType: 'Excel' })
                    }
                    disabled={isButtonResultsDisable}
                  >
                    <FiDownload size={25} />

                    <ButtonText>
                      <span>Excel File</span>
                      <p>
                        {selectedLoans.length > 0
                          ? `${selectedLoans.length} loan${
                              selectedLoans.length > 1 ? 's' : ''
                            }`
                          : 'All Results'}
                      </p>
                    </ButtonText>
                  </Button>
                  <Button
                    width="fit-content"
                    height="40px"
                    onClick={() =>
                      handleDownloadResults({
                        reportType:
                          selectedLoans.length === 1 ||
                          completedLoans.length === 1
                            ? 'Pdf'
                            : 'Pdf_Zip',
                      })
                    }
                    disabled={isButtonResultsDisable}
                  >
                    <FiDownload size={25} />

                    <ButtonText>
                      <span>
                        {selectedLoans.length === 1 ||
                        completedLoans.length === 1
                          ? 'PDF File'
                          : 'Zip File'}
                      </span>
                      <p>
                        {(() => {
                          if (
                            (selectedLoans.length === 0 &&
                              completedLoans.length > 0) ||
                            selectedLoans.length >= completedLoans.length
                          ) {
                            return 'All Completed';
                          }

                          if (
                            selectedLoans.length > 0 &&
                            completedLoans.length > 0
                          ) {
                            return `${selectedLoans.length} loan${
                              selectedLoans.length > 1 ? 's' : ''
                            }`;
                          }

                          return 'All Completed';
                        })()}
                      </p>
                    </ButtonText>
                  </Button>
                </ActionsWrapperContainer>
              )}
              {currentUser.hasEntitlement(
                'Order Tracking - BPO Panel - Update',
              ) && (
                <ActionsWrapperContainer>
                  {selectedLoans.length > 0 && (
                    <Button
                      width="fit-content"
                      variant="secondary"
                      height="40px"
                      onClick={() => handleOpenRequestUpdate()}
                      disabled={isButtonResultsDisable}
                    >
                      <MdRefresh size={30} />

                      <ButtonText>
                        <span>Request Update</span>

                        <p>
                          {`${selectedLoans.length} loan${
                            selectedLoans.length > 1 ? 's' : ''
                          }`}
                        </p>
                      </ButtonText>
                    </Button>
                  )}
                </ActionsWrapperContainer>
              )}
            </ActionsWrapper>
          </PanelHeader.Item>
        </PanelHeader>
        <Content padding="20px 0px 0px 0px">
          <Tabs onSelectTab={onSelectTab} selectedTab={selectedTab}>
            <Tab label="All Loans" count={loans.length} index={0} variant="All">
              <BPOGrid
                highlightRows={highlightedRows}
                fields={fields}
                loans={loans}
                onSelectionChanged={onSelectionChanged}
                selectedLoans={selectedLoans}
                onChangeData={onChangeData}
              />
            </Tab>
            <Tab
              label="Complete"
              count={completedLoans.length}
              index={1}
              variant="Complete"
            >
              <BPOGrid
                highlightRows={highlightedRows}
                fields={fields}
                loans={completedLoans}
                onSelectionChanged={onSelectionChanged}
                selectedLoans={selectedLoans}
                onChangeData={onChangeData}
              />
            </Tab>
            <Tab
              label="Errors"
              count={erroredLoans.length}
              index={2}
              variant="Error"
            >
              <BPOGrid
                highlightRows={highlightedRows}
                fields={fields}
                loans={erroredLoans}
                selectedLoans={selectedLoans}
                onChangeData={onChangeData}
              />
            </Tab>
            <Tab
              label="Action Required"
              count={pendingLoans.length}
              index={3}
              variant="Action Required"
            >
              <BPOGrid
                highlightRows={highlightedRows}
                fields={fields}
                loans={pendingLoans}
                selectedLoans={selectedLoans}
                onChangeData={onChangeData}
              />
            </Tab>
            <Tab
              label="In Progress"
              count={inProgressLoans.length}
              index={4}
              variant="In Progress"
            >
              <BPOGrid
                highlightRows={highlightedRows}
                fields={fields}
                loans={inProgressLoans}
                selectedLoans={selectedLoans}
                onChangeData={onChangeData}
              />
            </Tab>
            <Tab
              label="Canceled"
              count={canceledLoans.length}
              index={5}
              variant="Canceled"
            >
              <BPOGrid
                highlightRows={highlightedRows}
                fields={fields}
                loans={canceledLoans}
                selectedLoans={selectedLoans}
                onChangeData={onChangeData}
              />
            </Tab>
          </Tabs>
        </Content>
      </Panel>
      {isRequestModalOpen && (
        <RequestUpdateModal
          isLoading={isLoading}
          isRequestModalOpen={isRequestModalOpen}
          handleCloseRequestUpdate={handleCloseRequestUpdate}
          handleRequestUpdate={handleRequestUpdate}
          selectedLoans={selectedLoans}
        />
      )}
      {isOpenBPOEditAddress && loanAddressToEdit && (
        <EditAddressModal
          isLoading={isLoading}
          isEditAddressModalOpen={
            isOpenBPOEditAddress || isOpenPBOEditAddressCancelConfirm
          }
          isOpenPBOEditAddressCancelConfirm={isOpenPBOEditAddressCancelConfirm}
          handleCancelClose={handleCancelClose}
          handleSaveAddress={handleSaveAddress}
          handleCancelLoan={() => {
            openEditAddressCancelConfirm(true);
          }}
          rowData={loanAddressToEdit}
        />
      )}
      {isOpenBPOEditAddress &&
        loanAddressToEdit &&
        isOpenPBOEditAddressCancelConfirm && (
          <ConfirmModal
            isModalOpen={isOpenPBOEditAddressCancelConfirm}
            title="Cancel BPO Loan"
            details="Are you sure you want to cancel this loan? This cannot be undone."
            cancelText="Don't Cancel"
            handleCancel={() => {
              openEditAddressCancelConfirm(false);
            }}
            confirmText="Cancel Loan"
            confirmVariant="tertiary"
            handleConfirm={handleCancelLoan}
          />
        )}
      <Downloading
        ref={downloadingRef}
        isOpen={isDownloading}
        onDismiss={handleClosingDownloading}
        width={340}
        height={258}
      />
    </>
  );
}

export default BPOPanel;
