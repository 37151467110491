/* eslint-disable no-unused-expressions */
import { IDropdownStyles, ITextFieldStyles } from 'office-ui-fabric-react';
import styled from 'styled-components';
import { Field as FormikField } from 'formik';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const Template = styled.div`
  display: flex;
  flex-direction: column;
  button {
    margin-top: 14px;
  }
  p {
    margin-top: 35px;
  }
`;

export const ErrorMessage = styled.div`
  color: ${props => props.theme.colors.red};
  font-weight: 700;
`;

export const LinkButton = styled.button`
  border: 0;
  background: transparent;
  color: ${props => props.theme.colors.primary};
  font-weight: 600;
`;

export const Row = styled.tr`
  padding: 10px;
`;

export const Cell = styled.td`
  padding: 0px 7px;
`;

export const HeaderCell = styled.th`
  padding-left: 8px;
`;

export const FieldWrapper = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: column;
`;

export const Field = styled(FormikField)`
  .ms-TextField-errorMessage {
    padding-top: 0px;
  }
`;

export const BPOType = styled.div``;

export const Label = styled.div``;

export const Options = styled.div``;

export const Option = styled.div``;

export const getFieldStyles = (error: boolean): Partial<ITextFieldStyles> => {
  if (!error) {
    return {};
  }
  return {
    field: { border: '1px solid #BD0E08', borderRadius: 2 },
  };
};

export const getComboBoxStyles = (
  error: boolean,
): Partial<IDropdownStyles> => ({
  dropdown: {
    width: '100%',
  },
  root: {
    borderRadius: 2,
    ':after': {
      borderColor: `${error ? '#BD0E08' : '#dbdbdb'}`,
    },
  },
});

export const Controls = styled.div`
  display: flex;
`;
