import { BaseGrid, BaseGridRef, useAgGridApi } from '@samc/react-ui-grid';
import React from 'react';
import { TextField } from 'office-ui-fabric-react';
import { FaCheckCircle } from 'react-icons/fa';
import { GridOptions, ValueGetterParams } from 'ag-grid-community';
import { ControlBar, StepContainer, useStepper } from '../../Common';
import {
  Content,
  Title,
  Counts,
  GridWrapper,
  Overview,
  Description,
  Total,
  OrderIdentifier,
  OrderSubmitted,
  Info,
  UnavailableQuantity,
  GridTools,
  SearchField,
} from './styles';
import { formatCurrency } from '../../../utils/helpers';
import { useOrder } from '../../../context/order';
import OrderCardList from '../OrderCardList';
import ExtendedGridFieldConfiguration from '../../../types/ExtendedGridFieldConfiguration';
import {
  BPOPanelUnAvailableProduct,
  BPOProductGridAvailableData,
  BPOTypeRenderer,
  cellStyle,
  PopUpRenderer,
  AddressPopUpRenderer,
  NotesRenderer,
} from './config';
import { useProducts } from '../../../queries/useProducts';
import { BPOLoanAddress } from '../../../context/order/bpo/types';
import {
  extractAddresses,
  formatAddress,
} from '../../../context/order/bpo/util';
import { ISubProduct } from '../../../context/order/types';

export interface BPORevisionSubmitSummary {
  loansCant: number;
  orderTotal: number;
  interiorPrice: number;
  exteriorPrice: number;
  exteriorCant: number;
  interiorCant: number;
}

let quickFilter = '';

function BPOReviewSubmit() {
  const gridRef = React.useRef<BaseGridRef<BPOProductGridAvailableData>>(null);
  const {
    order,
    orderActions: { onUpdateOrderIdentifier },
  } = useOrder();
  const { state } = useStepper();

  const [gridApi, onGridReady] = useAgGridApi();
  const productsQuery = useProducts(order.product.vendorId);
  const [unAvailableProducts, setUnAvailableProducts] = React.useState<
    string[]
  >([]);

  const BPOProduct = React.useMemo(() => {
    if (!productsQuery.isLoading && productsQuery.data) {
      return productsQuery.data.find(
        product => product.productId === order.product.productId,
      );
    }
    return undefined;
  }, [order.product.productId, productsQuery.data, productsQuery.isLoading]);

  const [subProductLookup, subProductLookupById] = React.useMemo(() => {
    const lookup: { [index: string]: ISubProduct } = {};
    const lookupById: { [index: string]: ISubProduct } = {};
    order.bpoLoanValidation.productInfo?.subProducts.forEach(product => {
      lookup[product.productName] = product;
      if (product.subProductId) {
        lookupById[product.subProductId] = product;
      }
    });
    return [lookup, lookupById];
  }, [order.bpoLoanValidation.productInfo?.subProducts]);

  const productCounts = React.useMemo(() => {
    const counts: { [key: string]: number } = {};
    order.bpoLoanValidation.data.forEach(loan => {
      if (loan.bpoProduct.name !== null) {
        if (counts[loan.bpoProduct.name] === undefined) {
          counts[loan.bpoProduct.name] = 0;
        }
        counts[loan.bpoProduct.name]++;
      }
    });
    return counts;
  }, [order.bpoLoanValidation.data]);

  const loaded = React.useRef(false);
  React.useEffect(() => {
    if (!loaded.current) {
      loaded.current = true;
      quickFilter = '';
    }
  }, []);

  React.useEffect(() => {
    if (order.bpoLoanValidation.data) {
      if (BPOProduct && BPOProduct.available) {
        setUnAvailableProducts(
          Object.keys(productCounts).filter(
            productName =>
              subProductLookup[productName] === undefined ||
              subProductLookup[productName].isAvailable !== true,
          ),
        );
      } else if (BPOProduct && !BPOProduct.available) {
        setUnAvailableProducts(Object.keys(productCounts));
      } else {
        setUnAvailableProducts([]);
      }

      gridRef.current?.reset();
    }
  }, [
    BPOProduct,
    order.bpoLoanValidation.data,
    productCounts,
    subProductLookup,
  ]);

  React.useEffect(() => {
    if (state.isRevalidating) {
      gridRef.current?.reset();
    }
  }, [state.isRevalidating]);

  const dataGrid = React.useMemo(() => {
    return order.bpoLoanValidation.data.map(loan => {
      return {
        ...loan,
        bpoLoanData: loan,
        providerRequestType: loan.bpoProduct.name,
        isAvailable:
          loan.bpoProduct.name &&
          !unAvailableProducts.includes(loan.bpoProduct.name),
      };
    });
  }, [order.bpoLoanValidation.data, unAvailableProducts]);

  const popUpRenderer = state.isSubmitted ? undefined : 'PopUpRenderer';
  const popUpAddressRenderer = state.isSubmitted
    ? undefined
    : 'AddressPopUpRenderer';
  const popUpTooltipValueGetter = state.isSubmitted
    ? undefined
    : () => 'Edit Loan';

  const addressesFormatter = (value: BPOLoanAddress[]) => {
    const address = value.find((row: BPOLoanAddress) => row.isSelected);
    if (address) {
      return formatAddress(address);
    }
    return '';
  };

  const fields: ExtendedGridFieldConfiguration[] = [
    {
      field: 'clientLoanId',
      headerName: 'Loan Id',
      cellRenderer: popUpRenderer,
      type: 'string',
      tooltipValueGetter: popUpTooltipValueGetter,
      cellStyle,
      flex: 0,
      width: 150,
    },
    {
      field: 'addresses',
      headerName: 'Address',
      cellRenderer: popUpAddressRenderer,
      type: 'string',
      tooltipValueGetter: popUpTooltipValueGetter,
      cellStyle,
      valueFormatter: params => {
        return addressesFormatter(params.value);
      },
      width: 350,
    },
    {
      field: 'bpoType',
      headerName: 'BPO Type',
      cellRenderer: popUpRenderer,
      type: 'string',
      tooltipValueGetter: popUpTooltipValueGetter,
      cellStyle,
      valueGetter: (params: ValueGetterParams) => {
        return params.data.bpoProduct.name;
      },
      width: 110,
    },
    {
      field: 'accessDetails',
      headerName: 'Interior Access Details',
      cellRenderer: popUpRenderer,
      type: 'string',
      tooltipValueGetter: popUpTooltipValueGetter,
      cellStyle,
      flex: 1,
    },
    {
      field: 'customerReferenceId1',
      headerName: 'Customer Reference ID1',
      cellRenderer: popUpRenderer,
      type: 'string',
      tooltipValueGetter: popUpTooltipValueGetter,
      cellStyle,
      flex: 0,
      width: 200,
    },
    {
      field: 'customerReferenceId2',
      headerName: 'Customer Reference ID2',
      cellRenderer: popUpRenderer,
      type: 'string',
      tooltipValueGetter: popUpTooltipValueGetter,
      cellStyle,
      flex: 0,
      width: 200,
    },
    {
      field: 'customerReferenceId3',
      headerName: 'Customer Reference ID3',
      cellRenderer: popUpRenderer,
      type: 'string',
      tooltipValueGetter: popUpTooltipValueGetter,
      cellStyle,
      flex: 0,
      width: 200,
    },
    {
      field: 'details',
      headerName: 'Notes',
      cellRenderer: 'NotesRenderer',
      cellRendererParams: {
        subProductLookupById,
      },
      type: 'string',
      tooltipValueGetter: () => 'View Details',
      cellStyle,
      flex: 1,
    },
  ];

  const onQuickFilterChange: React.ChangeEventHandler<
    HTMLInputElement
  > = event => {
    quickFilter = event.target.value.trim().toLowerCase();
    gridApi?.onFilterChanged();
  };

  const onGridExport = () => {
    if (gridApi) {
      gridApi.exportDataAsExcel({
        processCellCallback: params => {
          const colDef = params.column.getColDef();
          switch (colDef.field) {
            case 'addresses': {
              if (params.node) {
                const addresses = extractAddresses(params.node.data);
                const address =
                  addresses.selected || addresses.edited || addresses.original;
                return address ? formatAddress(address) : '';
              }
              return '';
            }
            default: {
              return params.value;
            }
          }
        },
      });
    }
  };

  const gridOptions: GridOptions = {
    isExternalFilterPresent: () => {
      return quickFilter !== '';
    },
    doesExternalFilterPass: node => {
      let match = ['accessDetails', 'clientLoanId'].some(field => {
        return node.data[field].toLowerCase().indexOf(quickFilter) > -1;
      });
      if (match) return true;

      if (node.data.bpoProduct.name.toLowerCase().indexOf(quickFilter) > -1) {
        return true;
      }

      const addressLookup = extractAddresses(node.data);
      if (addressLookup.selected) {
        const addressFields: Array<keyof BPOLoanAddress> = [
          'street',
          'unitNumber',
          'city',
          'state',
          'zip',
        ];
        match = addressFields.some(field => {
          if (addressLookup.selected) {
            const value = addressLookup.selected[field];
            if (typeof value === 'string') {
              return value.toLowerCase().indexOf(quickFilter) > -1;
            }
          }
          return false;
        });
      }

      return match;
    },
  };

  const [availableCount, unavailableCount] = React.useMemo(() => {
    let available = 0;
    let unavailable = 0;
    Object.keys(productCounts).forEach(productId => {
      const count = productCounts[productId] || 0;
      if (
        subProductLookup[productId] &&
        subProductLookup[productId].isAvailable
      ) {
        available += count;
      } else {
        unavailable += count;
      }
    });
    return [available, unavailable];
  }, [productCounts, subProductLookup]);

  if (!productsQuery) return null;

  const summary = {
    exteriorCant: 0,
    interiorCant: 0,
    loansCant: 0,
    exteriorPrice: 0,
    interiorPrice: 0,
    orderTotal: 0,
    ...order.bpoLoanValidation.summary,
  };

  const productCountsUI: JSX.Element[] = [];
  order.bpoLoanValidation.productInfo?.subProducts.forEach(subProduct => {
    if (
      subProduct.isAvailable === true &&
      productCounts[subProduct.productName] !== undefined
    ) {
      productCountsUI.push(
        <p key={subProduct.productId}>
          {productCounts[subProduct.productName]} {subProduct.productName} •{' '}
          {formatCurrency(subProduct.price)} per loan
        </p>,
      );
    }
  });

  return (
    <StepContainer align="center">
      <Content>
        {!state.isSubmitted && (
          <Title>
            Please <strong>review your order.</strong>
          </Title>
        )}
        <GridTools>
          <Counts style={{ flex: '1 1 auto' }}>
            {availableCount + unavailableCount} Loans{' '}
            {unavailableCount > 0 && (
              <UnavailableQuantity>
                ({unavailableCount} Unavailable)
              </UnavailableQuantity>
            )}
            {order.bpoLoanValidation.uploadFileName && (
              <span>• {order.bpoLoanValidation.uploadFileName}</span>
            )}
          </Counts>
          <SearchField>
            <input
              type="text"
              placeholder="Filter"
              onChange={onQuickFilterChange}
            />
          </SearchField>
        </GridTools>
        <ControlBar onExport={onGridExport} suppressDeleteButton />
        <GridWrapper>
          <BaseGrid
            ref={gridRef}
            data={dataGrid as BPOProductGridAvailableData[]}
            fields={fields}
            idField="orderItemId"
            frameworkComponents={{
              BPOTypeRenderer,
              PopUpRenderer,
              AddressPopUpRenderer,
              NotesRenderer,
            }}
            sizeColumnsToFit
            rowsPerPage={100}
            suppressPaginationPanel={false}
            suppressColumnFilters
            suppressRowSelector
            onBodyGridReady={onGridReady}
            gridOptions={gridOptions}
          />
        </GridWrapper>
        {unAvailableProducts.length > 0 &&
          unAvailableProducts.map(productName => (
            <BPOPanelUnAvailableProduct
              productName={productName}
              quantityLoan={productCounts[productName]}
            />
          ))}
        <Overview>
          <Description>
            <strong>Broker Price Opinion</strong>
            {productCountsUI}
          </Description>
          <Total>
            <strong>Order Total</strong>
            <span>{formatCurrency(summary.orderTotal)}</span>
          </Total>
        </Overview>
        {state.isSubmitted ? (
          <>
            <OrderSubmitted>
              <FaCheckCircle size={40} />
              <div>
                <h2>Order {order.orderNumber} Submitted</h2>
                {order.orderIdentifier && (
                  <p>Order Identifier: {order.orderIdentifier}</p>
                )}
              </div>
            </OrderSubmitted>
            <Info>
              <strong>Your order is in progress.</strong> We&apos;ll notify you
              when an update is available.
            </Info>
            <OrderCardList orderId={order.orderId} />
          </>
        ) : (
          <OrderIdentifier>
            <TextField
              id="Order"
              label="Order Identifier (Optional)"
              value={order.orderIdentifier}
              onChange={(_e, val) => {
                onUpdateOrderIdentifier(val || '');
              }}
            />
          </OrderIdentifier>
        )}
      </Content>
    </StepContainer>
  );
}

export default BPOReviewSubmit;
