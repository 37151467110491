import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import api from '../services/api';

export async function removeAppraisalUpload({
  stagingId,
}: {
  stagingId: string;
}) {
  await api.delete('AppraisalOrder/staging', {
    params: { stagingId },
  });
}

export function useRemoveAppraisalUpload() {
  return useMutation<
    void,
    AxiosError<ErrorData>,
    { stagingId: string; documentId: string }
  >(({ stagingId }) => removeAppraisalUpload({ stagingId }));
}
