import { AuthenticationConfig } from '@samc/auth-authentication-okta';
import AuthorizationConfig from '../features/authorization/models/AuthorizationConfig';

export const oktaConfig: AuthenticationConfig = {
  platformName: 'Certainty',
  appName: 'Certainty',
  baseUrl: window.config.oktaBaseUrl,
  clientId: window.config.oktaClientId,
  issuer: window.config.oktaIssuerUrl,
  loginRedirectMethod: 'HardRedirect',
  oktaUrl: window.config.oktaUrl,
  contactEmail: '#',
  helpLink: '#',
  loginRedirectStrategy: 'ToLastVisitedUrl',
  tokenRefreshMethod: 'None',
  useOktaUser: false,
  showLogoutWarning: false,
};

export const authConfig: AuthorizationConfig = {
  platformName: 'Certainty',
  appName: 'Certainty',
  apiUrl: window.config.authApiUrl,
  agGridLicense:
    'CompanyName=SHI International Corp._on_behalf_of_SitusAMC Holdings Corporation,LicensedGroup=SitusAMC Information Technology,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=50,LicensedProductionInstancesCount=20,AssetReference=AG-037343,SupportServicesEnd=6_April_2024_[v2]_MTcxMjM1ODAwMDAwMA==153082338970b927df5e010843925d4d',
  helpLink: '#',
  contactEmail: '#',
  authenticationScheme: 'Okta',
  setContainerId: false,
  showErrorForUserWithNoAccess: false,
  requireTermsOfUseAcceptance: true,
};
