/* eslint-disable react/function-component-definition */
import React from 'react';
import { useDrag } from 'react-dnd';
import { DefaultPublisher } from '@samc/common';
import dragIcon from './Draggable.svg';
import Base from '../../models/Base';
import {
  SelectionEvent,
  SelectionType,
  DragStartedEvent,
  GroupSelectionEvent,
} from '../../events';
import './Draggable.css';

export interface DragData<T extends Base<T>> {
  model: T;
  type: string;
}

type DraggableProps<T extends Base<T>> = {
  model: T;
  typeName: string;
  display: (model: T) => string;
  isSelected?: boolean;
  isFocus?: boolean /* Not REALLY focus, just the best way to describe it. Draws a dotted line around it, but the focus/selection is managed by Droppable. */;
};

type DraggableCollectionProps<T extends Base<T>> = {
  models: T[];
  title: string;
  children: React.ReactElement[];
};

export const Draggable = <T extends Base<T>>(props: DraggableProps<T>) => {
  const { model, display, typeName, isSelected, isFocus } = props;
  const dragData: DragData<T> = {
    model,
    type: typeName,
  };

  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: typeName,
      item: () => {
        DefaultPublisher.publish(new DragStartedEvent(model.id));
        return dragData;
      },
      collect: monitor => ({ opacity: monitor.isDragging() ? 0.5 : 1 }),
    }),
    [],
  );
  const displayName = display(model);
  if (!displayName) {
    // eslint-disable-next-line no-console
    console.warn(
      `Display function didn't render a valid name: ${display}. Model: ${model}`,
    );
  }

  const className = `draggable cursor-pointer${isSelected ? ' selected' : ''}${
    isFocus ? ' focused' : ''
  }`;

  const clicked = (e: React.MouseEvent) => {
    const type = DetermineSelectionType(e);
    DefaultPublisher.publish(new SelectionEvent(model.id, type));
  };
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={className}
      ref={dragRef}
      key={model.id.toString()}
      style={{ opacity }}
      onClick={clicked}
    >
      <div className="draggable-border">
        <img src={dragIcon} alt="Drag" />
        <span> {displayName}</span>
      </div>
    </div>
  );
};

export const DraggableCollection = <T extends Base<T>>(
  props: DraggableCollectionProps<T>,
) => {
  const { title, models, children } = props;
  const clicked = () => {
    DefaultPublisher.publish(new GroupSelectionEvent(models.map(m => m.id)));
  };

  return (
    <div className="draggableCollection" key={title}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
      <h5 className="group bg-mono-6 text-1 cursor-pointer" onClick={clicked}>
        {title}
      </h5>
      {children}
    </div>
  );
};

function DetermineSelectionType(e: React.MouseEvent) {
  let type = SelectionType.Normal;
  if (e.ctrlKey) {
    type = SelectionType.Single;
  }
  // Shift overrides ctrl
  if (e.shiftKey) {
    type = SelectionType.Range;
  }
  return type;
}
