import React from 'react';
import _ from 'underscore';
import { IChoiceGroupOption } from '@fluentui/react';
import { ICellRendererParams } from 'ag-grid-community';
import { RiDeleteBin7Line } from 'react-icons/ri';
import { MdEdit } from 'react-icons/md';
import { BPOValidationAddressGridData } from '.';
import {
  Address,
  BPOLoanAddress,
  BPOLoanData,
} from '../../../context/order/bpo/types';
import CellStyleParams from '../../../types/CellStyleParams';
import {
  AddressNotFound,
  DeleteContainer,
  LoanIdContainer,
  PopupContainer,
} from './styles';
import RadioGroup from '../../Common/RadioGroup';
import { extractAddresses } from '../../../context/order/bpo/util';
import OriginalAddressModal from './OriginalAddressModal';
import { useOrder } from '../../../context/order';
import { useStepper } from '../../Common';

export interface FrameworkComp {
  [p: string]: unknown;
}

interface ErrorType {
  [key: string]: {
    color: string;
    borderColor: string;
    backgroundColor: string;
  };
}

export const errorType: ErrorType = {
  Error: {
    color: '#BD0E08',
    borderColor: '#BD0E08',
    backgroundColor: '#f5e2e0',
  },
  Address: {
    color: '#BD0E08',
    borderColor: '#BD0E08',
    backgroundColor: '#f5e2e0',
  },
  Warning: {
    color: '#e6930a',
    borderColor: '#e6930a',
    backgroundColor: '#ffe1b0',
  },
  Duplicated: {
    color: '#646464',
    borderColor: '#646464',
    backgroundColor: '#dcdcdc',
  },
};

export const cellStyle = (params: CellStyleParams): React.CSSProperties => {
  const { suggestedAddresses, selectedAddress, originalAddress } =
    params.data as BPOValidationAddressGridData;

  const column = params.column.getColId();

  const selectedFromSuggested =
    suggestedAddresses !== undefined &&
    !_.isEmpty(selectedAddress) &&
    _.some(suggestedAddresses, selectedAddress);

  if (selectedFromSuggested && column === 'selectedAddress') {
    if (suggestedAddresses.length > 1) {
      return { backgroundColor: '#e4f7da', color: 'rgba(19, 61, 145, 0.5)' };
    }
    return { backgroundColor: '#e4f7da', color: '#000' };
  }

  if (column === 'originalAddress') {
    if (suggestedAddresses !== undefined && suggestedAddresses.length === 0) {
      if (originalAddress === selectedAddress) {
        return {
          backgroundColor: '#e4f7da',
          color: '#000',
        };
      }

      return {
        height: '100%',
        flex: 1,
        color: '#000',
      };
    }

    if (originalAddress === selectedAddress) {
      return {
        backgroundColor: '#e4f7da',
        color: '#000',
      };
    }

    return {
      height: '100%',
      flex: 1,
      background: 'unset',
    };
  }

  if (
    suggestedAddresses !== undefined &&
    suggestedAddresses.length > 1 &&
    column === 'selectedAddress'
  ) {
    if (selectedAddress === originalAddress) {
      return { color: '#bbb', background: 'unset' };
    }
    return { color: 'rgba(19, 61, 145, 1)' };
  }

  if (
    suggestedAddresses !== undefined &&
    suggestedAddresses.length === 0 &&
    column === 'selectedAddress'
  ) {
    return { backgroundColor: '#ffe3e3', color: '#bd0e08' };
  }

  return { backgroundColor: 'unset', color: '#bbb' };
};

export const formatAddress = (address: Address): string => {
  const { street, unitNumber, city, state, zip } = address;
  const parts = [street, unitNumber, city, state, zip].filter(
    part => part !== undefined && part !== '',
  );

  return parts.join(', ');
};
export function LoanIdRenderer({ value }: ICellRendererParams) {
  return <LoanIdContainer>{value}</LoanIdContainer>;
}

export function PopUpRenderer({ data, node, api }: ICellRendererParams) {
  const [isOpen, setIsOpen] = React.useState(false);
  const isMounted = React.useRef(false);

  const {
    order,
    bpoActions: { onEditBPOLoanAddress },
  } = useOrder();

  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const loan = data as BPOLoanData;
  const addressLookup = extractAddresses(loan);

  const setData = (editedAddress: Address) => {
    onEditBPOLoanAddress(loan.row, editedAddress);
    const updatedLoan = order.bpoLoanValidation.data.find(
      row => row.bpoOrderStagingId === data.bpoOrderStagingId,
    );
    node.setData(updatedLoan);

    api.refreshCells({ force: true, rowNodes: [node] });
  };

  const originalAddress = formatAddress(
    addressLookup.original || ({} as BPOLoanAddress),
  );

  return (
    <PopupContainer onClick={() => setIsOpen(true)}>
      <span>{originalAddress}</span>
      <MdEdit size={16} />
      <OriginalAddressModal
        isOpen={isOpen}
        rowData={data}
        onSubmit={setData}
        onDismiss={() => setIsOpen(false)}
        isLoading={false}
      />
    </PopupContainer>
  );
}

export function SelectableRenderer({
  // value,
  node,
  api,
  ...params
}: ICellRendererParams) {
  function onChange(option: string | number): void {
    setData(option as string);
  }

  const data = params.data as BPOLoanData;
  const addressLookup = extractAddresses(data);

  const options: IChoiceGroupOption[] = addressLookup.suggested.map(
    (address, index) => {
      const suggestedAddress = formatAddress(address);
      return {
        key: address.bpoOrderStagingAddressId || `suggested${index.toString()}`, // suggested addresses should always have an ID
        text: suggestedAddress,
      };
    },
  );

  if (addressLookup.edited) {
    options.push({
      key: addressLookup.edited.bpoOrderStagingAddressId || 'edited',
      text: `Use Edited Address: ${formatAddress(addressLookup.edited)}`,
    });
  }

  options.push({
    key:
      addressLookup.original?.bpoOrderStagingAddressId || 'useOriginalAddress',
    text: 'Use Original Address',
  });

  const {
    order,
    bpoActions: { onChangeSingleBPOValidationData },
  } = useOrder();

  const { onRefreshData } = useStepper();

  const setData = (key: string) => {
    if (key === 'useOriginalAddress' || key.substring(1, 9) === 'suggested') {
      // This is not expected to happen
      throw new Error(
        'Unable to determine ID for original or suggested address.',
      );
    }

    let hasChange = false;
    const myLoan: BPOLoanData = {
      ...node.data,
      addresses: (node.data as BPOLoanData).addresses.map(address => {
        const isSelected = address.bpoOrderStagingAddressId === key;
        if (isSelected !== address.isSelected) {
          hasChange = true;
        }

        const newAddress = {
          ...address,
          isSelected,
        };

        return newAddress;
      }),
    };

    if (myLoan.errors) {
      myLoan.errors = myLoan.errors.map(err => {
        if (err.type === 'Address' && err.errorAcknowledged !== true) {
          hasChange = true;
          return {
            ...err,
            errorAcknowledged: true,
          };
        }
        return err;
      });
    }

    myLoan.hasChange = hasChange;

    onChangeSingleBPOValidationData(myLoan);
    onRefreshData();

    const updatedLoan = order.bpoLoanValidation.data.find(
      loan => loan.bpoOrderStagingId === data.bpoOrderStagingId,
    );

    node.setData(updatedLoan);
    api.refreshCells({ force: true, rowNodes: [node] });
  };

  let selectedAddressId = '';
  if (addressLookup.selected) {
    selectedAddressId = addressLookup.selected.bpoOrderStagingAddressId || '';
  } else if (addressLookup.suggested.length === 1) {
    selectedAddressId =
      addressLookup.suggested[0].bpoOrderStagingAddressId || '';
  }

  if (addressLookup.suggested.length === 0) {
    return (
      <AddressNotFound>
        <strong>Address not found</strong>
        <RadioGroup
          options={options}
          name={`suggestedAddresses-${data.row}`}
          onChange={onChange}
          selected={selectedAddressId}
        />
      </AddressNotFound>
    );
  }

  return (
    <RadioGroup
      options={options}
      name={`suggestedAddresses-${data.row}`}
      onChange={onChange}
      selected={selectedAddressId}
    />
  );
}

export interface DeleteBtRendererProps extends ICellRendererParams {
  onClick: (rowIndex: number) => void;
}

export function DeleteBtCellRenderer({ onClick, data }: DeleteBtRendererProps) {
  return (
    <DeleteContainer onClick={() => onClick(data)}>
      <RiDeleteBin7Line color="#bd0e08" size={16} />
      <p>Delete Loan</p>
    </DeleteContainer>
  );
}
