import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import api from '../services/api';

export type AUSUploadLoanResponse = {
  loanStagingId: string;
  documentId: string;
  documentName: string;
  clientLoanId: string;
  borrowerInfos: [
    {
      borrowerName: string;
      ssn: string;
    },
  ];
  loanAmount: number;
  subjectPropertyInfo: {
    street: string;
    unitNumber: string;
    city: string;
    state: string;
    zip: string;
  };
};

export type AUSUploadLoanParams = {
  files: File[];
  stagingId: string;
};

async function setAUSUploadLoan({
  files,
  ...params
}: AUSUploadLoanParams): Promise<AUSUploadLoanResponse> {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  const formData = new FormData();
  formData.append('Document', files[0]);

  let response;

  if (!params.stagingId) {
    response = await api.post<AUSUploadLoanResponse>(
      '/AUSOrder/uploadLoan',
      formData,
      config,
    );
  } else {
    formData.append('StagingId', params.stagingId);
    response = await api.patch<AUSUploadLoanResponse>(
      '/AUSOrder/uploadLoan',
      formData,
      config,
    );
  }

  const {
    loanStagingId,
    documentId,
    documentName,
    clientLoanId,
    borrowerInfos,
    loanAmount,
    subjectPropertyInfo,
  } = response.data;

  const loan: AUSUploadLoanResponse = {
    loanStagingId,
    documentId,
    documentName,
    clientLoanId,
    borrowerInfos,
    loanAmount,
    subjectPropertyInfo,
  };

  return loan;
}

export { setAUSUploadLoan };

export function useSetAUSUploadLoan() {
  return useMutation<
    AUSUploadLoanResponse,
    AxiosError<ErrorData>,
    AUSUploadLoanParams
  >(params => setAUSUploadLoan(params));
}
