import { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { IProduct } from '../context/order/types';
import api from '../services/api';

export async function getProducts(
  vendorId?: string | number,
): Promise<IProduct[]> {
  const response = await api.get<IProduct[]>('/Products', {
    params: { vendorId },
  });

  return response.data;
}

export const useProducts = (
  vendorId?: string | number,
): UseQueryResult<IProduct[], AxiosError<ErrorData>> =>
  useQuery(['products', vendorId], () => getProducts(vendorId), {
    refetchInterval: 1000 * 60,
  });
