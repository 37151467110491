import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import api from '../services/api';

type UpdateAppraisalOrderParams = {
  orderId: string;
  orderIdentifier?: string;
};

type UpdateAppraisalOrderResponse = {
  orderNumber: number;
};

async function updateAppraisalOrder({
  orderId,
  orderIdentifier,
}: UpdateAppraisalOrderParams) {
  const escapedId = window.encodeURIComponent(orderId);
  const escapedIdentifier = window.encodeURIComponent(orderIdentifier || '');
  const response = await api.patch<UpdateAppraisalOrderResponse>(
    `/AppraisalOrder/${escapedId}/submit?identifier=${escapedIdentifier}`,
  );
  return response.data;
}

export function useSubmitAppraisalOrder() {
  return useMutation<
    UpdateAppraisalOrderResponse,
    AxiosError<ErrorData>,
    UpdateAppraisalOrderParams
  >(params => updateAppraisalOrder(params));
}
