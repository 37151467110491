import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import api from '../services/api';

export type SetAppraisalOrderData = {
  orderId?: string;
  companyDivision?: number;
  stagingId: string;
  vendorId: string;
  clientLoanId: string;
  productId: string;
  productPrice: {
    productId: string;
    productName: string;
    price: number;
  };
};

async function setAppraisalOrder(
  formData: SetAppraisalOrderData,
): Promise<{ orderId: string }> {
  let response;
  if (!formData.orderId) {
    response = await api.post('/AppraisalOrder', formData);
  } else {
    response = await api.patch('/AppraisalOrder', formData);
  }
  return response.data;
}

export function useSetAppraisalOrder() {
  return useMutation<
    { orderId: string },
    AxiosError<ErrorData>,
    SetAppraisalOrderData
  >(formData => setAppraisalOrder(formData));
}
