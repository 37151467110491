import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { ILoan } from '../context/order/appraisal/types';
import { BPOLoanData, IManualLoan } from '../context/order/bpo/types';
import { BPORevisionSubmitSummary } from '../components/NewOrder/BPOReviewSubmit';
import api, { BPO_ENDPOINT_PATH } from '../services/api';
import {
  calculateBPOOrderResponse,
  GetBPOOrderResponse,
} from '../queries/useBPOOrder';
import { ISubProduct } from '../context/order/types';

export type SetBPOLoansUploadParams = {
  formData: {
    orderId?: string;
    productId: string;
    vendorId: string;
    documentId: string;
    companyDivision?: number;
    productsPrice?: {
      productId: string;
      productName: string;
      price: number;
    }[];
    data: IManualLoan[];
  };
};

type LoansWithDetails = {
  details: string;
} & ILoan;

export type SetBPOLoansResponse = {
  isCompletedSuccessfully: boolean;
  orderId: string;
  loans: LoansWithDetails[];
  data: BPOLoanData[];
  summary: BPORevisionSubmitSummary;
  products: ISubProduct[];
};

async function setBPOLoansUpload({
  formData,
}: SetBPOLoansUploadParams): Promise<GetBPOOrderResponse> {
  let response;

  // TODO: Remove if bpoType is no longer accepted
  // if (formData.data.length > 0) {
  //   formData.data.forEach(row => {
  //     if (row.bpoProduct.name !== null) {
  //       row.bpoType = row.bpoProduct.name;
  //     } else {
  //       row.bpoType = undefined;
  //     }
  //   });
  // }

  if (!formData.orderId) {
    if (formData.data.length > 0) {
      response = await api.post(`/${BPO_ENDPOINT_PATH}/data`, formData);
    } else {
      response = await api.post(`/${BPO_ENDPOINT_PATH}/file`, formData);
    }
  } else if (formData.data.length > 0) {
    response = await api.put(
      `/${BPO_ENDPOINT_PATH}/${formData.orderId}/data`,
      formData,
    );
  } else {
    response = await api.put(
      `/${BPO_ENDPOINT_PATH}/${formData.orderId}/file`,
      formData,
    );
  }

  return calculateBPOOrderResponse(response.data);
}

export function useSetBPOLoans() {
  return useMutation<
    GetBPOOrderResponse,
    AxiosError<ErrorData>,
    SetBPOLoansUploadParams
  >(params => setBPOLoansUpload(params));
}
