import { FieldProps } from 'formik';
import {
  ITextFieldProps,
  MaskedTextField,
  TextField,
} from 'office-ui-fabric-react';
import * as React from 'react';
import { getErrorMessage, Omit } from './utils';

export function mapFieldToTextField<T extends string | undefined = any>({
  form,
  field,
  meta,
}: FieldProps<T>): Pick<
  ITextFieldProps,
  'value' | 'name' | 'onChange' | 'onBlur' | 'errorMessage' | 'form'
> {
  return {
    ...field,
    errorMessage: getErrorMessage({ form, field, meta }),
  };
}

export function mapFieldToMaskedTextField<T extends string | undefined = any>({
  form,
  field,
  meta,
}: FieldProps<T>): Pick<
  ITextFieldProps,
  'value' | 'name' | 'onChange' | 'onBlur' | 'errorMessage' | 'form'
> {
  return {
    ...mapFieldToTextField({ form, field, meta }),
    // ev hsa wrong balue for MaskedTextField
    onChange: (_, value) => form.setFieldValue(field.name, value),
  };
}

export type FormikTextFieldProps<T = any> = Omit<
  ITextFieldProps,
  'value' | 'name' | 'onChange' | 'onBlur' | 'form'
> &
  FieldProps<T>;

export function FormikTextField<T extends string | undefined = any>({
  field,
  form,
  meta,
  ...props
}: FormikTextFieldProps<T>) {
  const { errorMessage, ...fieldProps } = mapFieldToTextField({
    field,
    form,
    meta,
  });

  return (
    <TextField
      errorMessage={errorMessage} // allow for prop overwrite
      {...props}
      {...fieldProps}
    />
  );
}

export function FormikMaskedTextField<T extends string | undefined = any>({
  field,
  form,
  meta,
  ...props
}: FormikTextFieldProps<T>) {
  const { errorMessage, ...fieldProps } = mapFieldToMaskedTextField({
    field,
    form,
    meta,
  });

  return (
    <MaskedTextField
      errorMessage={errorMessage} // allow for prop overwrite
      {...props}
      {...fieldProps}
    />
  );
}
